import { useState, useEffect } from "react";

import './Details.css';

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage,  } from "interface/Data";
import { GetListPag, RegisterListPag, SetListPag } from "interface/Page";
import { SvgReturn, SvgSave } from "components/SvgFile";
import ModelSite_Home from "../../ModelPage/Home";
import ModelSite_Video from "../../ModelPage/Video";
import { Reg_DataSite } from "services/RegisterData";
import ModelSite_ModulesWorkload from "../../ModelPage/ModulesWorkload";
import ModelSite_StudentWorks from "../../ModelPage/StudentWorks";
import ModelSite_Contents from "../../ModelPage/Contents";
import ModelSite_PersonalizedService from "../../ModelPage/PersonalizedService";
import ModelSite_Profile from "../../ModelPage/Profile";
import ModelSite_Depositions from "../../ModelPage/Depositions";
import PopUp_DataProfile from "components/PopUp/DataProfile";

export default function Site_Home_details(){

    const [ loading, setLoading ]   = useState(false);
    const [ idPage, setIdPage ]     = useState(GetListPag('currentPageId'));
    const [ dataPage, setDataPage ] = useState(GetDataPage('siteHome'));

    const [ type, setType ]         = useState(InitialData('type'));

    useEffect(()=>{
        RegisterDataPage('siteHome', setDataPage);
        RegisterListPag('currentPageId', setIdPage);
    }, []);

    useEffect(()=>{
        setDataPage(GetDataPage('siteHome'));
        setIdPage(GetListPag('currentPageId'));

        setType(InitialData('type'));
        ShowNewData();
    }, [dataPage]);

    useEffect(()=>{
        setDataPage(GetDataPage('siteHome'));
        setIdPage(GetListPag('currentPageId'));

        setType(InitialData('type'));
        ShowNewData();
    }, [idPage]);

    function InitialData(type){
        if(idPage !=0){
            const newData = dataPage.find(item => item.id == idPage);
            return newData[type];
        }
        return '';
    }

    function ReturnPage(){
        SetListPag('currentPage', 'siteHome');
        SetListPag('currentPageId', 'remuve');
    }

    let contents = [];
    function ReturnData(value){
        contents = value;
    }

    function ShowNewData(){
        switch(type){
            case "Home":
                return <ModelSite_Home origin="siteHome" ReturnData={ ReturnData } />;

            case "Apresentação com vídeo":
                return <ModelSite_Video origin="siteHome" ReturnData={ ReturnData } />;

            case "Trabalhos de alunos":
                return <ModelSite_StudentWorks origin="siteHome" ReturnData={ ReturnData } />;

            case "Explicação dos conteúdos":
                return <ModelSite_Contents origin="siteHome" ReturnData={ ReturnData } />;

            case "Módulos e carga horária":
                return <ModelSite_ModulesWorkload origin="siteHome" ReturnData={ ReturnData } />;

            case "Depoimentos":
                return <ModelSite_Depositions origin="siteHome" ReturnData={ ReturnData } />;

            case "Atendimento personalizado":
                return <ModelSite_PersonalizedService origin="siteHome" ReturnData={ ReturnData } />;

            case "Perfil do professor":
                return <ModelSite_Profile origin="siteHome" ReturnData={ ReturnData } />;
        }
    }

    function SaveData(event){
        event.preventDefault();
        setLoading(true);
        Reg_DataSite(idPage, 'siteHome', 'home', type, contents, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        setLoading(false);
        ShowNewData();
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    return(
        <div className="Site_Home_details">
            <div className={ loading == true ? "return_data_save div_loading" : "return_data_save" }>
                <img alt="loading" src="./assets/loading.gif" className="loading" />
            </div>
            <form onSubmit={ SaveData } className="div_login">
                <div className="new_block">
                    <div className="div_return" onClick={ ()=>{ ReturnPage() } }>
                        <SvgReturn color="#111827" className="icons" />
                        voltar
                    </div>
                    <div className="title_page">{ idPage !=0 ? type : "Novo cadastro" }</div>
                    <button className="new_block_text">
                        <SvgSave color="#ffffff" className="icons" />
                        Salvar
                    </button>
                </div>

                <div className="list_data_page">
                    <div className="div_input div_space">
                        {
                            idPage !=0 ? null :
                            <div className="div_show_data div_space">  
                                <select className="select_type" onChange={ (e)=>{ setType(e.target.value) } } required>
                                    <option value="">#</option>
                                    <option value="Home">Home</option>
                                    <option value="Apresentação com vídeo">Apresentação com vídeo</option>
                                    <option value="Trabalhos de alunos">Trabalhos de alunos</option>
                                    <option value="Explicação dos conteúdos">Explicação dos conteúdos</option>
                                    <option value="Módulos e carga horária">Módulos e carga horária</option>
                                    <option value="Depoimentos">Depoimentos</option>
                                    <option value="Atendimento personalizado">Atendimento personalizado</option>
                                    <option value="Perfil do professor">Perfil do professor</option>
                                </select>
                                <span className="span_input">Tipo</span>
                            </div>
                        }
                        { ShowNewData() }
                    </div>
                </div>
            </form>
            <PopUp_DataProfile />
        </div>
    )
}