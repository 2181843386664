import { useState, useEffect } from "react";

import './Details.css';

import Select from 'react-select';

import JoditEditor from 'jodit-react';
import { config, typeCookiePageId } from "dataFixed";

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage } from "interface/Data";
import { GetUserData, RegisterUserData } from "interface/Users";
import { GetListPag, SetListPag, RegisterListPag } from "interface/Page";

import { SvgDelete, SvgImg, SvgReturn, SvgSave, SvgVimeo, SvgYoutube } from "components/SvgFile";

import { Reg_Team, Reg_UserDash } from "services/RegisterData";
import PopUp_DetailsStudy from "components/PopUp/DetailsStudy";

export default function Team_Details(){

    const [ loading, setLoading ]         = useState(false);
    const [ userId, setUserId ]           = useState(GetUserData('id'));
    const [ dataPage, setDataPage ]       = useState(GetDataPage('team'));
    const [ idPage, setIdPage ]           = useState(GetListPag('currentPageId'));

    const [ name, setName ]               = useState(InitialData('name'));
    const [ note, setNote ]               = useState(InitialData('note'));
    const [ course, setCourse ]           = useState(InitialData('id_course'));
    const [ listStudy, setListStudy ]     = useState(InitialData('list_study'));
    const [ status, setStatus ]           = useState(false);
    const [ studentsAdd, setStudentsAdd ] = useState([]);

    const [ listCourses, setListCourses ] = useState(ListCourse());

    useEffect(()=>{
        RegisterUserData('id', setUserId);
        RegisterDataPage('team', setDataPage);
        RegisterListPag('currentPageId', setIdPage);
    }, []);

    useEffect(()=>{
        setDataPage(GetDataPage('team'));
        setIdPage(GetListPag('currentPageId'));

        setName(InitialData('name'));
        setNote(InitialData('note'));
        setCourse(InitialData('id_course'));
        setListStudy(InitialData('list_study'));
    }, [dataPage]);

    useEffect(()=>{
        setDataPage(GetDataPage('team'));
        setIdPage(GetListPag('currentPageId'));

        setName(InitialData('name'));
        setNote(InitialData('note'));
        setCourse(InitialData('id_course'));
        setListStudy(InitialData('list_study'));
    }, [idPage]);

    function InitialData(type){
        if(idPage !=0){
            const newData = dataPage.find(item => item.id == idPage);
            if(type == 'id_course'){
                return { "value": newData['id_course'], "label": newData['name_course'] };
            }
            return newData[type];
        }
        if(type == 'list_study'){
            return [];
        }
        return '';
    }

    function ListCourse(){
        const options = []; 
        GetDataPage('courses').map((key, index)=>{
            options.push({
                "value": key.id, "label": key.title
            });
        })
        return options;
    }

    function ListStudents(){
        const options = []; 
        GetDataPage('students').map((key, index)=>{
            options.push({
                "value": key.id, "label": key.name
            });
        })
        return options;
    }

    function ReturnPage(){
        SetListPag('currentPage', 'team');
        SetListPag('currentPageId', 'remuve');
    }

    function SaveData(event){
        event.preventDefault();
        setLoading(true);
        Reg_Team(idPage, name, note, course.value, studentsAdd, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        setLoading(false);
        setStatus(false);
        setStudentsAdd([])
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    function OpenFile(type, file){
        SetModalData('ShowFile', { "type": type, "file": file });
        SetModalState('ShowFile', true);
    }

    function OpenDetailsStudy(id, name){
        SetModalData('StatusClasses', { "id": id, "name": name });
        SetModalState('StatusClasses', true);
    }

    function DeleteData(id, value){
        SetModalData('Confirmation', { "origin": "team", "type" : "delete_user", "id" : id, "name": value });
        SetModalState('Confirmation', true);
    }

    return(
        <div className="Team_Details">
            <div className={ loading == true ? "return_data_save div_loading" : "return_data_save" }>
                <img alt="loading" src="./assets/loading.gif" className="loading" />
            </div>
            <form onSubmit={ SaveData } className="div_login">
                <div className="new_block">
                    <div className="div_return" onClick={ ()=>{ ReturnPage() } }>
                        <SvgReturn color="#111827" className="icons" />
                        voltar
                    </div>
                    <div className="title_page">Detalhes</div>
                    <button className="new_block_text">
                        <SvgSave color="#ffffff" className="icons" />
                        Salvar
                    </button>
                </div>

                <div className="list_data_page">
                    <div className="div_input">
                        <div className="div_show_data">
                            <input type="text" className="input_name" onChange={ (e)=>{ setName(e.target.value) } } value={ name } maxLength="140" required />
                            <span className="span_input" maxLength="14">Nome</span>
                        </div>
                        <div className="div_show_data div_space">
                            <Select className="select_search div_space" defaultOptions value={ course } options={ ListCourse() } onChange={ (e)=> { setCourse(e); } } />
                            <div className="span_search_select">Cursos</div>
                        </div>
                    </div>
                </div>

                <div className="list_data_page block_cinza"> 
                    <div className="block_title">
                        <div className="">
                            Observação
                        </div>
                    </div>
                    <div className="div_input">
                        <JoditEditor className="show_textarea" config={ config } value={ note ? note.replaceAll('&#34;', '"') : "" } onBlur={ newContent => setNote(newContent) } />
                    </div>
                </div>

                <div className="list_data_page block_cinza"> 
                    <div className="block_title">
                        <div className="">
                            Alunos
                        </div>
                        <div className="button_new_data" onClick={ ()=>{ setStatus(!status) } }>
                            { status == true ? "Cancelar" : "Adicionar novo aluno" }
                        </div>
                    </div>
                    {
                        status != true ? null : 
                        <div className="div_input">
                            <div className="div_show_data div_space students">
                                <Select className="div_space" defaultOptions options={ ListStudents() } onChange={ (e)=> { setStudentsAdd(e); } } value={ studentsAdd } isMulti /> 
                            </div>
                        </div>
                    }
                    <div className="div_input">
                        {
                            listStudy.length > 0 ?
                            <table width="100%" cellPadding="2" cellSpacing="6">
                                <tbody>
                                    <tr>
                                        <th width="10">#</th>
                                        <th>Aluno</th>
                                        <th width="60" align="right"></th>
                                    </tr>
                                </tbody>
                                 {/* className="show_details_classes" */}
                                <tbody>
                                    {
                                        listStudy.map((key, index)=>{
                                            return(
                                                <tr key={ index }>
                                                    <td>{ index + 1 }</td>
                                                    <td style={ { cursor: 'pointer' } } onClick={ ()=>{ OpenDetailsStudy(key.id_user, key.name) } }>{ key.name }</td>
                                                    <td>
                                                        <div className="div_opt_alt">
                                                            <div onClick={ ()=>{ DeleteData(key.id, key.name) } }>
                                                                <SvgDelete color="#F00000" className="icons"/>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                            : status == true ? null : <div className="no_data">Nenhum aluno adicionado até o momento...</div>
                        }
                    </div>
                </div>
            </form>  
            <PopUp_DetailsStudy />      
        </div>
    )
}