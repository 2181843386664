import { useState, useEffect } from "react";

import './Details.css';

import Select from 'react-select';

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage } from "interface/Data";
import { GetUserData, RegisterUserData } from "interface/Users";
import { GetListPag, SetListPag, RegisterListPag } from "interface/Page";

import { SvgDelete, SvgImg, SvgReturn, SvgSave } from "components/SvgFile";

import { Reg_UserDash } from "services/RegisterData";

export default function AccessDash_Details(){

    const [ loading, setLoading ]   = useState(false);
    const [ userId, setUserId ]     = useState(GetUserData('id'));
    const [ dataPage, setDataPage ] = useState(GetDataPage('accessDash'));
    const [ idPage, setIdPage ]     = useState(GetListPag('currentPageId'));

    const [ name, setName ]     = useState(InitialData('name'));
    const [ pass, setPass ]     = useState('');
    const [ email, setEmail ]   = useState(InitialData('email'));
    const [ file, setFile ]     = useState(InitialData('file'));
    const [ page, setPage ]     = useState(InitialData('page'));
    const [ access, setAccess ] = useState(InitialData('access'));
    const [ opt, setOpt ]       = useState([
        { "value": "courses", "label": "Cursos" },
        { "value": "students", "label": "Alunos" },
        { "value": "team", "label": "Turmas" },
        { "value": "siteHome", "label": "Site - Home" },
        { "value": "siteHowWorks", "label": "Site - Como funciona" },
        { "value": "siteMentoring", "label": "Site - Mentoria" },
        { "value": "siteRolo", "label": "Site - Rolo" },
        { "value": "sitePayment", "label": "Site - Tipo de pagamento" },
        { "value": "siteNameMenu", "label": "Site - Páginas do menu" },
        { "value": "financialEntry", "label": "Financeiro - Entrada" },
        { "value": "financialExit", "label": "Financeiro - Saída" },
        { "value": "financialBalance", "label": "Financeiro - Balanço" },
        { "value": "indicators", "label": "Indicadores" }
    ]);
    const [ addPage, setAddPage ] = useState([]);

    const [ stateFile, setStateFile ] = useState(false);
    const [ stateOpt, setStateOpt ]   = useState(false);

    useEffect(()=>{
        RegisterUserData('id', setUserId);
        RegisterDataPage('accessDash', setDataPage);
        RegisterListPag('currentPageId', setIdPage);
    }, []);

    useEffect(()=>{
        setDataPage(GetDataPage('accessDash'));
        setIdPage(GetListPag('currentPageId'));

        setName(InitialData('name'));
        setEmail(InitialData('email'));
        setFile(InitialData('file'));
        setPage(InitialData('page'));
        setAccess(InitialData('access'));
    }, [dataPage]);

    useEffect(()=>{
        setDataPage(GetDataPage('accessDash'));
        setIdPage(GetListPag('currentPageId'));

        setName(InitialData('name'));
        setEmail(InitialData('email'));
        setFile(InitialData('file'));
        setPage(InitialData('page'));
        setAccess(InitialData('access'));
    }, [idPage]);

    function InitialData(type){
        if(idPage !=0){
            const newData = dataPage.find(item => item.id == idPage);
            return newData[type];
        }
        if(type == 'page'){
            return [];
        }
        return '';
    }

    function ReturnPage(){
        SetListPag('currentPage', 'accessDash');
        SetListPag('currentPageId', 'remuve');
    }

    function SaveData(event){
        event.preventDefault();
        setLoading(true);
        Reg_UserDash(userId, idPage, name, pass, email, file, access, addPage, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        setAddPage([]);
        setLoading(false);
        setStateOpt(false);
        setStateFile(false);
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    function AddFile(value){
        if(value){
            setFile(value);
            setStateFile(true);
        }else {
            setStateFile(false);
        }
    }

    function OpenFile(type, file){
        SetModalData('ShowFile', { "type": type, "file": file });
        SetModalState('ShowFile', true);
    }

    function DeleteData(id, value){
        SetModalData('Confirmation', { "origin": "access_dash", "type" : "delete_page", "id" : id, "name": value });
        SetModalState('Confirmation', true);
    }

    return(
        <div className="AccessDash_Details">
            <div className={ loading == true ? "return_data_save div_loading" : "return_data_save" }>
                <img alt="loading" src="./assets/loading.gif" className="loading" />
            </div>
            <form onSubmit={ SaveData } className="div_login">
                <div className="new_block">
                    <div className="div_return" onClick={ ()=>{ ReturnPage() } }>
                        <SvgReturn color="#111827" className="icons" />
                        voltar
                    </div>
                    <div className="title_page">Detalhes</div>
                    <button className="new_block_text">
                        <SvgSave color="#ffffff" className="icons" />
                        Salvar
                    </button>
                </div>

                <div className="list_data_page">                    
                    <div className="div_input">
                        <div className="div_show_data div_space">
                            <input type="text" className="data_input" onChange={ (e)=>{ setName(e.target.value) } } value={ name } maxLength="140" required />
                            <span className="span_input" maxLength="14">Nome</span>
                        </div>

                        <div className="div_show_data div_space">
                            <input type="email" className="data_input" onChange={ (e)=>{ setEmail(e.target.value) } } value={ email.replaceAll(' ', '') } maxLength="140" required />
                            <span className="span_input">E-mail</span>
                        </div>

                        <div className="div_show_data">
                            <input type="password" className="pass" onChange={ (e)=>{ setPass(e.target.value) } } maxLength="20" />
                            <span className="span_input">Senha</span>
                        </div>
                        
                        {
                            idPage == userId ? null :
                            <div className="div_show_data">  
                                <select className="data_select" onChange={ (e)=>{ setAccess(e.target.value) } } value={ access }>
                                    <option value="0">Professor</option>
                                    <option value="1">Financeiro</option>
                                    <option value="2">Administrador</option>
                                </select>
                                <span className="span_input">Usuário</span>
                            </div>
                        }

                        <div className="div_show_data">   
                            <div className={ stateFile == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                <label>
                                    { stateFile == true ? "Foto adicionada" : "Adicionar foto" }
                                    <input type="file" onChange={ (e)=>{ AddFile(e.target.files[0]); } } accept="image/*"/>
                                </label>
                            </div>
                            {
                                file !='' ?
                                    stateFile == true ?
                                    <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(file)) } }>
                                        <SvgImg color="#324d6b" className="icons"/>
                                    </div> :
                                    <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "login/" + file) } }>
                                        <SvgImg color="#324d6b" className="icons"/>
                                    </div>
                                : ""
                            }                       
                        </div>
                    </div>
                </div>

                {
                    access == 2 ? null :
                    <div className="list_data_page block_cinza"> 

                        <div className="block_title">
                            <div className="">
                                Páginas liberadas
                            </div>
                            <div className="button_new_data" onClick={ ()=>{ setStateOpt(!stateOpt) } }>
                                {
                                    stateOpt == true ? "Cancelar" : "Liberar nova página"
                                }
                            </div>
                        </div>
                        
                        {
                            stateOpt == true ?
                            <div className="div_input">
                                <div className="div_space">
                                    <Select className="div_space" defaultOptions options={ opt } onChange={ (e)=> { setAddPage(e); } } value={ addPage } placeholder="Clique aqui para visualizar os serviços..." isMulti />
                                </div>
                            </div> 
                            : null
                        }   
                        
                        <div className="div_input">
                            {
                                page.length > 0 ?
                                    page.map((key, index)=>{
                                        return(
                                            <div className="page_released" key={ index }>
                                                <div className="">
                                                    { key.name }
                                                </div>
                                                <div className="" onClick={ ()=>{ DeleteData(key.id, key.name) } }>
                                                    <SvgDelete color="#f00000" className="icons" />
                                                </div>
                                            </div>
                                        )
                                    })
                                : 
                                stateOpt == true ? null : <div className="no_data">Nenhuma página liberada até o momento...</div>
                            }          
                        </div>  
                    </div>
                }

                {
                    idPage == 0 ? null :
                    <div className="list_data_page block_cinza"> 

                        <div className="block_title">
                            <div className="">
                                Alterações realizadas na dashboard
                            </div>
                        </div>

                        <div className="div_input">
                            <div className="div_show_data div_space">
                                
                            </div>
                        </div>
                        
                    </div>
                }
            </form>
        </div>
    )
}