import { useState, useEffect } from "react";

import './Profile.css';

import JoditEditor from 'jodit-react';
import { config } from "dataFixed";

import { SvgDelete, SvgImg, SvgPdf, SvgVimeo, SvgYoutube } from "components/SvgFile";

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage } from "interface/Data";
import { GetListPag, RegisterListPag } from "interface/Page";

export default function ModelSite_Profile(props){

    const [ idPage, setIdPage ]     = useState(GetListPag('currentPageId'));
    const [ dataPage, setDataPage ] = useState(GetDataPage(props.origin));

    const [ typeBg, setTypeBg ]             = useState(InitialData('type_bg'));
    const [ bgPage, setBgPage ]             = useState(InitialData('bg_page'));
    const [ status, setStatus ]             = useState(InitialData('status'));
    const [ img, setImg ]                   = useState(InitialData('img'));
    const [ statusImg, setStatusImg ]       = useState(InitialData('statusImg'));
    const [ sampleImage, setSampleImage ]   = useState(InitialData('sample_image'));
    const [ statusSample, setStatusSample ] = useState(InitialData('statusSample'));

    const [ typeAlign, setTypeAlign ]   = useState(InitialData('type_alignment'));
    const [ name_1, setName_1 ]         = useState(InitialData('name_1'));
    const [ name_2, setName_2 ]         = useState(InitialData('name_2'));
    const [ text_1, setText_1 ]         = useState(InitialData('text_1'));
    const [ behance, setBehance ]       = useState(InitialData('behance'));
    const [ artstation, setArtstation ] = useState(InitialData('artstation'));

    useEffect(()=>{
        RegisterDataPage(props.origin, setDataPage);
        RegisterListPag('currentPageId', setIdPage);
        
        LoadingData();
    }, []);

    useEffect(()=>{
        setDataPage(GetDataPage(props.origin));
        setIdPage(GetListPag('currentPageId'));
        
        setTypeBg(InitialData('type_bg'));
        setBgPage(InitialData('bg_page'));
        setStatus(InitialData('status'));
        setImg(InitialData('img'));
        setStatusImg(InitialData('statusImg'));
        setSampleImage(InitialData('sample_image'));
        setStatusSample(InitialData('statusSample'));
        setTypeAlign(InitialData('type_alignment'));
        setName_1(InitialData('name_1'));
        setName_2(InitialData('name_2'));
        setText_1(InitialData('text_1'));
        setBehance(InitialData('behance'));
        setArtstation(InitialData('artstation'));

        LoadingData();
    }, [dataPage]);

    useEffect(()=>{
        setDataPage(GetDataPage(props.origin));
        setIdPage(GetListPag('currentPageId'));
        
        setTypeBg(InitialData('type_bg'));
        setBgPage(InitialData('bg_page'));
        setStatus(InitialData('status'));
        setImg(InitialData('img'));
        setStatusImg(InitialData('statusImg'));
        setSampleImage(InitialData('sample_image'));
        setStatusSample(InitialData('statusSample'));
        setTypeAlign(InitialData('type_alignment'));
        setName_1(InitialData('name_1'));
        setName_2(InitialData('name_2'));
        setText_1(InitialData('text_1'));
        setBehance(InitialData('behance'));
        setArtstation(InitialData('artstation'));

        LoadingData();
    }, [idPage]);

    function InitialData(type){
        if(idPage !=0){
            if(dataPage.length > 0){
                const newData = dataPage.find(item => item.id == idPage);
                return newData[type];
            }
        }
        if(type == 'type_alignment'){
            return 'left';
        }
        if(type == 'type_bg'){
            return 'Imagem de fundo';
        }
        return '';
    }

    function LoadingData(){
        props.ReturnData({"typeBg": typeBg, "bgPage": bgPage, "type_alignment": typeAlign, "img": img, "sampleImage": sampleImage, "name_1": name_1, "name_2": name_2, "text_1": text_1, "behance": behance, "artstation": artstation});
    }

    function HandleTypeBg(value){
        setTypeBg(value);
        props.ReturnData({"typeBg": value, "bgPage": bgPage, "type_alignment": typeAlign, "img": img, "sampleImage": sampleImage, "name_1": name_1, "name_2": name_2, "text_1": text_1, "behance": behance, "artstation": artstation});
    }

    function HandleBgPage(value){
        setBgPage(value);
        props.ReturnData({"typeBg": typeBg, "bgPage": value, "type_alignment": typeAlign, "img": img, "sampleImage": sampleImage, "name_1": name_1, "name_2": name_2, "text_1": text_1, "behance": behance, "artstation": artstation});
    }

    function HandleImg_1(value){
        setBgPage(value);
        value ? setStatus(true) : setStatus(false);
        props.ReturnData({"typeBg": typeBg, "bgPage": value, "type_alignment": typeAlign, "img": img, "sampleImage": sampleImage, "name_1": name_1, "name_2": name_2, "text_1": text_1, "behance": behance, "artstation": artstation});
    }

    function HandleImg_2(value){
        setImg(value);
        value ? setStatusImg(true) : setStatusImg(false);
        props.ReturnData({"typeBg": typeBg, "bgPage": bgPage, "type_alignment": typeAlign, "img": value, "sampleImage": sampleImage, "name_1": name_1, "name_2": name_2, "text_1": text_1, "behance": behance, "artstation": artstation});
    }

    function HandleImg_3(value){
        setSampleImage(value);
        value ? setStatusSample(true) : setStatusSample(false);
        props.ReturnData({"typeBg": typeBg, "bgPage": bgPage, "type_alignment": typeAlign, "img": img, "sampleImage": value, "name_1": name_1, "name_2": name_2, "text_1": text_1, "behance": behance, "artstation": artstation});
    }

    function HandleTypeAlign(value){
        setTypeAlign(value);
        props.ReturnData({"typeBg": typeBg, "bgPage": bgPage, "type_alignment": value, "img": img, "sampleImage": sampleImage, "name_1": name_1, "name_2": name_2, "text_1": text_1, "behance": behance, "artstation": artstation});
    }

    function HandleName_1(value){
        setName_1(value);
        props.ReturnData({"typeBg": typeBg, "bgPage": bgPage, "type_alignment": typeAlign, "img": img, "sampleImage": sampleImage, "name_1": value, "name_2": name_2, "text_1": text_1, "behance": behance, "artstation": artstation});
    }

    function HandleName_2(value){
        setName_2(value);
        props.ReturnData({"typeBg": typeBg, "bgPage": bgPage, "type_alignment": typeAlign, "img": img, "sampleImage": sampleImage, "name_1": name_1, "name_2": value, "text_1": text_1, "behance": behance, "artstation": artstation});
    }

    function HandleText_1(value){
        setText_1(value);
        props.ReturnData({"typeBg": typeBg, "bgPage": bgPage, "type_alignment": typeAlign, "img": img, "sampleImage": sampleImage, "name_1": name_1, "name_2": name_2, "text_1": value, "behance": behance, "artstation": artstation});
    }

    function HandleBehance(value){
        setBehance(value);
        props.ReturnData({"typeBg": typeBg, "bgPage": bgPage, "type_alignment": typeAlign, "img": img, "sampleImage": sampleImage, "name_1": name_1, "name_2": name_2, "text_1": text_1, "behance": value, "artstation": artstation});
    }

    function HandleArtstation(value){
        setArtstation(value);
        props.ReturnData({"typeBg": typeBg, "bgPage": bgPage, "type_alignment": typeAlign, "img": img, "sampleImage": sampleImage, "name_1": name_1, "name_2": name_2, "text_1": text_1, "behance": behance, "artstation": value});
    }

    function OpenFile(type, file){
        SetModalData('ShowFile', { "type": type, "file": file });
        SetModalState('ShowFile', true);
    }

    return(
        <div className="ModelSite_Profile">
            <div className="list_data_page">
                <div className="div_input">
                    <div className="div_show_data">  
                        <select className="data_select" onChange={ (e)=>{ HandleTypeBg(e.target.value) } } value={ typeBg }>
                            <option value="Imagem de fundo">Imagem de fundo</option>
                            <option value="Definir cor">Definir cor</option>
                        </select>
                        <span className="span_input">Fundo</span>
                    </div>
                    {
                        typeBg == 'Definir cor' ?
                        <div className="div_show_data">
                            <input type="text" className="input_color" onChange={ (e)=>{ HandleBgPage(e.target.value) } } value={ bgPage } maxLength="140" />
                            <span className="span_input" maxLength="14">Cor de fundo</span>
                            <div className="div_color" style={ { background: bgPage } } />
                        </div> :
                        <div className="div_show_data">   
                            <div className={ status == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                <label>
                                    { status == true ? "Imagem de fundo adicionada" : "Adicionar imagem de fundo" }
                                    <input type="file" onChange={ (e)=>{ HandleImg_1(e.target.files[0]); } } accept="image/*"/>
                                </label>
                            </div>
                            {
                                bgPage !='' ?
                                    status == true ?
                                    <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(bgPage)) } }>
                                        <SvgImg color="#324d6b" className="icons"/>
                                    </div> :
                                    <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "profile/" + bgPage) } }>
                                        <SvgImg color="#324d6b" className="icons"/>
                                    </div>
                                : ""
                            }                      
                        </div>
                    }
                </div>

                <div className="div_input space_top">
                    <div className="div_show_data">  
                        <select className="data_select" onChange={ (e)=>{ HandleTypeAlign(e.target.value) } } value={ typeAlign }>
                            <option value="left">Left</option>
                            <option value="right">Right</option>
                        </select>
                        <span className="span_input">Tipo</span>
                    </div>
                    <div className="div_show_data">   
                        <div className={ statusImg == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                            <label>
                                { statusImg == true ? "Foto adicionada" : "Adicionar foto" }
                                <input type="file" onChange={ (e)=>{ HandleImg_2(e.target.files[0]); } } accept="image/*"/>
                            </label>
                        </div>
                        {
                            img !='' ?
                                statusImg == true ?
                                <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(img)) } }>
                                    <SvgImg color="#324d6b" className="icons"/>
                                </div> :
                                <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "profile/" + img) } }>
                                    <SvgImg color="#324d6b" className="icons"/>
                                </div>
                            : ""
                        }                      
                    </div>
                    <div className="div_show_data">   
                        <div className={ statusSample == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                            <label>
                                { statusSample == true ? "imagem de exemplo adicionada" : "Adicionar imagem de exemplo" }
                                <input type="file" onChange={ (e)=>{ HandleImg_3(e.target.files[0]); } } accept="image/*"/>
                            </label>
                        </div>
                        {
                            sampleImage !='' ?
                                statusSample == true ?
                                <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(sampleImage)) } }>
                                    <SvgImg color="#324d6b" className="icons"/>
                                </div> :
                                <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "profile/" + sampleImage) } }>
                                    <SvgImg color="#324d6b" className="icons"/>
                                </div>
                            : ""
                        }                      
                    </div>
                </div>
                        
                <div className="div_input div_space space_top">
                    <div className="div_show_data div_space">
                        <input type="text" className="data_input" onChange={ (e)=>{ HandleName_1(e.target.value) } } value={ name_1 } maxLength="80" />
                        <span className="span_input" maxLength="14">Nome 1</span>
                    </div>
                    <div className="div_show_data div_space">
                        <input type="text" className="data_input" onChange={ (e)=>{ HandleName_2(e.target.value) } } value={ name_2 } maxLength="80" />
                        <span className="span_input" maxLength="14">Nome 2</span>
                    </div>
                </div>

                <div className="div_input block_cinza"> 
                    <div className="block_title space_bottom">
                        <div className="">
                            Descrição
                        </div>
                    </div>
                    <div className="div_input">
                        <JoditEditor className="show_textarea" config={ config } value={ text_1.replaceAll('&#34;', '"') } onBlur={ newContent => HandleText_1(newContent) } />
                    </div>
                </div>
                        
                <div className="div_input div_space space_top">
                    <div className="div_show_data div_space">
                        <input type="text" className="data_input" onChange={ (e)=>{ HandleBehance(e.target.value) } } value={ behance } maxLength="80" />
                        <span className="span_input" maxLength="14">Link behance</span>
                    </div>
                </div>
                        
                <div className="div_input div_space space_top">
                    <div className="div_show_data div_space">
                        <input type="text" className="data_input" onChange={ (e)=>{ HandleArtstation(e.target.value) } } value={ artstation } maxLength="80" />
                        <span className="span_input" maxLength="14">link artstation</span>
                    </div>
                </div>

                <div className="div_input div_space space_top">
                    <div className="div_show_data div_space img">
                        {
                            typeAlign == "left" ?
                            <img alt="home_example" src="./assets/bg_profile_left.jpg" className="img_example" /> :

                            typeAlign == "right" ? <img alt="home_example" src="./assets/bg_profile_right.jpg" className="img_example" /> : ""
                        }
                    </div>
                </div>
            </div>
        </div>
    );

}