import { useState, useEffect } from "react";

import './PaymentTypes.css';

import { SvgDelete, SvgSave } from "components/SvgFile";

import { Reg_NewData_Payment } from "services/RegisterData";

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage } from "interface/Data";

export default function Site_PaymentTypes(){
    
    const [ loading, setLoading ]   = useState(false);
    const [ dataPage, setDataPage ] = useState(GetDataPage('sitePayment'));

    const [ title, setTitle ]             = useState(InitialData('title'));
    const [ subtitle, setSubtitle ]       = useState(InitialData('subtitle'));
    const [ listPayment, setListPayment ] = useState(InitialData('payment'));

    const [ showHome, setShowHome ]           = useState(InitialData('show_home'));
    const [ showMentoring, setShowMentoring ] = useState(InitialData('show_mentoring'));
    const [ showRolo, setShowRolo ]           = useState(InitialData('show_rolo'));
    const [ listCategory, setListCategory ]   = useState(ListCategory());

    useEffect(()=>{
        RegisterDataPage('sitePayment', setDataPage);
    }, []);

    useEffect(()=>{
        setDataPage(GetDataPage('sitePayment'));

        setTitle(InitialData('title'));
        setSubtitle(InitialData('subtitle'));
        setListPayment(InitialData('payment'));
        setShowHome(InitialData('show_home'));
        setShowMentoring(InitialData('show_mentoring'));
        setShowRolo(InitialData('show_rolo'));
        setListCategory(ListCategory());
    }, [dataPage]);

    function InitialData(type){
        const newData = dataPage;
        return newData[type];
    }

    function ListCategory(){
        const newData = [];
        dataPage.payment.forEach(item =>{
            item.category.forEach(item_1 =>{
                newData.push(item_1.name);
            });
        })
        const duplicate = newData.filter((item, index) => newData.indexOf(item) === index);
        return duplicate;
    }

    function AddNewData(id){
        setListPayment([...listPayment, {
            "id": 0,
            "upload": false,
            "best_cost_benefit": "",
            "title": "",
            "subtitle": "",
            "color": "",
            "type": "",
            "cash": "",
            "data": "",
            "category":[
                {
                    "id": 0,
                    "id_given": id,
                    "data": "",
                    "upload": false,
                    "name": ""
                }
            ]
        }]);
    }

    function NewCategory(id, index){
        const dataShow = [...listPayment];
        dataShow[index]['category'] = [...dataShow[index]['category'], {
            "id": 0,
            "id_given": id,
            "data": "",
            "upload": false,
            "name": ""
        }]
        setListPayment(dataShow);
    }
    
    function handleData(index, type, value){
        const dataShow = [...listPayment];
        dataShow[index][type] = value;
        setListPayment(dataShow);
    }

    function handleDataCategory(index, index_, type, value){
        const dataShow = [...listPayment];
        dataShow[index]['category'][index_][type] = value;
        setListPayment(dataShow);
    }

    function DeleteData(type, id, value){
        SetModalData('Confirmation', { "origin": "", "type" : type, "id" : id, "name": value });
        SetModalState('Confirmation', true);
    }

    function RemuveDataAddCategory(id, index, index_, value, upload){
        if(upload == 'true'){
            SetModalData('Confirmation', { "origin": "", "type" : "delete_category", "id" : id, "name": value });
            SetModalState('Confirmation', true);
        }else {
            let arrayData = [...listPayment];
            arrayData[index]['category'].splice(index_, 1);
            setListPayment(arrayData);
        }
    }

    function SaveData(event){
        event.preventDefault();
        setLoading(true);
        Reg_NewData_Payment(dataPage.id, title, subtitle, showHome, showMentoring, showRolo, listPayment, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    return(
        <div className="Site_PaymentTypes">
            <div className={ loading == true ? "return_data_save div_loading" : "return_data_save" }>
                <img alt="loading" src="./assets/loading.gif" className="loading" />
            </div>
            <form onSubmit={ SaveData } className="div_login">
                <div className="new_block">
                    <div className="title_page">Tipos de pagamento</div>
                    <button className="new_block_text">
                        <SvgSave color="#ffffff" className="icons" />
                        Salvar
                    </button>
                </div>

                <div className="list_data_page">
                    <div className="div_input">
                        <div className="div_show_data div_space">
                            <input type="text" className="data_input" onChange={ (e)=>{ setTitle(e.target.value) } } value={ title } required />
                            <span className="span_input" maxLength="14">Título</span>
                        </div>
                    </div>
                </div>

                <div className="list_data_page space_top">
                    <div className="div_input">
                        <div className="div_show_data div_space">
                            <input type="text" className="data_input" onChange={ (e)=>{ setSubtitle(e.target.value) } } value={ subtitle } required />
                            <span className="span_input" maxLength="14">Subtítulo</span>
                        </div>
                    </div>
                </div>

                <div className="list_data_page space_top">
                    <div className="div_input">
                        <div className="div_show_data div_space">
                            <div className="">Aparecer nas páginas</div> 
                            <div className={ showHome ? "highlight high_active" : "highlight" } onClick={ ()=>{ setShowHome(!showHome) } }>
                                Home
                            </div>
                            <div className={ showMentoring ? "highlight high_active" : "highlight" } onClick={ ()=>{ setShowMentoring(!showMentoring) } }>
                                Mentoria
                            </div>
                            <div className={ showRolo ? "highlight high_active" : "highlight" } onClick={ ()=>{ setShowRolo(!showRolo) } }>
                                Rolo
                            </div>
                        </div>
                    </div>
                </div>

                <div className="list_data_page block_cinza"> 
                    <div className="block_title">
                        <div className="">
                            Formas de pagamento
                        </div>
                        {/* 
                        <div className="button_new_data" onClick={ ()=>{  } }>
                            Adicionar novo pagamento
                        </div> 
                        */}
                    </div>
                    {
                        listPayment.map((key, index)=>{
                            return(
                                <div className="show_payment" key={ index }>
                                    <div className="div_input"> 
                                        <div className="div_show_data">  
                                            <select className="select_type" onChange={ (e)=>{ handleData(index, 'type', e.target.value) } } value={ key.type } required>
                                                <option value="">#</option>
                                                <option value="free">free</option>
                                                <option value="valor">valor</option>
                                            </select>
                                            <span className="span_input">Pagamento</span>
                                        </div>
                                        {
                                            key.type == 'free' ? null :  
                                            <>                                     
                                                <div className="div_show_data">
                                                    <input type="text" className="input_cash" onChange={ (e)=>{ handleData(index, 'cash', e.target.value) } } maxLength="20" value={ key.cash } />
                                                    <span className="span_input" maxLength="14">Valor R$</span>
                                                </div> 
                                                <div className="div_show_data">  
                                                    <select className="data_select" onChange={ (e)=>{ handleData(index, 'data', e.target.value) } } value={ key.data } required>
                                                        <option value="">#</option>
                                                        <option value="MÊS">Mês</option>
                                                        <option value="ANO">Ano</option>
                                                    </select>
                                                    <span className="span_input">Tipo</span>
                                                </div>
                                            </>                                              
                                        }
                                        <div className="div_show_data">  
                                            <select className="select_cost_benefit" onChange={ (e)=>{ handleData(index, 'best_cost_benefit', e.target.value) } } value={ key.best_cost_benefit } required>
                                                <option value="">#</option>
                                                <option value="Sim">Sim</option>
                                                <option value="Não">Não</option>
                                            </select>
                                            <span className="span_input">Melhor custo benefício</span>
                                        </div>
                                        <div className="div_show_data">
                                            <input type="text" className="input_color" onChange={ (e)=>{ handleData(index, 'color', e.target.value) } } value={ key.color } required />
                                            <span className="span_input" maxLength="14">Cor</span>
                                            <div className="div_color" style={ { backgroundColor: key.color } } />
                                        </div>
                                        <div className="div_show_data div_space">
                                            <div className="trash" onClick={ ()=>{ DeleteData('delete_data', key.id, key.title) } }>
                                                <SvgDelete color="#f00000" className="icons" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="div_input space_top">
                                        <div className="div_show_data">
                                            <input type="text" className="data_input" onChange={ (e)=>{ handleData(index, 'title', e.target.value) } } maxLength="80" value={ key.title } required />
                                            <span className="span_input" maxLength="14">Título</span>
                                        </div>
                                        <div className="div_show_data div_space">
                                            <input type="text" className="data_input" onChange={ (e)=>{ handleData(index, 'subtitle', e.target.value) } } maxLength="80" value={ key.subtitle } required />
                                            <span className="span_input" maxLength="14">Subtítulo</span>
                                        </div>
                                    </div>

                                    <div className="list_data_page block_cinza"> 
                                        <div className="block_title space_bottom">
                                            <div className="">
                                                Categoria
                                            </div>
                                            <div className="button_new_data" onClick={ ()=>{ NewCategory(key.id, index) } }>
                                                Adicionar nova categoria
                                            </div>
                                        </div>
                                        {
                                            key.category.map((key_1, index_1)=>{
                                                return(
                                                    <div className="div_input div_category" key={ index_1 }>
                                                        <div className="div_show_data"> 
                                                            <label>Categoria</label> 
                                                            <select className="select_category" onChange={ (e)=>{ handleDataCategory(index, index_1, 'name', e.target.value) } } value={ key_1.name }>
                                                                <option value="">#</option>
                                                                <option value="add_new_category">Adicionar nova categoria</option>
                                                                {
                                                                    listCategory.map((cat, cat_index)=>{
                                                                        return (
                                                                            <option value={ cat } key={ cat_index }>{ cat }</option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                        {
                                                            key_1.name != 'add_new_category' ? null :
                                                            <div className="div_show_data">
                                                                <label>Nome</label>
                                                                <input type="text" className="data_input" onChange={ (e)=>{ handleDataCategory(index, index_1, 'data', e.target.value) } } value={ key_1.data } />
                                                            </div> 
                                                        }
                                                        <div className="div_show_data">
                                                            <div className="" onClick={ ()=>{ RemuveDataAddCategory(key_1.id, index, index_1, key_1.name, key_1.upload) } }>
                                                                <SvgDelete color="#f00000" className="icons" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>

            </form>
        </div>
    )
}