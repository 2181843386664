import { useState, useEffect, useRef } from "react";

import './Details.css';

import Select from 'react-select';

import JoditEditor from 'jodit-react';
import { config } from "dataFixed";

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage } from "interface/Data";
import { GetUserData, RegisterUserData } from "interface/Users";
import { GetListPag, SetListPag, RegisterListPag } from "interface/Page";

import { SvgDelete, SvgImg, SvgReturn, SvgSave } from "components/SvgFile";

import { Reg_Students, Reg_UserDash } from "services/RegisterData";
import { cepMask, cpfMask, phoneMask } from "services/Mask";

export default function Students_Details(){

    const editText_1 = useRef();

    const [ loading, setLoading ]   = useState(false);
    const [ userId, setUserId ]     = useState(GetUserData('id'));

    const [ dataPage, setDataPage ]          = useState(GetDataPage('students'));
    const [ showTeamList , setShowTeamList ] = useState(GetDataPage('team'));
    const [ showCourses, setShowCourses ]    = useState(GetDataPage('courses'));
    const [ idPage, setIdPage ]              = useState(GetListPag('currentPageId'));

    const [ name, setName ]         = useState(InitialData('name'));
    const [ login, setLogin ]       = useState(InitialData('login'));
    const [ email, setEmail ]       = useState(InitialData('email'));
    const [ phone, setPhone ]       = useState(InitialData('phone'));
    const [ file, setFile ]         = useState(InitialData('file'));
    const [ state, setState ]       = useState(false);
    const [ country, setCountry ]   = useState(InitialData('country'));
    const [ address, setAddress ]   = useState(InitialData('address'));
    const [ cep, setCep ]           = useState(InitialData('cep'));
    const [ city, setCity ]         = useState(InitialData('city'));
    const [ uf, setUf ]             = useState(InitialData('uf'));
    const [ cpf, setCpf ]           = useState(InitialData('cpf'));
    const [ note, setNote ]         = useState(InitialData('note'));
    const [ pass, setPass ]         = useState('');
    const [ nivel, setNivel ]       = useState(InitialData('nivel'));

    const [ listTeam, setListTeam ]               = useState(InitialData('list_team'));
    const [ listNewTeam, setListNewTeam ]         = useState([]);

    const [ coursesReleased, setCoursesReleased ] = useState(InitialData('courses_released'));
    const [ stateNewCourse, setStateNewCourse ]   = useState(false);
    const [ addCourse, setAddCourse ]             = useState({ "value": 0, "label" : "Selecione um curso..."});
    const [ typeAdd, setTypeAdd ]                 = useState(0);
    const [ addClasses, setAddClasses ]           = useState([]);

    useEffect(()=>{
        RegisterUserData('id', setUserId);
        RegisterDataPage('students', setDataPage);
        RegisterDataPage('team', setShowTeamList);
        RegisterDataPage('courses', setShowCourses);
        RegisterListPag('currentPageId', setIdPage);
    }, []);

    useEffect(()=>{
        setDataPage(GetDataPage('students'));
        setShowTeamList(GetDataPage('team'));
        setShowCourses(GetDataPage('courses'));
        setIdPage(GetListPag('currentPageId'));

        setName(InitialData('name'));
        setLogin(InitialData('login'));
        setEmail(InitialData('email'));
        setPhone(InitialData('phone'));
        setFile(InitialData('file'));
        setCountry(InitialData('country'));
        setAddress(InitialData('address'));
        setCep(InitialData('cep'));
        setCity(InitialData('city'));
        setUf(InitialData('uf'));
        setCpf(InitialData('cpf'));
        setNote(InitialData('note'));
        setNivel(InitialData('nivel'));
        setListTeam(InitialData('list_team'));
        setCoursesReleased(InitialData('courses_released'));
    }, [dataPage]);

    useEffect(()=>{
        setDataPage(GetDataPage('students'));
        setShowTeamList(GetDataPage('team'));
        setShowCourses(GetDataPage('courses'));
        setIdPage(GetListPag('currentPageId'));

        setName(InitialData('name'));
        setLogin(InitialData('login'));
        setEmail(InitialData('email'));
        setPhone(InitialData('phone'));
        setFile(InitialData('file'));
        setCountry(InitialData('country'));
        setAddress(InitialData('address'));
        setCep(InitialData('cep'));
        setCity(InitialData('city'));
        setUf(InitialData('uf'));
        setCpf(InitialData('cpf'));
        setNote(InitialData('note'));
        setNivel(InitialData('nivel'));
        setListTeam(InitialData('list_team'));
        setCoursesReleased(InitialData('courses_released'));
    }, [idPage]);

    function InitialData(type){
        if(idPage !=0){
            const newData = dataPage.find(item => item.id == idPage);
            return newData[type];
        }
        if(type == 'list_team' || type == 'courses_released'){
            return [];
        }
        if(type == 'nivel'){
            return '0';
        }
        return '';
    }

    function ShowListCourses(){
        const options = []
        showCourses.map((key, index)=>{
            options.push({
                "value": key.id, "label": key.title
            });
        });
        return options;
    }

    function ListCourseClasses(){
        const newData = [];
        Object.keys(showCourses).map((key, index)=>{
            if(showCourses[key].id == addCourse.value){
                Object.keys(showCourses[key].list_classes).map((key_, index_)=>{
                    newData.push({ "value": showCourses[key].list_classes[key_].id, "label": showCourses[key].list_classes[key_].title });
                });

            }
        });
        return(
            <div className="div_show_data div_space courses">
                <Select className="div_space" defaultOptions options={ newData } onChange={ (e)=> { setAddClasses(e) } } value={ addClasses } isMulti />
            </div>
        )
    }

    function NewTeam(){
        setListTeam([...listTeam, { "id": 0, "name": "" }]);
    }

    function ReturnPage(){
        SetListPag('currentPage', 'students');
        SetListPag('currentPageId', 'remuve');
    }

    function OpenFile(type, file){
        SetModalData('ShowFile', { "type": type, "file": file });
        SetModalState('ShowFile', true);
    }

    function AddFile(value){
        if(value){
            setFile(value);
            setState(true);
        }else {
            setState(false);
        }
    }

    function DeleteData(type, index, id, value){
        switch (type) {
            case 'delete_team':
                    if(id == 0){
                        const newData = [...listTeam];
                        newData.splice(index, 1);
                        setListTeam(newData);
                    }else {
                        SetModalData('Confirmation', { "origin": "students", "type" : type, "id" : id, "name": value });
                        SetModalState('Confirmation', true);
                    }
                break;
            
            case 'delete_classe': case 'delete_course':
                    SetModalData('Confirmation', { "origin": "students", "type" : type, "id" : id, "name": value });
                    SetModalState('Confirmation', true);
                break;
        }
    }

    function HandleTeam(index, value){
        const newData = [...listTeam];
        newData[index].name = value;
        setListTeam(newData);
    }

    function SaveData(event){
        event.preventDefault();
        setLoading(true);
        let infCourse = 0;
        if(Object.keys(addCourse).length > 0){
            infCourse = addCourse.value;
        }
        Reg_Students(idPage, name, login, email, phone, file, country, address, cep, city, uf, cpf, note, pass, nivel, listTeam, infCourse, typeAdd, addClasses, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
        setTypeAdd('');
        setAddCourse([]); 
        setAddClasses([]);
        setStateNewCourse(false);
        setState(false);
    }

    function CallbackSuccess(){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }
    
    return(
        <div className="Students_Details">
            <div className={ loading == true ? "return_data_save div_loading" : "return_data_save" }>
                <img alt="loading" src="./assets/loading.gif" className="loading" />
            </div>
            <form onSubmit={ SaveData } className="div_login">
                <div className="new_block">
                    <div className="div_return" onClick={ ()=>{ ReturnPage() } }>
                        <SvgReturn color="#111827" className="icons" />
                        voltar
                    </div>
                    <div className="title_page">Detalhes</div>
                    <button className="new_block_text">
                        <SvgSave color="#ffffff" className="icons" />
                        Salvar
                    </button>
                </div>

                <div className="list_data_page">                    
                    <div className="div_input">
                        
                        <div className="div_show_data">  
                            <select className="access" onChange={ (e)=>{ setNivel(e.target.value) } } value={ nivel }>
                                <option value="0">Free</option>
                                <option value="1">Bronze</option>
                                <option value="2">Prata</option>
                                <option value="3">Ouro</option>
                            </select>
                            <span className="span_input">Acesso</span>
                        </div>

                        <div className="div_show_data div_space">
                            <input type="text" className="data_input" onChange={ (e)=>{ setName(e.target.value) } } value={ name } maxLength="140" required />
                            <span className="span_input" maxLength="14">Nome</span>
                        </div>

                        <div className="div_show_data">
                            <input type="text" className="cpf" onChange={ (e)=>{ setCpf(e.target.value); } } value={ cpfMask(cpf) } maxLength="14" />
                            <span className="span_input">CPF</span>
                        </div>

                        <div className="div_show_data">
                            <input type="text" className="login" onChange={ (e)=>{ setLogin(e.target.value) } } value={ login } maxLength="20" required />
                            <span className="span_input" maxLength="14">Login</span>
                        </div>

                        <div className="div_show_data">
                            <input type="password" className="pass" onChange={ (e)=>{ setPass(e.target.value) } } maxLength="20" />
                            <span className="span_input">Senha</span>
                        </div>

                        <div className="div_show_data">   
                            <div className={ state == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                <label>
                                    { state == true ? "Foto adicionada" : "Adicionar foto" }
                                    <input type="file" onChange={ (e)=>{ AddFile(e.target.files[0]); } } accept="image/*"/>
                                </label>
                            </div>
                            {
                                file !='' ?
                                    state == true ?
                                    <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(file)) } }>
                                        <SvgImg color="#324d6b" className="icons"/>
                                    </div> :
                                    <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "login/" + file) } }>
                                        <SvgImg color="#324d6b" className="icons"/>
                                    </div>
                                : ""
                            }                       
                        </div>

                        <div className="div_show_data">
                            <input type="email" className="email" onChange={ (e)=>{ setEmail(e.target.value) } } value={ email.replaceAll(' ', '') } maxLength="140" required />
                            <span className="span_input">E-mail</span>
                        </div>

                        <div className="div_show_data">
                            <input type="text" className="phone" onChange={ (e)=>{ setPhone(e.target.value) } } value={ phoneMask(phone) } />
                            <span className="span_input">Telefone</span>
                        </div>

                        <div className="div_show_data">
                            <input type="text" className="country" onChange={ (e)=>{ setCountry(e.target.value) } } value={ country } />
                            <span className="span_input">País</span>
                        </div>

                        <div className="div_show_data div_space">
                            <input type="text" className="data_input" onChange={ (e)=>{ setAddress(e.target.value) } } value={ address } />
                            <span className="span_input" maxLength="14">Endereço</span>
                        </div>

                        <div className="div_show_data">
                            <input type="text" className="city" onChange={ (e)=>{ setCity(e.target.value) } } value={ city } />
                            <span className="span_input" maxLength="14">Cidade</span>
                        </div>

                        <div className="div_show_data">
                            <input type="text" className="uf" onChange={ (e)=>{ setUf(e.target.value) } } value={ uf } />
                            <span className="span_input" maxLength="14">UF</span>
                        </div>

                        <div className="div_show_data">
                            <input type="text" className="cep" onChange={ (e)=>{ setCep(e.target.value) } } value={ cepMask(cep) } />
                            <span className="span_input" maxLength="14">CEP</span>
                        </div>

                    </div>
                </div>
                
                <div className="list_data_page block_cinza"> 
                    <div className="block_title">
                        <div className="">
                            Observação
                        </div>
                    </div>
                    <div className="div_input">
                        <JoditEditor className="show_textarea" ref={ editText_1 } config={ config } value={ note != '' ? note.replaceAll('&#34;', '"') : "" } onBlur={ newContent => setNote(newContent) } />
                    </div>
                </div>
                
                <div className="list_data_page block_cinza"> 
                    <div className="block_title">
                        <div className="">
                            Turmas
                        </div>
                        <div className="button_new_data" onClick={ ()=>{ NewTeam(); } }>
                            Adicionar nova turma
                        </div>
                    </div>
                    <div className="div_input">
                        {
                            listTeam.length > 0 ?
                                listTeam.map((key, index)=>{
                                    return(
                                        <div className="page_released" key={ index }>
                                            <div className="name_team">
                                                {
                                                    key.id == 0 ?                                                     
                                                    <select onChange={ (e)=>{ HandleTeam(index, e.target.value);  } }>
                                                        <option value="">#</option>
                                                        {
                                                            showTeamList.map((key_1, index_1)=>{
                                                                return(
                                                                    <option value={ key_1.id } key={ index_1 }>{ key_1.name }</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    :
                                                    key.name
                                                }
                                            </div>
                                            <div className="" onClick={ ()=>{ DeleteData('delete_team', index, key.id, key.name) } }>
                                                <SvgDelete color="#f00000" className="icons" />
                                            </div>
                                        </div>
                                    )
                                })
                            : 
                            <div className="no_data">Nenhuma turma adicionada até o momento...</div>
                        }  
                    </div>
                </div>
                
                <div className="list_data_page block_cinza"> 
                    <div className="block_title">
                        <div className="">
                            Cursos liberados
                        </div>
                        <div className="button_new_data" onClick={ ()=>{ setStateNewCourse(!stateNewCourse); } }>
                            { stateNewCourse == true ? "Cancelar" : "Liberar nova aula" }
                        </div>
                    </div>
                    {
                        stateNewCourse != true ? null : 
                        <div className="div_input add_new_course">
                            <div className="div_show_data div_space courses">                            
                                <Select className="div_space" defaultOptions options={ ShowListCourses() } onChange={ (e)=> { setAddCourse(e); setAddClasses([]); } } value={ addCourse } /> 
                            </div>
                            <div className="div_show_data courses">  
                                <select onChange={ (e)=>{ setTypeAdd(e.target.value) } } style={ { padding: 8 } } value={ typeAdd }>
                                    <option value="0">Todas aulas</option>
                                    <option value="1">Escolher aulas</option>
                                </select>
                            </div>
                            {
                                typeAdd == 1 ? ListCourseClasses(addCourse.value) : ""
                            }
                        </div>
                    }
                    <div className="div_input">
                        {
                            coursesReleased.length > 0 ?
                                <div className="show_courses">
                                    <table width="100%" cellPadding="2" cellSpacing="6">
                                        <tbody>
                                            <tr>
                                                <th width="300">Curso</th>
                                                <th>Aulas</th>
                                                <th width="20" align="right"></th>
                                            </tr>
                                        </tbody>
                                        {
                                            coursesReleased.map((key, index)=>{
                                                if(key.type == 0){
                                                    return(
                                                        <tbody key={ index }>
                                                            <tr>
                                                                <td>{ key.nameCourse }</td>
                                                                <td>Todas aulas liberadas</td>
                                                                <td align="right">
                                                                    <div className="" onClick={ ()=>{ DeleteData('delete_course', index, key.id, key.nameCourse) } }>
                                                                        <SvgDelete color="#f00000" className="icons" />
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    )
                                                }else {
                                                    return(
                                                        <tbody key={ index }>
                                                            <tr>
                                                                <td className="table_hover" rowSpan={ key.list_classes.length + 1 }>{ key.nameCourse }</td>
                                                            </tr>
                                                            {
                                                                key.list_classes.map((key_1, index_1)=>{
                                                                    return(
                                                                        <tr key={ index_1 }>
                                                                            <td className="table_border_left">{ key_1.nameClasse }</td>
                                                                            <td align="right">
                                                                                <div className="" onClick={ ()=>{ DeleteData('delete_classe', index_1, key_1.id, key_1.nameClasse) } }>
                                                                                    <SvgDelete color="#f00000" className="icons" />
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                        </tbody>
                                                    )
                                                }
                                            })
                                        }
                                    </table>
                                </div>
                            :
                            stateNewCourse != true ? <div className="no_data">Nenhum curso liberado até o momento...</div> : null
                        }  
                    </div>
                </div>

            </form>
        </div>
    )
}