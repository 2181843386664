import { useState, useEffect } from "react";

import './Courses.css';

import { SvgAddNewData, SvgCancel, SvgDelete, SvgEdit, SvgUser, SvgUserDisabled, SvgSearch, SvgSetaUp, SvgSetaDown, SvgTriagle } from "components/SvgFile";

import { GetDataPage, RegisterDataPage } from "interface/Data";
import { GetListPag, RegisterListPag, SetListPag } from "interface/Page";
import { SetModalData, SetModalState } from "interface/PopUp";
import { Reg_AltPosition } from "services/RegisterData";

export default function Courses(){

    const [ search, setSearch ]             = useState('');
    const [ showPageData, setShowPageData ] = useState(GetDataPage('courses'));

    const [ category, setCategory ]             = useState('All');
    const [ listCategory, setListCategory ]     = useState(ListCategory());
    const [ statusCategory, setStatusCategory ] = useState(false);

    const [ itensPage, setItensPage ] = useState(40);
    const [ showPage, setShowPage ]   = useState(0);

    const page         = Math.ceil(showPageData.length / itensPage);
    const startItens   = showPage * itensPage;
    const endItens     = startItens + itensPage;
    const currentItens = showPageData.slice(startItens, endItens);

    useEffect(()=>{
        RegisterDataPage('courses', setShowPageData);
    }, []);

    function ListCategory(){
        const newData = [];
        GetDataPage('courses').forEach(item =>{
            item.category.forEach(item_1 =>{
                newData.push(item_1.name);
            });
        })
        const duplicate = newData.filter((item, index) => newData.indexOf(item) === index);
        return duplicate;
    }

    function SearchInput(value){
        const newList = [];
        if(value){
            GetDataPage('courses').forEach(item =>{
                if(item.title.toLowerCase().indexOf(value.toLowerCase()) != -1){
                    newList.push(item);
                }
                if(item.subtitle.toLowerCase().indexOf(value.toLowerCase()) != -1){
                    newList.push(item);
                }
                if(item.text.toLowerCase().indexOf(value.toLowerCase()) != -1){
                    newList.push(item);
                }
            });
            const duplicate = newList.filter((item, index) => newList.indexOf(item) === index);
            setShowPageData(duplicate);
        }else {
            setShowPageData(GetDataPage('courses'));
        }
        setSearch(value);
        setCategory('All');
        setStatusCategory(false);
    }

    function SearchCategory(value){
        const newList = [];
        if(value){
            if(value == 'All'){
                setShowPageData(GetDataPage('courses'));
            }else {
                GetDataPage('courses').forEach(item =>{
                    item.category.forEach(item_1 =>{
                        if(item_1.name.toLowerCase().indexOf(value.toLowerCase()) != -1){
                            newList.push(item);
                        }
                    });
                });
                const duplicate = newList.filter((item, index) => newList.indexOf(item) === index);
                setShowPageData(duplicate);
            }
        }else {
            setShowPageData(GetDataPage('courses'));
        }
        setSearch('');
        setCategory(value);
        setStatusCategory(false);
    }

    function DeleteData(id, value){
        SetModalData('Confirmation', { "origin": "access_dash", "type" : "hide_courses", "id" : id, "name": value });
        SetModalState('Confirmation', true);
    }

    function PageClick(idPage){
        SetListPag('currentPage', 'courses_details')
        SetListPag('currentPageId', idPage);
    }

    function ChangePosition(id, order, type){
        Reg_AltPosition('courses', id, order, type, ()=>{ }, ()=>{ CallbackError() });
    }

    function CallbackError(){

    }

    return(
        <div className="Courses">
            <div className="new_block">
                <div className="input_search">

                    <SvgSearch color="#111827" className="icons_search" />
                    <input type="text" className="search" onChange={ (e)=>{ SearchInput(e.target.value) } } value={ search } required />
                    <span className="span_search">Pesquisar...</span>

                    <div className="click_show_search">
                        <div className="div_input" onClick={ ()=>{ setStatusCategory(!statusCategory); } }>
                            <div className="div_select">
                                { category }
                                {
                                    statusCategory == true ?
                                    <SvgTriagle color="#747474" className="icon_select_open" /> :
                                    <SvgTriagle color="#747474" className="icon_select_close" />
                                }
                            </div>
                            <span className="span_name_select">Categoria</span>
                        </div>
                        {
                            statusCategory == true ?
                            <div className="click_show_search_selected">
                                <div className="opt_list_search" onClick={ ()=>{ SearchCategory('All'); } }>All</div>
                                {
                                    listCategory.map((key, index)=>{
                                        return (
                                            <div className="opt_list_search" key={ index } onClick={ ()=>{ SearchCategory(key); } }>
                                                { key }
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            : null
                        }
                    </div>

                </div>

                <div className="title_page">Cursos</div>
                <div className="new_block_text" onClick={ ()=>{ PageClick(0) } }>
                    <SvgAddNewData color="#ffffff" className="icons" />
                    Curso
                </div>
            </div>
            <div className="list_data_page">
                <table width="100%" cellPadding="2" cellSpacing="6">
                    <tbody>
                        <tr>
                            <th width="20" align="center">#</th>
                            <th>Título</th>
                            <th width="60">Order</th>
                            <th width="60" align="right"></th>
                        </tr>
                    </tbody>
                    <tbody>
                        {
                            currentItens.map((key, index)=>{
                                return(
                                    <tr key={ index }>
                                        <td align="center">{ startItens + index + 1 }</td>
                                        <td>{ key.title }</td>
                                        <td align="right">
                                            <div className="div_opt_alt">
                                                {
                                                    index == 0 ?
                                                    <div className="space_div"></div> :
                                                    <div onClick={ ()=>{ ChangePosition(key.id, key.order_, 'up') } }>
                                                        <SvgSetaUp color="#324d6b" className="icons"/>
                                                    </div>
                                                }
                                                {
                                                    (index + 1) == currentItens.length ?
                                                    <div className="space_div"></div> :
                                                    <div onClick={ ()=>{ ChangePosition(key.id, key.order_, 'down') } }>
                                                        <SvgSetaDown color="#324d6b" className="icons"/>
                                                    </div>
                                                }
                                            </div>
                                        </td>
                                        <td align="right">
                                            <div className="div_opt_alt">
                                                <div onClick={ ()=>{ DeleteData(key.id, key.title) } }>
                                                    <SvgCancel color="#F00000" className="icons"/>
                                                </div>
                                                <div onClick={ ()=>{ PageClick(key.id) } }>
                                                    <SvgEdit color="#324d6b" className="icons"/>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
            {
                showPageData.length >= 40 ?
                <div className="list_pages">
                    {
                        Array.from(Array(page), (item, index)=>{
                            return(
                                <div className={ showPage == index ? "numb_page" : "numb_page page_active" } key={ index } onClick={ (e)=>{ setShowPage(index) } }>
                                    { index + 1 }
                                </div>
                            )
                        })
                    }
                </div> : null
            }
        </div>
    )
}