import { useState, useEffect } from "react";

import './Login.css';

import Access from "services/AccessDash";

import { SvgLogotipo } from "components/SvgFile";

export default function Login(){

    const [ email, setEmail ]       = useState('');
    const [ password, setPassword ] = useState('');
    const [ msgError, setMsgError ] = useState('');

    function Register(event){
        event.preventDefault();
        Access("logar", email, password, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() })
    }

    function CallbackSuccess(){
    }

    function CallbackError(){
        setMsgError("E-mail ou senha incorreta!");
    }

    return(
        <div className="Login">
            <form onSubmit={ Register } className="div_login">
                <div className="logotipo">
                    <SvgLogotipo widthLogo="width_logo" className="svg_logotipo" />
                </div>
                <div className="show_login">

                    <div className="div_input">
                        <div className="">E-mail</div>
                        <div className="space_input">
                            <input type="email" onChange={ (e)=>{ setEmail(e.target.value) } } placeholder="teste@teste.com" required />
                        </div>
                    </div>

                    <div className="div_input">
                        <div className="">Senha</div>
                        <div className="space_input">
                            <input type="password" onChange={ (e)=>{ setPassword(e.target.value) } } placeholder="****" required />
                        </div>
                    </div>

                    <div className={ msgError ? "msg_error" : "msg_none" }>
                        { msgError }
                    </div>

                    <div className="div_button">
                        <button type="submit" className="button_login">
                            Login
                        </button>
                    </div>

                </div>
            </form>
        </div>
    )
}
