import Axios from 'axios';

import Cookies from "universal-cookie";

import { connectionPage, cookiesRegister, typeCookie, typeCookieEmail, typeCookiePage, typeCookiePassw } from 'dataFixed';

import { SetListPag } from 'interface/Page';
import { SetUserData } from 'interface/Users';
import { SetListData, SetListDataSingle } from 'interface/Data';

// Delete data
export function Reg_DeleteData(currentPage, type, id, CallbackSuccess, CallbackError){
    const data = new FormData();

    data.append('id', id);
    data.append('edit_', type);
    data.append('current_page', currentPage);

    Axios({
        url     : connectionPage + '/php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        if(currentPage == 'accessDash' || currentPage == 'accessDash_details'){
            SetListDataSingle('accessDash', response.data.accessDash);

        }else if(currentPage == 'courses' || currentPage == 'courses_details' || currentPage == 'courses_details_classes'){
            SetListDataSingle('courses', response.data.courses);

        }else if(currentPage == 'students' || currentPage == 'students_details'){
            SetListDataSingle('students', response.data.students);

        }else if(currentPage == 'financialEntry' || currentPage == 'financialEntry_details'){
            SetListDataSingle('financialEntry', response.data.financialEntry);

        }else if(currentPage == 'financialExit' || currentPage == 'financialExit_details'){
            SetListDataSingle('financialExit', response.data.financialExit);

        }else if(currentPage == 'team' || currentPage == 'team_details'){
            SetListDataSingle('team', response.data.team);

        }else if(currentPage == 'sitePayment'){
            SetListDataSingle('sitePayment', response.data.sitePayment);

        }else if(currentPage == 'siteHowWorks'){
            SetListDataSingle('siteHowWorks', response.data.siteHowWorks);

        }else if(currentPage == 'siteHome' || currentPage == 'siteHome_details'){
            SetListDataSingle('siteHome', response.data.siteHome);

        }else if(currentPage == 'siteMentoring' || currentPage == 'siteMentoring_details'){
            SetListDataSingle('siteMentoring', response.data.siteMentoring);

        }else if(currentPage == 'siteRolo' || currentPage == 'siteRolo_details'){
            SetListDataSingle('siteRolo', response.data.siteRolo);
        }
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}

// Edit data user
export function Reg_UserDash(userId, idPage, name, pass, email, file, access, addPage, CallbackSuccess, CallbackError){
    const cookies = new Cookies();

    const data = new FormData();
    data.append('edit_', 'edit_profile');
    data.append('current_page', 'accessDash');

    data.append('userId', userId);
    data.append('idPage', idPage);
    data.append('name', name);
    data.append('pass', pass);
    data.append('email', email);
    data.append('file', file);
    data.append('access', access);
    addPage.forEach((element, index) => {
        data.append('page_value[]', element.value);
        data.append('page_name[]', element.label);
    });

    Axios({
        url     : connectionPage + '/php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        SetListDataSingle('accessDash', response.data.accessDash);
        if(response.data.idPage !=0){
            SetListPag('currentPageId', response.data.idPage);
        }
        if(userId == idPage){
            const newData = response.data.accessDash.find(item => item.id == userId);
            cookies.set(typeCookieEmail, email, '/', cookiesRegister);
            if(pass !=''){
                cookies.set(typeCookiePassw, newData['pass'], '/', cookiesRegister);
            }
            SetUserData('id', newData['id']);
            SetUserData('file', newData['file']);
            SetUserData('access', newData['access']);
            SetUserData('page', newData['page']);
        }

        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}

// Change position
export function Reg_AltPosition(current_page, id, order, type, CallbackSuccess, CallbackError){
    const data = new FormData();
    data.append('edit_', 'alt_position');
    data.append('current_page', current_page);

    data.append('id', id);
    data.append('order', order);
    data.append('type', type);

    Axios({
        url     : connectionPage + '/php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {

        if(current_page == 'courses' || current_page == 'courses_details'){
            SetListDataSingle('courses', response.data.courses);

        }else if(current_page == 'siteNameMenu'){
            SetListDataSingle('siteNameMenu', response.data.siteNameMenu);

        }else if(current_page == 'siteHome'){
            SetListDataSingle('siteHome', response.data.siteHome);

        }else {
            SetListDataSingle(current_page, response.data);
        }
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}

// Register data courses
export function Reg_EditOrRegisterCourses(idPage, typeVideo, video, file, img, highlight, title, subtitle, free, bronze, prata, gold, category, text, observation, CallbackSuccess, CallbackError){
    const data = new FormData();
    data.append('edit_', 'edit_or_register_courses');
    data.append('current_page', 'courses');

    data.append('id_page', idPage);
    data.append('type_video', typeVideo);
    data.append('video', video);
    data.append('file', file);
    data.append('img', img);
    data.append('highlight', highlight);
    data.append('title', title);
    data.append('subtitle', subtitle);
    data.append('free', free);
    data.append('bronze', bronze);
    data.append('prata', prata);
    data.append('gold', gold);
    category.forEach((element, index) => {
        data.append('category_id[]', element.id);
        data.append('category_name[]', element.name);
        data.append('category_new_name[]', element.new_name);
    });
    data.append('text', text);
    data.append('observation', observation);

    Axios({
        url     : connectionPage + '/php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {

        SetListDataSingle('courses', response.data.courses);
        if(response.data.id_page !=0){
            SetListPag('currentPageId', response.data.idPage);
        }
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}
// Register data classes
export function Reg_EditOrRegisterClasses(idPage, idCourses, typeVideo, video, free, module, newModule, title, download, tips, link, observation, CallbackSuccess, CallbackError){
    const data = new FormData();
    data.append('edit_', 'edit_or_register_classes');
    data.append('current_page', 'courses');

    data.append('id_page', idPage);
    data.append('id_courses', idCourses)
    data.append('type_video', typeVideo);
    data.append('video', video);
    data.append('free', free);
    data.append('module', module);
    data.append('newModule', newModule);
    data.append('title', title);
    data.append('tips', tips);
    data.append('link', link);
    data.append('observation', observation);
    
    download.forEach((element, index) => {
        data.append('download_id[]', element.id);
        data.append('download_title[]', element.title);
        data.append('download_img_' + index, element.img);
        data.append('download_file_' + index, element.file);
    });

    Axios({
        url     : connectionPage + '/php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {

        SetListDataSingle('courses', response.data.courses);
        if(response.data.id_page !=0){
            SetListPag('currentPageIdSegund', response.data.idPage);
        }
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}

// Register students
export function Reg_Students(id_page, name, login, email, phone, file, country, address, cep, city, uf, cpf, note, pass, nivel, listTeam, id_courses, typeAdd, classes, CallbackSuccess, CallbackError){
    const data = new FormData();

    data.append('edit_', 'edit_or_register_students');
    data.append('current_page', 'students');

    data.append('id_page', id_page);
    data.append('name', name);
    data.append('login', login);
    data.append('email', email);
    data.append('phone', phone);
    data.append('file', file);
    data.append('country', country);
    data.append('address', address);
    data.append('cep', cep);
    data.append('city', city);
    data.append('uf', uf);
    data.append('cpf', cpf);
    data.append('note', note);
    data.append('pass', pass);
    data.append('nivel', nivel);
    data.append('id_courses', id_courses);
    data.append('type', typeAdd);

    listTeam.forEach(element => {
        if(element.id == 0){
            data.append('team[]', element.name);
        }
    });

    classes.forEach(element => {
        data.append('id_classes[]', element.value);
    });

    Axios({
        url     : connectionPage + '/php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {

        SetListDataSingle('students', response.data.students);
        if(response.data.id_page !=0){
            SetListPag('currentPageId', response.data.id_page);
        }
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}

// Register team
export function Reg_Team(id_page, name, note, idCourse, studentsAdd, CallbackSuccess, CallbackError){
    const data = new FormData();

    data.append('edit_', 'edit_or_register_team');
    data.append('current_page', 'team');

    data.append('id_page', id_page);
    data.append('name', name);
    data.append('note', note);
    data.append('id_course', idCourse);

    studentsAdd.forEach(element => {
        data.append('id_students[]', element.value);
    });

    Axios({
        url     : connectionPage + '/php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {

        SetListDataSingle('team', response.data.team);
        if(response.data.id_page !=0){
            SetListPag('currentPageId', response.data.id_page);
        }
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}

// Register data team
export function Reg_TeamStatusClasses(id_page, id_user, id_classe, id_reg, release, punctuation, CallbackSuccess, CallbackError){
    const data = new FormData();

    data.append('edit_', 'edit_status_classes');
    data.append('current_page', 'team');

    data.append('id_page', id_page);
    data.append('id_team_id', id_user);
    data.append('id_classe', id_classe);
    data.append('id_reg', id_reg);
    data.append('release', release);
    data.append('punctuation', punctuation);

    Axios({
        url     : connectionPage + '/php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {

        SetListDataSingle('team', response.data.team);
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}

// Register financial entry
export function Reg_Entry(idPage, title, status, bank, newBank, agency, bill, dueDate, paymentData, cash, file, typeOrigin, origin, newOrigin, idLogin, typePayment, newPayment, codePag, fee, note, CallbackSuccess, CallbackError){
    const data = new FormData();

    data.append('edit_', 'edit_or_register_entry');
    data.append('current_page', 'financialEntry');

    data.append('idPage', idPage);
    data.append('title', title);
    data.append('status', status);
    data.append('bank', bank);
    data.append('newBank', newBank);
    data.append('agency', agency);
    data.append('bill', bill);
    data.append('dueDate', dueDate);
    data.append('paymentData', paymentData);
    data.append('cash', cash);
    data.append('file', file);
    data.append('typeOrigin', typeOrigin);
    data.append('origin', origin);
    data.append('newOrigin', newOrigin);
    data.append('idLogin', idLogin);
    data.append('typePayment', typePayment);
    data.append('newPayment', newPayment);
    data.append('codePag', codePag);
    data.append('fee', fee);
    data.append('note', note);

    Axios({
        url     : connectionPage + '/php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        
        SetListDataSingle('financialEntry', response.data.financialEntry);
        if(response.data.id_page !=0){
            SetListPag('currentPageId', response.data.id_page);
        }
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}
// Register financial exit
export function Reg_Exit(idPage, title, status, bank, newBank, agency, bill, dueDate, paymentData, cash, file, typeOrigin, origin, newOrigin, idLogin, typePayment, newPayment, codePag, fee, note, CallbackSuccess, CallbackError){
    const data = new FormData();

    data.append('edit_', 'edit_or_register_exit');
    data.append('current_page', 'financialExit');

    data.append('idPage', idPage);
    data.append('title', title);
    data.append('status', status);
    data.append('bank', bank);
    data.append('newBank', newBank);
    data.append('agency', agency);
    data.append('bill', bill);
    data.append('dueDate', dueDate);
    data.append('paymentData', paymentData);
    data.append('cash', cash);
    data.append('file', file);
    data.append('typeOrigin', typeOrigin);
    data.append('origin', origin);
    data.append('newOrigin', newOrigin);
    data.append('idLogin', idLogin);
    data.append('typePayment', typePayment);
    data.append('newPayment', newPayment);
    data.append('codePag', codePag);
    data.append('fee', fee);
    data.append('note', note);

    Axios({
        url     : connectionPage + '/php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        SetListDataSingle('financialExit', response.data.financialExit);
        if(response.data.id_page !=0){
            SetListPag('currentPageId', response.data.id_page);
        }
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}

// Site - Edit data menu
export function Reg_EditMenu(listMenus, CallbackSuccess, CallbackError){
    const data = new FormData();
    data.append('current_page', 'siteNameMenu');
    data.append('edit_', 'edit_name_menu');

    listMenus.forEach((element, index) => {
        data.append('id[]', element.id);
        data.append('order_[]', element.order_);
        data.append('name[]', element.name);
    });

    Axios({
        url     : connectionPage + '/php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        SetListDataSingle('siteNameMenu', response.data.siteNameMenu);
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    });
}

// Site - home, mentoria e rolo
export function Reg_DataSite(idPage, page, origin, type, contents, CallbackSuccess, CallbackError){
    const data = new FormData();
    data.append('edit_', 'edit_or_register');
    data.append('current_page', page);

    data.append('idPage', idPage);
    data.append('origin', origin);
    data.append('type', type);
    
    switch(type){
        case "Home":
                data.append('file', contents.bg_page);
                data.append('title_1', contents.title_1);
                data.append('title_2', contents.title_2);
                data.append('title_3', contents.title_3);
                data.append('title_4', contents.title_4);
                data.append('title_5', contents.title_5);
                data.append('title_6', contents.title_6);
                data.append('title_7', contents.title_7);
                data.append('title_8', contents.title_8);
            break;

        case "Apresentação com vídeo":
                data.append('bg_page', contents.bg_page);
                data.append('typeVideo', contents.typeVideo);
                data.append('video', contents.video);
                data.append('button', contents.button);
                data.append('title_1', contents.title_1);
                data.append('title_2', contents.title_2);
                data.append('title_3', contents.title_3);
                data.append('text_1', contents.text_1);
                data.append('text_2', contents.text_2);
            break;

        case "Trabalhos de alunos":
                data.append('typeVideo', contents.typeVideo);
                data.append('video', contents.video);
                data.append('title', contents.title);
                data.append('img', contents.img);
            break;

        case "Explicação dos conteúdos":
                data.append('bg_page', contents.bg_page);
                data.append('title_1', contents.title_1);
                data.append('title_2', contents.title_2);
                data.append('title_3', contents.title_3);
                data.append('text_1', contents.text_1);
                data.append('text_2', contents.text_2);
                data.append('text_3', contents.text_3);
                data.append('name_1', contents.name_1);
                data.append('name_2', contents.name_2);
                data.append('file', contents.file);
                data.append('img', contents.img);
            break;

        case "Módulos e carga horária":
                data.append('img', contents.img);
                data.append('bg_page', contents.bg_page);
                data.append('title_1', contents.title_1);
                data.append('text_1', contents.text_1);
                contents.dataTable.forEach(element => {
                    data.append('dataTable_id[]', element.id);
                    data.append('dataTable_title[]', element.title);
                    data.append('dataTable_hour[]', element.data);
                    data.append('dataTable_color[]', element.color);
                });
            break;

        case "Depoimentos":
                data.append('title_1', contents.title_1);
                data.append('bg_page', contents.bg_page);
                contents.dataProfile.forEach((element, index) => {
                    data.append('id_depositions[]', element.id);
                    data.append('img_' + index, element.img);
                    data.append('title[]', element.title);
                    data.append('text[]', element.text);
                    data.append('area[]', element.area);
                    data.append('data[]', element.data);
                });
            break;

        case "Atendimento personalizado":
                data.append('file', contents.file);
                data.append('title_1', contents.title_1);
                data.append('title_2', contents.title_2);
                data.append('title_3', contents.title_3);
                data.append('title_4', contents.title_4);
                data.append('text_1', contents.text_1);
                data.append('text_2', contents.text_2);
                data.append('text_3', contents.text_3);
            break;

        case "Perfil do professor":
                data.append('typeBg', contents.typeBg);
                data.append('bgPage', contents.bgPage);
                data.append('type_alignment', contents.type_alignment);
                data.append('img', contents.img);
                data.append('sampleImage', contents.sampleImage);
                data.append('name_1', contents.name_1);
                data.append('name_2', contents.name_2);
                data.append('text_1', contents.text_1);
                data.append('behance', contents.behance);
                data.append('artstation', contents.artstation);
            break;

    }

    Axios({
        url     : connectionPage + '/php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        if(page == 'siteHome'){
            SetListDataSingle('siteHome', response.data.siteHome);

        }else if(page == 'siteMentoring'){
            SetListDataSingle('siteMentoring', response.data.siteMentoring);

        }else if(page == 'siteRolo'){
            SetListDataSingle('siteRolo', response.data.siteRolo);
        }

        if(response.data.id_page !=0){
            SetListPag('currentPageId', response.data.id_page);
        }
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    });
}
// Site - New profile
export function Reg_NewProfile(idPage, page, file, name, area, date, text, CallbackSuccess, CallbackError){
    const data = new FormData();
    data.append('edit_', 'new_profile');
    data.append('current_page', page);

    data.append('idPage', idPage);
    data.append('file', file);
    data.append('name', name);
    data.append('text', text);
    data.append('area', area);
    data.append('date', date);

    Axios({
        url     : connectionPage + '/php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {

        if(page == 'siteHome'){
            SetListDataSingle('siteHome', response.data.siteHome);
            
        }else if(page == 'siteMentoring'){
            SetListDataSingle('siteMentoring', response.data.siteMentoring);

        }else if(page == 'siteRolo'){
            SetListDataSingle('siteRolo', response.data.siteRolo);
        }
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}

// HowWorks
export function Reg_HowWorks(title_1, title_2, text, doubts, CallbackSuccess, CallbackError){
    const data = new FormData();
    data.append('edit_', 'edit_or_register');
    data.append('current_page', 'siteHowWorks');

    data.append("title_1", title_1);
    data.append("title_2", title_2);
    data.append("text", text);

    doubts.forEach(element_2=>{
        data.append("doubts_id[]", element_2.id);
        data.append("doubts_question[]", element_2.question);
        data.append("doubts_response[]", element_2.response);
    });

    Axios({
        url     : connectionPage + '/php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        
        SetListDataSingle('siteHowWorks', response.data.siteHowWorks);
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}
// HowWorks - Doubts
export function Reg_NewData_Doubts(question, response, CallbackSuccess, CallbackError){
    const data = new FormData();
    data.append('edit_', 'register_doubts');
    data.append('current_page', 'siteHowWorks');

    data.append('question', question);
    data.append('response', response);

    Axios({
        url     : connectionPage + '/php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {

        SetListDataSingle('siteHowWorks', response.data.siteHowWorks);
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}

// Payment
export function Reg_NewData_Payment(id_page, title, subtitle, showHome, showMentoring, showRolo, listPayment, CallbackSuccess, CallbackError){
    const data = new FormData();
    data.append('edit_', 'edit_payment');
    data.append('current_page', 'sitePayment');

    data.append('id_page', id_page);
    data.append('title', title);
    data.append('subtitle', subtitle);
    data.append('showHome', showHome);
    data.append('showMentoring', showMentoring);
    data.append('showRolo', showRolo);

    listPayment.forEach((element, index) => {
        data.append('payment_index[]', index);
        data.append('payment_id[]', element.id);
        data.append('payment_best_cost_benefit[]', element.best_cost_benefit);
        data.append('payment_data[]', element.data);
        data.append('payment_cash[]', element.cash);
        data.append('payment_title[]', element.title);
        data.append('payment_subtitle[]', element.subtitle);
        data.append('payment_color[]', element.color);
        data.append('payment_type[]', element.type);

        element.category.forEach((element_1, index_1) => {
            data.append('category_index[]', index);
            data.append('category_id[]', element_1.id);
            data.append('category_id_given[]', element_1.id_given);
            data.append('category_data[]', element_1.data);
            data.append('category_name[]', element_1.name);
        });
    });

    Axios({
        url     : connectionPage + '/php/register.php',
        mode    : 'no-cors',
        method  : 'POST',
        data    : data
    })
    .then(response => {
        
        SetListDataSingle('sitePayment', response.data.sitePayment);
        CallbackSuccess();

    }).catch((error)=>{
        CallbackError();
    })
}