import { useState, useEffect } from "react";

import './Video.css';

import JoditEditor from 'jodit-react';
import { config } from "dataFixed";

import { SvgVimeo, SvgYoutube } from "components/SvgFile";

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage } from "interface/Data";
import { GetListPag, RegisterListPag } from "interface/Page";

export default function ModelSite_Video(props){

    const [ idPage, setIdPage ]     = useState(GetListPag('currentPageId'));
    const [ dataPage, setDataPage ] = useState(GetDataPage(props.origin));

    const [ bgPage, setBgPage ]       = useState(InitialData('bg_page'));
    const [ typeVideo, setTypeVideo ] = useState(InitialData('typeVideo'));
    const [ video, setVideo ]         = useState(InitialData('video'));
    const [ button, setButton]        = useState(InitialData('button'));
    const [ title_1, setTitle_1 ]     = useState(InitialData('title_1'));
    const [ title_2, setTitle_2 ]     = useState(InitialData('title_2'));
    const [ title_3, setTitle_3 ]     = useState(InitialData('title_3'));
    const [ text_1, setText_1 ]       = useState(InitialData('text_1'));
    const [ text_2, setText_2 ]       = useState(InitialData('text_2'));

    useEffect(()=>{
        RegisterDataPage(props.origin, setDataPage);
        RegisterListPag('currentPageId', setIdPage);
        
        LoadingData();
    }, []);

    useEffect(()=>{
        setDataPage(GetDataPage(props.origin));
        setIdPage(GetListPag('currentPageId'));

        setBgPage(InitialData('bg_page'));
        setTypeVideo(InitialData('typeVideo'));
        setVideo(InitialData('video'));
        setButton(InitialData('button'));
        setTitle_1(InitialData('title_1'));
        setTitle_2(InitialData('title_2'));
        setTitle_3(InitialData('title_3'));
        setText_1(InitialData('text_1'));
        setText_2(InitialData('text_2'));
        
        LoadingData();
    }, [dataPage]);

    useEffect(()=>{
        setDataPage(GetDataPage(props.origin));
        setIdPage(GetListPag('currentPageId'));

        setBgPage(InitialData('bg_page'));
        setTypeVideo(InitialData('typeVideo'));
        setVideo(InitialData('video'));
        setButton(InitialData('button'));
        setTitle_1(InitialData('title_1'));
        setTitle_2(InitialData('title_2'));
        setTitle_3(InitialData('title_3'));
        setText_1(InitialData('text_1'));
        setText_2(InitialData('text_2'));

        LoadingData();
    }, [idPage]);

    function InitialData(type){
        if(idPage !=0){
            if(dataPage.length > 0){
                const newData = dataPage.find(item => item.id == idPage);

                return newData[type];
            }
        }
        if(type == 'typeVideo'){
            return 'Vimeo';
        }
        return '';
    }

    function LoadingData(){
        props.ReturnData({ "bg_page": bgPage, "typeVideo": typeVideo, "video": video, "button": button, "title_1": title_1, "title_2": title_2, "title_3": title_3, "text_1": text_1, "text_2": text_2 });
    }

    function HandleBgPage(value){
        setBgPage(value);
        props.ReturnData({ "bg_page": value, "typeVideo": typeVideo, "video": video, "button": button, "title_1": title_1, "title_2": title_2, "title_3": title_3, "text_1": text_1, "text_2": text_2 });
    }

    function HandleTypeVideo(value){
        setTypeVideo(value);
        props.ReturnData({ "bg_page": bgPage, "typeVideo": value, "video": video, "button": button, "title_1": title_1, "title_2": title_2, "title_3": title_3, "text_1": text_1, "text_2": text_2 });
    }

    function HandleVideo(value){
        setVideo(value);
        props.ReturnData({ "bg_page": bgPage, "typeVideo": typeVideo, "video": value, "button": button, "title_1": title_1, "title_2": title_2, "title_3": title_3, "text_1": text_1, "text_2": text_2 });
    }

    function HandleButton(value){
        setButton(value);
        props.ReturnData({ "bg_page": bgPage, "typeVideo": typeVideo, "video": video, "button": value, "title_1": title_1, "title_2": title_2, "title_3": title_3, "text_1": text_1, "text_2": text_2 });
    }

    function HandleTitle_1(value){
        setTitle_1(value);
        props.ReturnData({ "bg_page": bgPage, "typeVideo": typeVideo, "video": video, "button": button, "title_1": value, "title_2": title_2, "title_3": title_3, "text_1": text_1, "text_2": text_2 });
    }

    function HandleTitle_2(value){
        setTitle_2(value);
        props.ReturnData({ "bg_page": bgPage, "typeVideo": typeVideo, "video": video, "button": button, "title_1": title_1, "title_2": value, "title_3": title_3, "text_1": text_1, "text_2": text_2 });
    }

    function HandleTitle_3(value){
        setTitle_3(value);
        props.ReturnData({ "bg_page": bgPage, "typeVideo": typeVideo, "video": video, "button": button, "title_1": title_1, "title_2": title_2, "title_3": value, "text_1": text_1, "text_2": text_2 });
    }

    function HandleText_1(value){
        setText_1(value);
        props.ReturnData({ "bg_page": bgPage, "typeVideo": typeVideo, "video": video, "button": button, "title_1": title_1, "title_2": title_2, "title_3": title_3, "text_1": value, "text_2": text_2 });
    }

    function HandleText_2(value){
        setText_2(value);
        props.ReturnData({ "bg_page": bgPage, "typeVideo": typeVideo, "video": video, "button": button, "title_1": title_1, "title_2": title_2, "title_3": title_3, "text_1": text_1, "text_2": value });
    }

    function OpenFile(type, file){
        SetModalData('ShowFile', { "type": type, "file": file });
        SetModalState('ShowFile', true);
    }

    return(
        <div className="ModelSite_Video">
            <div className="list_data_page">
                <div className="div_input">
                    <div className="div_show_data">
                        <input type="text" className="input_color" onChange={ (e)=>{ HandleBgPage(e.target.value) } } value={ bgPage } maxLength="140" />
                        <span className="span_input" maxLength="14">Cor de fundo</span>
                        <div className="div_color" style={ { background: bgPage } } />
                    </div>

                    <div className="div_show_data">  
                        <select className="data_select" onChange={ (e)=>{ HandleTypeVideo(e.target.value) } } value={ typeVideo }>
                            <option value="Vimeo">Vimeo</option>
                            <option value="Youtube">Youtube</option>
                        </select>
                        <span className="span_input">Tipo</span>
                    </div>

                    <div className="div_show_data">
                        <input type="text" className="input_code" onChange={ (e)=>{ HandleVideo(e.target.value) } } value={ video } maxLength="80" />
                        <span className="span_input" maxLength="14">Código do vídeo</span>
                    </div>
                    {
                        video !='' ?
                        <div className="div_show_data" onClick={ ()=>{ OpenFile(typeVideo, video) } }>
                            {
                                typeVideo == 'Vimeo' ?
                                <SvgVimeo color="#324d6b" className="icons"/> :
                                <SvgYoutube color="#324d6b" className="icons"/>
                            }
                        </div> : ""
                    }

                    <div className="div_show_data">
                        <input type="text" className="input_button" onChange={ (e)=>{ HandleButton(e.target.value) } } value={ button } maxLength="15" />
                        <span className="span_input" maxLength="14">Botão</span>
                    </div>
                </div>
                        
                <div className="div_input div_space space_top">
                    <div className="div_show_data div_space">
                        <input type="text" className="data_input" onChange={ (e)=>{ HandleTitle_1(e.target.value) } } value={ title_1 } maxLength="80" />
                        <span className="span_input" maxLength="14">Título 1</span>
                    </div>
                </div>
                
                <div className="div_input div_space space_top">
                    <div className="div_show_data div_space">
                        <input type="text" className="data_input" onChange={ (e)=>{ HandleTitle_2(e.target.value) } } value={ title_2 } maxLength="80" />
                        <span className="span_input" maxLength="14">Título 2</span>
                    </div>
                </div>

                <div className="div_input block_cinza"> 
                    <div className="block_title space_bottom">
                        <div className="">
                            Texto 1
                        </div>
                    </div>
                    <div className="div_input">
                        <JoditEditor className="show_textarea" config={ config } value={ text_1.replaceAll('&#34;', '"') } onBlur={ newContent => HandleText_1(newContent) } />
                    </div>
                </div>
                
                <div className="div_input div_space space_top">
                    <div className="div_show_data div_space">
                        <input type="text" className="data_input" onChange={ (e)=>{ HandleTitle_3(e.target.value) } } value={ title_3 } maxLength="80" />
                        <span className="span_input" maxLength="14">Título 3</span>
                    </div>
                </div>

                <div className="div_input block_cinza"> 
                    <div className="block_title space_bottom">
                        <div className="">
                            Texto 2
                        </div>
                    </div>
                    <div className="div_input">
                        <JoditEditor className="show_textarea" config={ config } value={ text_2.replaceAll('&#34;', '"') } onBlur={ newContent => HandleText_2(newContent) } />
                    </div>
                </div>

                <div className="div_input div_space space_top">
                    <div className="div_show_data div_space img">
                        <img alt="home_example" src="./assets/bg_video.jpg" className="img_example" />
                    </div>
                </div>
            </div>
        </div>
    );
};