import React, { useState, useEffect } from "react";

import './Doubts.css';

import JoditEditor from 'jodit-react';
import { config, typeCookiePageId } from "dataFixed";

import { SvgClose, SvgSetaDown, SvgSetaUp } from "components/SvgFile";

import { RegisterModalData, RegisterModalObserver, SetModalState } from "interface/PopUp";

import { Reg_NewData_Doubts } from "services/RegisterData";

export default function PopUp_Doubts(){

    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);

    const [ question, setQuestion ] = useState('');
    const [ response, setResponse ] = useState('');

    useEffect(()=>{
        RegisterModalData('Doubts', setModaldata);
        RegisterModalObserver('Doubts', setShowPopUp);
    }, []);

    function SaveData(event){
        event.preventDefault();
        Reg_NewData_Doubts(question, response, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        ClosePopUp();
    }

    function CallbackError(){
    }

    function ClosePopUp(){
        SetModalState('Doubts', false);
    }

    return (
        (showPopUp ?
            <>
                <div className="PopUp">
                    <div className="all">
                        <div className="div_data">
                            <div className="title">
                                Registrar nova dúvida
                            </div>
                            <div className="close" onClick={ ()=>{ ClosePopUp() } }>
                                <SvgClose color="#F00000" className="icons" />
                            </div>
                        </div>
                        <div className="div_data" style={ { paddingTop: 0 } }>
                            <div className="content">
                                <form onSubmit={ SaveData } className="form_doubts">                                    
                                    <div className="div_doubts">
                                        <div className="list_data_page">
                                            <div className="div_input">
                                                <div className="div_show_data div_space">
                                                    <input type="text" className="data_input" onChange={ (e)=>{ setQuestion(e.target.value) } } required />
                                                    <span className="span_input" maxLength="14">Título</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="list_data_page">
                                            <div className="div_input">
                                                <div className="div_show_data div_space">
                                                    <JoditEditor className="show_textarea" config={ config } onBlur={ newContent => setResponse(newContent) } />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <button className="new_block_text" style={ { width: "-webkit-fill-available" } }>Salvar</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        : <React.Fragment></React.Fragment>)
    );
}