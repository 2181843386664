import { useState, useEffect, useRef } from "react";

import './Classes.css';

import Select from 'react-select';

import JoditEditor from 'jodit-react';
import { config, typeCookiePageId } from "dataFixed";

import { Reg_EditOrRegisterClasses } from "services/RegisterData";

import { GetListPag, RegisterListPag, SetListPag } from "interface/Page";
import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage } from "interface/Data";
import { SvgDelete, SvgGif, SvgImg, SvgPdf, SvgReturn, SvgSave, SvgVimeo, SvgYoutube } from "components/SvgFile";

export default function Courses_Details_Classes(){

    const editText_1 = useRef();
    const editText_2 = useRef();
    const editText_3 = useRef();
    
    const [ loading, setLoading ]   = useState(false);
    const [ dataPage, setDataPage ] = useState(GetDataPage('courses'));
    const [ idCourse, setIdCourse ] = useState(GetListPag('currentPageId'));
    const [ idPage, setIdPage ]     = useState(GetListPag('currentPageIdSegund'));

    const [ listModule, setListModule ] = useState([]);

    const [ typeVideo, setTypeVideo ]     = useState(InitialData('type_video'));
    const [ video, setVideo ]             = useState(InitialData('video'));
    const [ free, setFree ]               = useState(InitialData('free'));
    const [ module, setModule ]           = useState(InitialData('module'));
    const [ newModule, setNewModule ]     = useState('');
    const [ title, setTitle ]             = useState(InitialData('title'));
    const [ download, setDownload ]       = useState(InitialData('downloads'));
    const [ tips, setTips ]               = useState(InitialData('tips'));
    const [ link, setLink ]               = useState(InitialData('link'));
    const [ observation, setObservation ] = useState(InitialData('observation'));

    useEffect(()=>{
        RegisterDataPage('courses', setDataPage);
        RegisterListPag('currentPageId', setIdCourse);
        RegisterListPag('currentPageIdSegund', setIdPage);
        ListModule();
    }, []);

    useEffect(()=>{
        setDataPage(GetDataPage('courses'));
        setIdCourse(GetListPag('currentPageId'));
        setIdPage(GetListPag('currentPageIdSegund'));
        ListModule();

        setTypeVideo(InitialData('type_video'));
        setVideo(InitialData('video'));
        setFree(InitialData('free'));
        setModule(InitialData('module'));
        setTitle(InitialData('title'));
        setDownload(InitialData('downloads'));
        setTips(InitialData('tips'));
        setLink(InitialData('link'));
        setObservation(InitialData('observation'));
    }, [dataPage]);

    useEffect(()=>{
        setDataPage(GetDataPage('courses'));
        setIdCourse(GetListPag('currentPageId'));
        setIdPage(GetListPag('currentPageIdSegund'));
        ListModule();

        setTypeVideo(InitialData('type_video'));
        setVideo(InitialData('video'));
        setFree(InitialData('free'));
        setModule(InitialData('module'));
        setTitle(InitialData('title'));
        setDownload(InitialData('downloads'));
        setTips(InitialData('tips'));
        setLink(InitialData('link'));
        setObservation(InitialData('observation'));
    }, [idPage]);
    
    function InitialData(type){
        if(idPage !=0){
            const showCourse = dataPage.find(item => item.id == idCourse);
            const newData    = showCourse.list_classes.find(item => item.id == idPage);
            return newData[type];
        }
        if(type == 'downloads'){
            return [];
        }
        
        if(type == 'free'){
            return false;
        }
        
        if(type == 'type_video'){
            return 'Vimeo';
        }
        return '';
    }

    function ListModule(){
        const newList = [];
        dataPage.map((key, index)=>{
            key.list_classes.forEach(item =>{
                newList.push(item.module);
            });
        })
        const duplicate = newList.filter((item, index) => newList.indexOf(item) === index);
        setListModule(duplicate);
    }

    function ReturnPage(){
        SetListPag('currentPage', 'courses_details');
        SetListPag('currentPageIdSegund', 'remuve');
    }

    function OpenFile(type, file){
        SetModalData('ShowFile', { "type": type, "file": file });
        SetModalState('ShowFile', true);
    }

    function AddFile(type, value, index){
        const newData = [...download];
        switch(type){
            case "file":
                newData[index].file        = value;
                newData[index].update_file = true;
                setDownload(newData);
                break;

            case "img":
                newData[index].img        = value;
                newData[index].update_img = true;
                setDownload(newData);
                break;
        }
    }

    function AddOptDownload(){
        setDownload([...download, { "id": 0, "img": "", "update_img": false, "file": "", "update_file": false, "title": "" }])
    }    

    function HandleDataDownload(index, value){
        const newData = [...download];
        newData[index].title = value;
        setDownload(newData);
    }

    function DeleteDownload(index, id, value){
        if(id == 0){
            const newData = [...download];
            newData.splice(index, 1);
            setDownload(newData);
        }else {
            SetModalData("Confirmation", { "origin": "classes", "type" : "delete_classes_download", "id" : id, "name": value });
            SetModalState('Confirmation', true);
        }
    }

    function SaveData(event){
        setLoading(true);
        event.preventDefault();
        Reg_EditOrRegisterClasses(idPage, idCourse, typeVideo, video, free, module, newModule, title, download, tips, link, observation, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    return(
        <div className="Details_Classes">
            <div className={ loading == true ? "return_data_save div_loading" : "return_data_save" }>
                <img alt="loading" src="./assets/loading.gif" className="loading" />
            </div>
            <form onSubmit={ SaveData } className="div_login">
                <div className="new_block">
                    <div className="div_return" onClick={ ()=>{ ReturnPage() } }>
                        <SvgReturn color="#111827" className="icons" />
                        voltar
                    </div>
                    <div className="title_page">Detalhes da aula</div>
                    <button className="new_block_text">
                        <SvgSave color="#ffffff" className="icons" />
                        Salvar
                    </button>
                </div>

                <div className="list_data_page">
                    <div className="div_input">

                        <div className="div_show_data">  
                            <select className="data_select" onChange={ (e)=>{ setTypeVideo(e.target.value) } } value={ typeVideo } required>
                                <option value="Vimeo">Vimeo</option>
                                <option value="Youtube">Youtube</option>
                            </select>
                            <span className="span_input">Tipo</span>
                        </div>
                        
                        <div className="div_show_data">
                            <input type="text" className="code" onChange={ (e)=>{ setVideo(e.target.value); } } value={ video.replaceAll(' ', '') } maxLength="18" required />
                            <span className="span_input" maxLength="14">Código do vídeo</span>
                        </div>
                        {
                            video !='' ?
                            <div className="div_show_data" onClick={ ()=>{ OpenFile(typeVideo, video) } }>
                                {
                                    typeVideo == 'Vimeo' ?
                                    <SvgVimeo color="#324d6b" className="icons icons_video"/> :
                                    <SvgYoutube color="#324d6b" className="icons icons_video"/>
                                }
                            </div> : ""
                        }

                        <div className={ free == true ? "free free_active" : "free" } onClick={ ()=>{ setFree(!free) } }>
                            Free:
                            { free == true ? " Ativo" : " Desativado" }
                        </div>
                    </div>
                </div>

                <div className="list_data_page space_top">
                    <div className="div_input">                        
                        <div className="div_show_data div_space">
                            <input type="text" className="data_input" onChange={ (e)=>{ setTitle(e.target.value) } } value={ title } maxLength="140" required />
                            <span className="span_input" maxLength="14">Título</span>
                        </div>
                        
                        <div className="div_show_data">  
                            <select className="data_select_module" onChange={ (e)=>{ setModule(e.target.value) } } value={ module } required>
                                <option value="">#</option>
                                <option value="new_module">Adicionar novo módulo</option>
                                {
                                    listModule.map((key_1, index_1)=>{
                                        if(key_1 !=''){
                                            return(
                                                <option value={ key_1 } key={ index_1 }>{ key_1 }</option>
                                            )
                                        }
                                    })
                                }
                            </select>
                            <span className="span_input">Módulo</span>
                        </div>
                        {
                            module == "new_module" ?                     
                            <div className="div_show_data">
                                <input type="text" className="data_input" onChange={ (e)=>{ setNewModule(e.target.value); } } maxLength="80" required />
                                <span className="span_input" maxLength="14">Nome do módulo</span>
                            </div>  
                            : null
                        }
                    </div>
                </div>

                <div className="list_data_page block_cinza"> 
                    <div className="block_title">
                        <div className="">
                            Downloads
                        </div>
                        <div className="button_new_data" onClick={ ()=>{ AddOptDownload(); } }>
                            Adicionar nova download
                        </div>
                    </div>
                    {
                        download.length > 0 ?
                            download.map((key, index)=>{
                                let iconsFile = '';
                                if(key.update_file == false){
                                    let ext = key.file.split('.');
                                    switch (ext[1]) {
                                        case "png": case "jpg": case "jpge":
                                                iconsFile = <SvgImg color="#324d6b" className="icons"/>;
                                            break;
                                        
                                        case "gif":
                                                iconsFile = <SvgGif color="#324d6b" className="icons"/>;
                                            break;
                                    
                                        case "pdf":
                                                iconsFile = <SvgPdf color="#324d6b" className="icons"/>;
                                            break;
                                        
                                        default:
                                                iconsFile = <div className="others">?</div>;
                                            break;
                                    }
                                }
                                
                                return(
                                    <div className="div_input div_download" key={ index }>                   
                                        <div className="div_show_data div_space opt_download">
                                            Título
                                            <input type="text" className="data_input" onChange={ (e)=>{ HandleDataDownload(index, e.target.value) } } value={ key.title } maxLength="140" required />
                                        </div>

                                        <div className="div_show_data opt_download">   
                                            <div className={ key.update_img == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                                <label>
                                                    { key.update_img == true ? "Foto adicionada" : "Adicionar foto" }
                                                    <input type="file" onChange={ (e)=>{ AddFile('img', e.target.files[0], index); } } accept="image/*"/>
                                                </label>
                                            </div>
                                            {
                                                key.img !='' ?
                                                    key.update_img == true ?
                                                    <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(key.img)) } }>
                                                        <SvgImg color="#324d6b" className="icons"/>
                                                    </div> :
                                                    <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', 'courses/img/' + key.img) } }>
                                                        <SvgImg color="#324d6b" className="icons"/>
                                                    </div>
                                                : ""
                                            }                      
                                        </div>

                                        <div className="div_show_data opt_download">   
                                            <div className={ key.update_file == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                                <label>
                                                    { key.update_file == true ? "Arquivo adicionado" : "Adicionar arquivo" }
                                                    <input type="file" onChange={ (e)=>{ AddFile('file', e.target.files[0], index); } }/>
                                                </label>
                                            </div>
                                            {
                                                key.file !='' ?
                                                    key.update_file == true ? null :
                                                    <div className="show_img_" onClick={ ()=>{ OpenFile('others', 'courses/others/' + key.file) } }>
                                                        {
                                                           iconsFile 
                                                        }
                                                    </div>
                                                : ""
                                            }                      
                                        </div>

                                        <div className="div_show_data opt_download" onClick={ ()=>{ DeleteDownload(index, key.id, key.title) } }>
                                            <SvgDelete color="#F00000" className="icons"/>
                                        </div>
                                    </div>
                                )
                            })
                        : <div className="no_data">Nenhum download adicionada até o momento...</div>
                    }
                </div>

                <div className="list_data_page block_cinza"> 
                    <div className="block_title">
                        <div className="">
                            Dicas
                        </div>
                    </div>
                    <div className="div_input">
                        <JoditEditor className="show_textarea" ref={ editText_1 } config={ config } value={ tips != '' ? tips.replaceAll('&#34;', '"') : "" } onBlur={ newContent => setTips(newContent) } />
                    </div>
                </div>

                <div className="list_data_page block_cinza"> 
                    <div className="block_title">
                        <div className="">
                            Link
                        </div>
                    </div>
                    <div className="div_input">
                        <JoditEditor className="show_textarea" ref={ editText_2 } config={ config } value={ link != '' ? link.replaceAll('&#34;', '"') : "" } onBlur={ newContent => setLink(newContent) } />
                    </div>
                </div>

                <div className="list_data_page block_cinza"> 
                    <div className="block_title">
                        <div className="">
                            Observação
                        </div>
                    </div>
                    <div className="div_input">
                        <JoditEditor className="show_textarea" ref={ editText_3 } config={ config } value={ observation != '' ? observation.replaceAll('&#34;', '"') : "" } onBlur={ newContent => setObservation(newContent) } />
                    </div>
                </div>

            </form>
        </div>
    )
}