import { useState, useEffect } from "react";

import './StudentWorks.css';

import JoditEditor from 'jodit-react';
import { config, typeCookiePageId } from "dataFixed";

import { SvgImg, SvgVimeo, SvgYoutube } from "components/SvgFile";

import { Reg_NewData_Pages } from "services/RegisterData";

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage } from "interface/Data";
import { GetListPag, RegisterListPag } from "interface/Page";

export default function ModelSite_StudentWorks(props){

    const [ idPage, setIdPage ]     = useState(GetListPag('currentPageId'));
    const [ dataPage, setDataPage ] = useState(GetDataPage(props.origin));

    const [ typeVideo, setTypeVideo ] = useState(InitialData('typeVideo'));
    const [ video, setVideo ]         = useState(InitialData('video'));
    const [ img, setImg ]             = useState(InitialData('bg_page'));
    const [ title, setTitle ]         = useState(InitialData('title_1'));
    const [ status, setStatus ]       = useState(InitialData('status'));

    useEffect(()=>{
        RegisterDataPage(props.origin, setDataPage);
        RegisterListPag('currentPageId', setIdPage);

        LoadingData();
    }, []);

    useEffect(()=>{
        setDataPage(GetDataPage(props.origin));
        setIdPage(GetListPag('currentPageId'));
        
        setTypeVideo(InitialData('typeVideo'));
        setVideo(InitialData('video'));
        setImg(InitialData('bg_page'));
        setTitle(InitialData('title_1'));
        setStatus(InitialData('status'));

        LoadingData();
    }, [dataPage]);

    useEffect(()=>{
        setDataPage(GetDataPage(props.origin));
        setIdPage(GetListPag('currentPageId'));
        
        setTypeVideo(InitialData('typeVideo'));
        setVideo(InitialData('video'));
        setImg(InitialData('bg_page'));
        setTitle(InitialData('title_1'));
        setStatus(InitialData('status'));

        LoadingData();
    }, [idPage]);

    function InitialData(type){
        if(idPage !=0){
            if(dataPage.length > 0){
                const newData = dataPage.find(item => item.id == idPage);
                return newData[type];
            }
        }
        if(type == 'typeVideo'){
            return 'Vimeo';
        }
        return '';
    }

    function LoadingData(){
        props.ReturnData({"typeVideo": typeVideo, "video": video, "img": img, "title": title});
    }

    function HandleTypeVideo(value){
        setTypeVideo(value);
        props.ReturnData({"typeVideo": value, "video": video, "img": img, "title": title});
    }

    function HandleVideo(value){
        setVideo(value);
        props.ReturnData({"typeVideo": typeVideo, "video": value, "img": img, "title": title});
    }

    function HandleImg(value){
        setImg(value);
        value ? setStatus(true) : setStatus(false);
        props.ReturnData({"typeVideo": typeVideo, "video": video, "img": value, "title": title});
    }

    function HandleTitle(value){
        setTitle(value);
        props.ReturnData({"typeVideo": typeVideo, "video": video, "img": img, "title": value});
    }

    function OpenFile(type, file){
        SetModalData('ShowFile', { "type": type, "file": file });
        SetModalState('ShowFile', true);
    }

    return(
        <div className="ModelSite_Video">
            <div className="list_data_page">
                <div className="div_input">
                    <div className="div_show_data">  
                        <select className="data_select" onChange={ (e)=>{ HandleTypeVideo(e.target.value) } } value={ typeVideo }>
                            <option value="Vimeo">Vimeo</option>
                            <option value="Youtube">Youtube</option>
                        </select>
                        <span className="span_input">Tipo</span>
                    </div>

                    <div className="div_show_data">
                        <input type="text" className="input_code" onChange={ (e)=>{ HandleVideo(e.target.value) } } value={ video } maxLength="80" />
                        <span className="span_input" maxLength="14">Código do vídeo</span>
                    </div>
                    {
                        video !='' ?
                        <div className="div_show_data" onClick={ ()=>{ OpenFile(typeVideo, video) } }>
                            {
                                typeVideo == 'Vimeo' ?
                                <SvgVimeo color="#324d6b" className="icons"/> :
                                <SvgYoutube color="#324d6b" className="icons"/>
                            }
                        </div> : ""
                    }
                    <div className="div_show_data">   
                        <div className={ status == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                            <label>
                                { status == true ? "Imagem de fundo adicionada" : "Adicionar imagem de fundo" }
                                <input type="file" onChange={ (e)=>{ HandleImg(e.target.files[0]); } } accept="image/*"/>
                            </label>
                        </div>
                        {
                            img !='' ?
                                status == true ?
                                <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(img)) } }>
                                    <SvgImg color="#324d6b" className="icons"/>
                                </div> :
                                <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "studentworks/" + img) } }>
                                    <SvgImg color="#324d6b" className="icons"/>
                                </div>
                            : ""
                        }                      
                    </div>
                </div>
                        
                <div className="div_input div_space space_top">
                    <div className="div_show_data div_space">
                        <input type="text" className="data_input" onChange={ (e)=>{ HandleTitle(e.target.value) } } value={ title } maxLength="80" />
                        <span className="span_input" maxLength="14">Título</span>
                    </div>
                </div>

                <div className="div_input div_space space_top">
                    <div className="div_show_data div_space img">
                        <img alt="home_example" src="./assets/bg_student.jpg" className="img_example" />
                    </div>
                </div>
            </div>
        </div>
    );

}