import React, { useState, useEffect } from "react";

import './DataProfile.css';

import JoditEditor from 'jodit-react';
import { config } from "dataFixed";

import { SvgClose, SvgImg } from "components/SvgFile";

import { RegisterModalData, RegisterModalObserver, SetModalData, SetModalState } from "interface/PopUp";

import { Reg_NewProfile } from "services/RegisterData";

export default function PopUp_DataProfile(){

    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);

    const [ status, setStatus ] = useState(false);
    const [ file, setFile ]     = useState('');
    const [ name, setName ]     = useState('');
    const [ area, setArea ]     = useState('');
    const [ date, setDate ]     = useState('');
    const [ text, setText ]     = useState('');


    useEffect(()=>{
        RegisterModalData('DataProfile', setModaldata);
        RegisterModalObserver('DataProfile', setShowPopUp);
    }, []);

    function SaveData(event){
        event.preventDefault();
        Reg_NewProfile(modalData.id, modalData.page, file, name, area, date, text, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }
    
    function CallbackSuccess(){
        ClosePopUp();
    }

    function CallbackError(){
    }

    function ClosePopUp(){
        setStatus(false);
        setFile('');
        setName('');
        setArea('');
        setDate('');
        setText('');
        SetModalState('DataProfile', false);
    }

    function OpenFile(type, file){
        SetModalData('ShowFile', { "type": type, "file": file });
        SetModalState('ShowFile', true);
    }

    return (
        (showPopUp ?
            <>
                <div className="PopUp">
                    <div className="all DataProfile">
                        <div className="div_data">
                            <div className="title">
                                Registrar novo perfil
                            </div>
                            <div className="close" onClick={ ()=>{ ClosePopUp() } }>
                                <SvgClose color="#F00000" className="icons" />
                            </div>
                        </div>
                        <div className="div_data" style={ { paddingTop: 0 } }>
                            <div className="content">
                                <form onSubmit={ SaveData } className="form_profile">                                    
                                    <div className="list_data_page show_profile_popup"> 
                                        <div className="div_input">                                       
                                            <div className="img_popup"> 
                                                <div className={ status == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                                    <label>
                                                        { status == true ? "Imagem adicionada" : "Adicionar imagem" }
                                                        <input type="file" onChange={ (e)=>{ setFile(e.target.files[0]); setStatus(true) } } accept="image/*"/>
                                                    </label>
                                                </div>
                                                {
                                                    file !='' ?
                                                        <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(file)) } }>
                                                            <SvgImg color="#324d6b" className="icons"/>
                                                        </div>
                                                    : ""
                                                }                      
                                            </div> 
                                        </div>                           
                                        <div className="div_input space_top position_popup">
                                            <div className="div_show_data">
                                                <input type="text" className="input_name" onChange={ (e)=>{ setName(e.target.value); } } maxLength="80" />
                                                <span className="span_input" maxLength="14">Nome</span>
                                            </div>
                                            <div className="div_show_data">
                                                <input type="text" className="data_input" onChange={ (e)=>{ setArea(e.target.value) } } maxLength="80" />
                                                <span className="span_input" maxLength="14">Área</span>
                                            </div> 
                                            <div className="div_show_data">
                                                <input type="text" className="input_date" onChange={ (e)=>{ setDate(e.target.value) } } maxLength="80" />
                                                <span className="span_input" maxLength="14">Data</span>
                                            </div> 
                                        </div>                           
                                        <div className="div_input">                                       
                                            <div className="div_show_data div_space"> 
                                                <JoditEditor className="show_textarea" config={ config } onBlur={ newContent => setText(newContent) } />
                                            </div>
                                        </div>
                                    </div>
                                    <button className="new_block_text" style={ { width: "-webkit-fill-available" } }>Salvar</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        : <React.Fragment></React.Fragment>)
    );
}