import { useState, useEffect } from "react";

import './PersonalizedService.css';

import JoditEditor from 'jodit-react';
import { config } from "dataFixed";

import { SvgDelete, SvgImg, SvgPdf, SvgVimeo, SvgYoutube } from "components/SvgFile";

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage } from "interface/Data";
import { GetListPag, RegisterListPag } from "interface/Page";

export default function ModelSite_PersonalizedService(props){

    const [ idPage, setIdPage ]     = useState(GetListPag('currentPageId'));
    const [ dataPage, setDataPage ] = useState(GetDataPage(props.origin));

    const [ file, setFile ]       = useState(InitialData('bg_page'));
    const [ status, setStatus ]   = useState(InitialData('status'));
    const [ title_1, setTitle_1 ] = useState(InitialData('title_1'));
    const [ title_2, setTitle_2 ] = useState(InitialData('title_2'));
    const [ title_3, setTitle_3 ] = useState(InitialData('title_3'));
    const [ title_4, setTitle_4 ] = useState(InitialData('title_4'));
    const [ text_1, setText_1 ]   = useState(InitialData('text_1'));
    const [ text_2, setText_2 ]   = useState(InitialData('text_2'));
    const [ text_3, setText_3 ]   = useState(InitialData('text_3'));

    useEffect(()=>{
        RegisterDataPage(props.origin, setDataPage);
        RegisterListPag('currentPageId', setIdPage);
        
        LoadingData();
    }, []);

    useEffect(()=>{
        setDataPage(GetDataPage(props.origin));
        setIdPage(GetListPag('currentPageId'));
        
        setFile(InitialData('bg_page'));
        setStatus(InitialData('status'));
        setTitle_1(InitialData('title_1'));
        setTitle_2(InitialData('title_2'));
        setTitle_3(InitialData('title_3'));
        setTitle_4(InitialData('title_4'));
        setText_1(InitialData('text_1'));
        setText_2(InitialData('text_2'));
        setText_3(InitialData('text_3'));

        LoadingData();
    }, [dataPage]);

    useEffect(()=>{
        setDataPage(GetDataPage(props.origin));
        setIdPage(GetListPag('currentPageId'));
        
        setFile(InitialData('bg_page'));
        setStatus(InitialData('status'));
        setTitle_1(InitialData('title_1'));
        setTitle_2(InitialData('title_2'));
        setTitle_3(InitialData('title_3'));
        setTitle_4(InitialData('title_4'));
        setText_1(InitialData('text_1'));
        setText_2(InitialData('text_2'));
        setText_3(InitialData('text_3'));

        LoadingData();
    }, [idPage]);

    function InitialData(type){
        if(idPage !=0){
            if(dataPage.length > 0){
                const newData = dataPage.find(item => item.id == idPage);
                return newData[type];
            }
        }
        return '';
    }

    function LoadingData(){
        props.ReturnData({"file": file, "title_1": title_1, "title_2": title_2, "title_3": title_3, "title_4": title_4, "text_1": text_1, "text_2": text_2, "text_3": text_3});
    }

    function HandleFile(value){
        setFile(value);
        value ? setStatus(true) : setStatus(false);
        props.ReturnData({"file": value, "title_1": title_1, "title_2": title_2, "title_3": title_3, "title_4": title_4, "text_1": text_1, "text_2": text_2, "text_3": text_3});
    }

    function HandleTitle_1(value){
        setTitle_1(value);
        props.ReturnData({"file": file, "title_1": value, "title_2": title_2, "title_3": title_3, "title_4": title_4, "text_1": text_1, "text_2": text_2, "text_3": text_3});
    }

    function HandleTitle_2(value){
        setTitle_2(value);
        props.ReturnData({"file": file, "title_1": title_1, "title_2": value, "title_3": title_3, "title_4": title_4, "text_1": text_1, "text_2": text_2, "text_3": text_3});
    }

    function HandleTitle_3(value){
        setTitle_3(value);
        props.ReturnData({"file": file, "title_1": title_1, "title_2": title_2, "title_3": value, "title_4": title_4, "text_1": text_1, "text_2": text_2, "text_3": text_3});
    }

    function HandleTitle_4(value){
        setTitle_4(value);
        props.ReturnData({"file": file, "title_1": title_1, "title_2": title_2, "title_3": title_3, "title_4": value, "text_1": text_1, "text_2": text_2, "text_3": text_3});
    }

    function HandleText_1(value){
        setText_1(value);
        props.ReturnData({"file": file, "title_1": title_1, "title_2": title_2, "title_3": title_3, "title_4": title_4, "text_1": value, "text_2": text_2, "text_3": text_3});
    }

    function HandleText_2(value){
        setText_2(value);
        props.ReturnData({"file": file, "title_1": title_1, "title_2": title_2, "title_3": title_3, "title_4": title_4, "text_1": text_1, "text_2": value, "text_3": text_3});
    }

    function HandleText_3(value){
        setText_3(value);
        props.ReturnData({"file": file, "title_1": title_1, "title_2": title_2, "title_3": title_3, "title_4": title_4, "text_1": text_1, "text_2": text_2, "text_3": value});
    }

    function OpenFile(type, file){
        SetModalData('ShowFile', { "type": type, "file": file });
        SetModalState('ShowFile', true);
    }

    return(
        <div className="ModelSite_PersonalizedService">
            <div className="list_data_page">
                <div className="div_input">
                    <div className="div_show_data">   
                        <div className={ status == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                            <label>
                                { status == true ? "Imagem de fundo adicionada" : "Adicionar imagem de fundo" }
                                <input type="file" onChange={ (e)=>{ HandleFile(e.target.files[0]); } } accept="image/*"/>
                            </label>
                        </div>
                        {
                            file !='' ?
                                status == true ?
                                <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(file)) } }>
                                    <SvgImg color="#324d6b" className="icons"/>
                                </div> :
                                <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "personalized_service/" + file) } }>
                                    <SvgImg color="#324d6b" className="icons"/>
                                </div>
                            : ""
                        }                      
                    </div>
                </div>
                        
                <div className="div_input div_space space_top">
                    <div className="div_show_data div_space">
                        <input type="text" className="data_input" onChange={ (e)=>{ HandleTitle_1(e.target.value) } } value={ title_1 } maxLength="80" />
                        <span className="span_input" maxLength="14">Título 1</span>
                    </div>
                </div>
                        
                <div className="div_input div_space space_top">
                    <div className="div_show_data div_space">
                        <input type="text" className="data_input" onChange={ (e)=>{ HandleTitle_2(e.target.value) } } value={ title_2 } maxLength="80" />
                        <span className="span_input" maxLength="14">Título 2</span>
                    </div>
                </div>

                <div className="div_input block_cinza"> 
                    <div className="block_title space_bottom">
                        <div className="">
                            Texto 1
                        </div>
                    </div>
                    <div className="div_input">
                        <JoditEditor className="show_textarea" config={ config } value={ text_1.replaceAll('&#34;', '"') } onBlur={ newContent => HandleText_1(newContent) } />
                    </div>
                </div>
                        
                <div className="div_input div_space space_top">
                    <div className="div_show_data div_space">
                        <input type="text" className="data_input" onChange={ (e)=>{ HandleTitle_3(e.target.value) } } value={ title_3 } maxLength="80" />
                        <span className="span_input" maxLength="14">Título 3</span>
                    </div>
                </div>

                <div className="div_input block_cinza"> 
                    <div className="block_title space_bottom">
                        <div className="">
                            Texto 2
                        </div>
                    </div>
                    <div className="div_input">
                        <JoditEditor className="show_textarea" config={ config } value={ text_2.replaceAll('&#34;', '"') } onBlur={ newContent => HandleText_2(newContent) } />
                    </div>
                </div>
                        
                <div className="div_input div_space space_top">
                    <div className="div_show_data div_space">
                        <input type="text" className="data_input" onChange={ (e)=>{ HandleTitle_4(e.target.value) } } value={ title_4 } maxLength="80" />
                        <span className="span_input" maxLength="14">Título 4</span>
                    </div>
                </div>

                <div className="div_input block_cinza"> 
                    <div className="block_title space_bottom">
                        <div className="">
                            Texto 3
                        </div>
                    </div>
                    <div className="div_input">
                        <JoditEditor className="show_textarea" config={ config } value={ text_3.replaceAll('&#34;', '"') } onBlur={ newContent => HandleText_3(newContent) } />
                    </div>
                </div>

                <div className="div_input div_space space_top">
                    <div className="div_show_data div_space img">
                        <img alt="home_example" src="./assets/bg_personalize.jpg" className="img_example" />
                    </div>
                </div>
            </div>
        </div>
    );
}