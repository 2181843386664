import { useState, useEffect } from "react";

import './Contents.css';

import Cookies from 'universal-cookie';

import { SvgMenuAccess, SvgMenuCourse, SvgMenuExit, SvgMenuFinance, SvgMenuGraphic, SvgMenuSite, SvgMenuStudents, SvgMenuTeam, SvgMenuUpdateJson, SvgSetaRight, SvgSite, SvgUser } from "components/SvgFile";

import { SetModalState } from "interface/PopUp";
import { GetUserData, RegisterUserData } from "interface/Users";
import { GetListPag, RegisterListPag, SetListPag } from "interface/Page";

import { cookiesRegister, typeCookie, typeCookieEmail, typeCookiePage, typeCookiePassw } from "dataFixed";

import Courses from "components/Page/Courses";
import AccessDash from "components/Page/AccessDash";
import PopUp_ShowFile from "components/PopUp/ShowFile";
import PopUP_Confirmation from "components/PopUp/Confirmation";
import AccessDash_Details from "components/Page/AccessDash/Details";
import PopUP_ReturnResponse from "components/PopUp/ReturnResponse";
import Courses_Details from "components/Page/Courses/Details";
import Courses_Details_Classes from "components/Page/Courses/Details/Classes";
import Students from "components/Page/Students";
import Students_Details from "components/Page/Students/Details";
import Indicators from "components/Page/Indicators";
import Finacial_Entry from "components/Page/Financial/Entry";
import FinancialEntry_details from "components/Page/Financial/Entry/Details";
import Finacial_Exit from "components/Page/Financial/Exit";
import FinancialExit_details from "components/Page/Financial/Exit/Details";
import Finacial_Balance from "components/Page/Financial/Balance";
import PopUP_BalanceDetails from "components/PopUp/Balance";
import Team from "components/Page/Team";
import Team_Details from "components/Page/Team/Details";
import PopUp_PageMenu from "components/PopUp/PageMenu";
import Site_Home from "components/Page/Site/Home";
import Site_PaymentTypes from "components/Page/Site/PaymentTypes";
import Site_HowWorks from "components/Page/Site/HowWorks";
import Site_Home_details from "components/Page/Site/Home/Details";
import Site_Mentoring from "components/Page/Site/Mentoring";
import Site_Mentoring_details from "components/Page/Site/Mentoring/Details";
import Site_Rolo from "components/Page/Site/Rolo";
import Site_Rolo_details from "components/Page/Site/Rolo/Details";

export default function Contents(){

    const cookies = new Cookies();
    const [ loading, setLoading ] = useState(false);

    const [ currentPage, setCurrentPage ] = useState(GetListPag('currentPage'));

    const [ userId, setUserId ]         = useState(GetUserData('id'));
    const [ userFile, setUserFile ]     = useState(GetUserData('file'));
    const [ userAccess, setUserAccess ] = useState(GetUserData('access'));
    const [ userPage, setUserPage ]     = useState(GetUserData('page'));

    const [ menuSite, setMenuSite ]        = useState(false);
    const [ listMenuSite, setListSubMenu ] = useState([
        "siteHome", "siteHome_details", 
        "siteHowWorks", 
        "siteMentoring", "siteMentoring_details", 
        "siteRolo", "siteRolo_details", 
        "sitePayment", 
        "siteNameMenu"
    ]);

    const [ menuFinancial, setMenuFinancial ]        = useState(false);
    const [ listMenuFinancial, setListSubFinancial ] = useState([
        "financialEntry", "financialEntry_details", 
        "financialExit", "financialExit_details", 
        "financialBalance"
    ]);

    useEffect(()=>{
        RegisterListPag('currentPage', setCurrentPage);

        RegisterUserData('id', setUserId);
        RegisterUserData('file', setUserFile);
        RegisterUserData('access', setUserAccess);
        RegisterUserData('page', setUserPage);
    }, []);

    function CkickPage(value){
        setTimeout(() => {
            SetListPag('currentPage', value);
        }, 100);
    }

    function CurrentPage(){
        switch(currentPage) {
            case "accessDash":
                return <AccessDash />;
            case "accessDash_details":
                return <AccessDash_Details />;

            case "courses":
                return <Courses />;
            case "courses_details":
                return <Courses_Details />;
            case "courses_details_classes":
                return <Courses_Details_Classes />;

            case "students":
                return <Students />;
            case "students_details":
                return <Students_Details />;

            case "financialEntry":
                return <Finacial_Entry />;
            case "financialEntry_details":
                return <FinancialEntry_details />;

            case "financialExit":
                return <Finacial_Exit />;
            case "financialExit_details":
                return <FinancialExit_details />;

            case "financialBalance":
                return <Finacial_Balance />;

            case "team":
                return <Team />;
            case "team_details":
                return <Team_Details />;

            case "siteHome":
                return <Site_Home />;
            case "siteHome_details":
                return <Site_Home_details />;

            case "siteHowWorks":
                return <Site_HowWorks />;

            case "siteMentoring":
                return <Site_Mentoring />;
            case "siteMentoring_details":
                return <Site_Mentoring_details />;

            case "siteRolo":
                return <Site_Rolo />;
            case "siteRolo_details":
                return <Site_Rolo_details />;
                
            case "sitePayment":
                return <Site_PaymentTypes />;

            case "indicators":
                return <Indicators />;

            default:
                return(
                    <div className="no_data">
                        Selecione uma opção do menu na lateral para continuar...
                    </div>
                )
        }
    }

    function EditProfile(){
        SetListPag('currentPage', 'accessDash_details')
        SetListPag('currentPageId', userId);
    }

    function OpenDetailsNameMenu(){
        SetModalState('NameMenu', true);
    }

    function Logout(){
        SetListPag('page', 'index');
        cookies.set(typeCookie, 'index', '/', cookiesRegister);

        cookies.remove(typeCookieEmail, '', '/', cookiesRegister);
        cookies.remove(typeCookiePassw, '', '/', cookiesRegister);
        cookies.remove(typeCookiePage, '', '/', cookiesRegister);
    }

    return(
        <div className="Contents">
            <a href="" target="_blank">
                <div className="show_site">
                    <SvgSite color="#ffffff" className="icons_site" />
                </div>
            </a>
            <div className="list_menu">
                <div className="profile">
                    <div className="img_profile">
                        {
                            userFile !='' ?
                            <img alt="profile user" src={ "./assets/login/" + userFile } className="show_img_profile" /> :
                            <SvgUser color="#FFFFFF" className="show_img_profile" />
                        }
                    </div>
                    <div className="button_edit" onClick={ ()=>{ EditProfile() } }>Editar meu perfil</div>
                </div>
                <div className="opt_menu_dash">
                    {
                        userAccess == 2 ?
                        <>
                            <div className={ currentPage == "accessDash" || currentPage == "accessDash_details" ? "show_menu_dash active_dash" : "show_menu_dash" } onClick={ ()=>{ CkickPage("accessDash"); setMenuFinancial(false); setMenuSite(false); } }>
                                <div className="name_menu">
                                    <div className="div_icons">
                                        <SvgMenuAccess color="#FFFFFF" className="icons" />
                                    </div>
                                    <div>Acessos a dashboard</div>
                                </div>
                            </div>

                            <div className={ currentPage == "courses" || currentPage == "courses_details" || currentPage == "courses_details_classes" ? "show_menu_dash active_dash" : "show_menu_dash" } onClick={ ()=>{ CkickPage("courses"); setMenuFinancial(false); setMenuSite(false); } }>
                                <div className="name_menu">
                                    <div className="div_icons">
                                        <SvgMenuCourse color="#FFFFFF" className="icons" />
                                    </div>
                                    <div>Cursos</div>
                                </div>
                            </div>

                            <div className={ currentPage == "students" || currentPage == "students_details" ? "show_menu_dash active_dash" : "show_menu_dash" } onClick={ ()=>{ CkickPage("students"); setMenuFinancial(false); setMenuSite(false); } }>
                                <div className="name_menu">
                                    <div className="div_icons">
                                        <SvgMenuStudents color="#FFFFFF" className="icons" />
                                    </div>
                                    <div>Alunos</div>
                                </div>
                            </div>

                            <div className={ currentPage == "team" || currentPage == "team_details" ? "show_menu_dash active_dash" : "show_menu_dash" } onClick={ ()=>{ CkickPage("team"); setMenuFinancial(false); setMenuSite(false); } }>
                                <div className="name_menu">
                                    <div className="div_icons">
                                        <SvgMenuTeam color="#FFFFFF" className="icons" />
                                    </div>
                                    <div>Turmas</div>
                                </div>
                            </div>

                            <div className="show_menu_dash">
                                <div className="name_menu" onClick={ ()=>{ setMenuSite(!menuSite);setMenuFinancial(false); } }>
                                    <div className="div_icons">
                                        <SvgMenuSite color="#FFFFFF" className="icons" />
                                    </div>
                                    <div>Site</div>
                                    <div className="icons_submenu">
                                        {
                                            menuSite == true || listMenuSite.find(item => item == currentPage) ?
                                            <SvgSetaRight color="#ffffff" className="icons icons_active" /> :
                                            <SvgSetaRight color="#ffffff" className="icons" />
                                        }
                                    </div>
                                </div>
                                
                                <div className={ menuSite == true || listMenuSite.find(item => item == currentPage) ? "name_submenu submenu_active" : "name_submenu" }>
                                    <div className={ currentPage == "siteHome" || currentPage == "siteHome_details" ? "sub_menu show_menu_dash space_submenu active_dash" : "sub_menu show_menu_dash space_submenu" } onClick={ ()=>{ CkickPage("siteHome") } }>
                                        <div>Home</div>
                                    </div>
                                    <div className={ currentPage == "siteHowWorks" ? "sub_menu show_menu_dash space_submenu active_dash" : "sub_menu show_menu_dash space_submenu" } onClick={ ()=>{ CkickPage("siteHowWorks") } }>
                                        <div>Como funciona</div>
                                    </div>
                                    <div className={ currentPage == "siteMentoring" || currentPage == "siteMentoring_details" ? "sub_menu show_menu_dash space_submenu active_dash" : "sub_menu show_menu_dash space_submenu" } onClick={ ()=>{ CkickPage("siteMentoring") } }>
                                        <div>Mentoria</div>
                                    </div>
                                    <div className={ currentPage == "siteRolo" || currentPage == "siteRolo_details" ? "sub_menu show_menu_dash space_submenu active_dash" : "sub_menu show_menu_dash space_submenu" } onClick={ ()=>{ CkickPage("siteRolo") } }>
                                        <div>Rolo</div>
                                    </div>
                                    <div className={ currentPage == "sitePayment" ? "sub_menu show_menu_dash space_submenu active_dash" : "sub_menu show_menu_dash space_submenu" } onClick={ ()=>{ CkickPage("sitePayment") } }>
                                        <div>Tipo de pagamento</div>
                                    </div>
                                    <div className="sub_menu show_menu_dash space_submenu" onClick={ ()=>{ OpenDetailsNameMenu() } }>
                                        <div>Páginas do menu</div>
                                    </div>
                                </div>
                            </div>

                            <div className="show_menu_dash">
                                <div className="name_menu" onClick={ ()=>{ setMenuSite(false); setMenuFinancial(!menuFinancial); } }>
                                    <div className="div_icons">
                                        <SvgMenuFinance color="#FFFFFF" className="icons" />
                                    </div>
                                    <div>Financeiro</div>
                                    <div className="icons_submenu">
                                        {
                                            menuFinancial == true || listMenuFinancial.find(item => item == currentPage) ?
                                            <SvgSetaRight color="#ffffff" className="icons icons_active" /> :
                                            <SvgSetaRight color="#ffffff" className="icons" />
                                        }
                                    </div>
                                </div>
                                
                                <div className={ menuFinancial == true || listMenuFinancial.find(item => item == currentPage) ? "name_submenu submenu_active" : "name_submenu" }>
                                    <div className={ currentPage == "financialEntry" || currentPage == "financialEntry_details" ? "sub_menu show_menu_dash space_submenu active_dash" : "sub_menu show_menu_dash space_submenu" } onClick={ ()=>{ CkickPage("financialEntry") } }>
                                        <div>Entrada</div>
                                    </div>
                                    <div className={ currentPage == "financialExit" || currentPage == "financialExit_details" ? "sub_menu show_menu_dash space_submenu active_dash" : "sub_menu show_menu_dash space_submenu" } onClick={ ()=>{ CkickPage("financialExit") } }>
                                        <div>Saída</div>
                                    </div>
                                    <div className={ currentPage == "financialBalance" ? "sub_menu show_menu_dash space_submenu active_dash" : "sub_menu show_menu_dash space_submenu" } onClick={ ()=>{ CkickPage("financialBalance") } }>
                                        <div>Balanço</div>
                                    </div>
                                </div>
                            </div>

                            <div className={ currentPage == "indicators" ? "show_menu_dash active_dash" : "show_menu_dash" } onClick={ ()=>{ CkickPage("indicators"); setMenuFinancial(false); setMenuSite(false); } }>
                                <div className="name_menu">
                                    <div className="div_icons">
                                        <SvgMenuGraphic color="#FFFFFF" className="icons" />
                                    </div>
                                    <div>Indicadores</div>
                                </div>
                            </div>

                            <div className={ currentPage == "update_json" ? "show_menu_dash active_dash" : "show_menu_dash" } onClick={ ()=>{ CkickPage("update_json"); setMenuFinancial(false); setMenuSite(false); } }>
                                <div className="name_menu">
                                    <div className="div_icons">
                                        <SvgMenuUpdateJson color="#FFFFFF" className="icons" />
                                    </div>
                                    <div>Atualizar site</div>
                                </div>
                            </div>
                        </>
                        :
                        <>
                            {/* {
                                userPage.map((key, index)=>{
                                    let iconMenu   = '';
                                    switch (key.page) {
                                        case 'course':
                                                iconMenu = <SvgMenuCourse color="#FFFFFF" className="icons" />;
                                            break;
                                    }
                                    let activeMenu = '';

                                    if(key.page == 'financial' && currentPage == 'financial_exit'){
                                        activeMenu = 'show_menu_dash active_dash';
                                    }else if(key.page == 'financial' && currentPage == 'financial_exit'){
                                        activeMenu = 'show_menu_dash active_dash';
                                    }else {
                                        activeMenu = 'show_menu_dash';
                                    }

                                    return(
                                        <div className={ activeMenu } onClick={ ()=>{ CkickPage(key.page); } } key={ index }>
                                            <div className="name_menu">
                                                <div className="div_icons">{ iconMenu }</div>
                                                <div>{ key.name }</div>
                                            </div>
                                        </div>
                                    )
                                })  
                            } */}
                        </>                              
                    }

                    <div className="show_menu_dash" onClick={ ()=>{ Logout() } }>
                        <div className="name_menu">
                            <div className="div_icons">
                                <SvgMenuExit color="#FFFFFF" className="icons" />
                            </div>
                            <div>Sair</div>
                        </div>
                    </div>
        
                </div>
            </div>
            <div className="list_data">
                <div className="show_data">
                    { CurrentPage() }
                </div>
            </div>
            <PopUP_BalanceDetails />
            <PopUP_Confirmation />
            <PopUP_ReturnResponse />
            <PopUp_ShowFile />
            <PopUp_PageMenu />
        </div>
    )
}