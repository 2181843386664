import { useState, useEffect } from "react";

import './Indicators.css';

import { GetDataPage, RegisterDataPage } from "interface/Data";

export default function Indicators(){
    const [ students, setStudents ] = useState(GetDataPage('students'));
    const [ courses, setCourses ]   = useState(GetDataPage('courses'));
    const [ classes, setClasses ]   = useState(CountData());
    const [ team, setTeam ]         = useState(GetDataPage('team'));

    useEffect(()=>{
        RegisterDataPage('students', setStudents);
        RegisterDataPage('courses', setCourses);
        RegisterDataPage('team', setTeam);
    }, []);

    function CountData(){
        const qtd = [];
        courses.map((key, index)=>{
            key.list_classes.map((key, index)=>{
                qtd.push(index)
            });
        });
        return qtd.length;
    }

    return(
        <div className="Indicators">
            <div className="show_data">
                <div className="div_data">
                    <div className="title">Alunos</div>
                    <div className="div_qtd">
                        <div className="tit_qtd">{ students.length }</div>
                    </div>
                </div>

                <div className="div_data">
                    <div className="title">Cursos</div>
                    <div className="div_qtd">
                        <div className="tit_qtd">{ courses.length }</div>
                        <div className="sub_qtd">Aulas: { classes }</div>
                    </div>
                </div>
                
                <div className="div_data">
                    <div className="title">Turmas</div>
                    <div className="div_qtd">
                        <div className="tit_qtd">{ team.length }</div>
                    </div>
                </div>
            </div>
        </div>
    )
}