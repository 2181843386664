import React, { useState, useEffect } from "react";

import './DetailsStudy.css';

import { SvgClose } from "components/SvgFile";

import { RegisterModalData, RegisterModalObserver, SetModalState } from "interface/PopUp";

import { GetDataPage, RegisterDataPage } from "interface/Data";

export default function PopUp_DetailsStudy(){

    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);
    const [ dataPage, setDataPage ]   = useState(GetDataPage('students'));

    const [ name, setName ]     = useState('');
    const [ access, setAccess ] = useState('');
    const [ login, setLogin ]   = useState('');
    const [ email, setEmail ]   = useState('');
    const [ phone, setPhone ]   = useState('');
    const [ note, setNote ]     = useState('');
    const [ file, setFile ]     = useState('');

    useEffect(()=>{
        RegisterModalData('StatusClasses', setModaldata);
        RegisterModalObserver('StatusClasses', setShowPopUp);
        
        RegisterDataPage('students', setDataPage);
    }, []);

    useEffect(()=>{
        if(showPopUp == true){
            setDataPage(GetDataPage('students'));

            setName(modalData.name);
            setAccess(InitialData('nivel_page'));
            setLogin(InitialData('login'));
            setEmail(InitialData('email'));
            setPhone(InitialData('phone'));
            setNote(InitialData('note'));
            setFile(InitialData('file'));
        }
    }, [showPopUp]);

    function InitialData(type){
        const newData = dataPage.find(item => item.id == modalData.id);
        return newData[type];
    }

    function ClosePopUp(){
        SetModalState('StatusClasses', false);
    }

    return (
        (showPopUp ?
            <>
                <div className="PopUp">
                    <div className="all StatusClasses">
                        <div className="div_data">
                            <div className="title">
                                Detalhes do aluno
                            </div>
                            <div className="close" onClick={ ()=>{ ClosePopUp() } }>
                                <SvgClose color="#F00000" className="icons" />
                            </div>
                        </div>
                        <div className="div_data" style={ { paddingTop: 0 } }>
                            <div className="content">
                                <table width="100%" cellPadding="2" cellSpacing="6">
                                    <tbody>
                                        <tr>
                                            <td align="right"><b>Nome</b></td>
                                            <td align="left">{ name }</td>
                                        </tr>
                                        <tr>
                                            <td align="right"><b>Acesso</b></td>
                                            <td align="left">{ access }</td>
                                        </tr>
                                        <tr>
                                            <td align="right"><b>Login</b></td>
                                            <td align="left">{ login }</td>
                                        </tr>
                                        <tr>
                                            <td align="right"><b>E-mail</b></td>
                                            <td align="left">{ email }</td>
                                        </tr>
                                        <tr>
                                            <td align="right"><b>Telefone</b></td>
                                            <td align="left">{ phone ? phone : "Não definido..." }</td>
                                        </tr>
                                        <tr>
                                            <td align="right"><b>Observação</b></td>
                                            <td align="left">{ note ? note : "Não definido..." }</td>
                                        </tr>
                                        <tr>
                                            <td align="right"><b>foto</b></td>
                                            <td align="left">
                                                { 
                                                    file != "" ? <img alt="img" src={ "./assets/login/" + file } className="show_img" /> : "Não definido..." 
                                                }
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        : <React.Fragment></React.Fragment>)
    );
}
