import { useState, useEffect } from "react";

import './Entry.css';

import { SetListPag } from "interface/Page";
import { GetDataPage, RegisterDataPage } from "interface/Data";
import { SetModalData, SetModalState } from "interface/PopUp";

import { SvgAddNewData, SvgDelete, SvgEdit, SvgImg, SvgPdf, SvgSearch, SvgTriagle } from "components/SvgFile";

import { monthList } from "dataFixed";

export default function Finacial_Entry(){

    const date = new Date();

    const [ search, setSearch ]               = useState('');

    const [ searchYear, setSearchYear ] = useState(date.getFullYear());
    const [ statusYear, setStatusYear ] = useState(false);
    const [ listYear, setListYear ]     = useState(ListYear());

    const [ searchMonth, setSearchMonth ] = useState('All');
    const [ statusMonth, setStatusMonth ] = useState(false);
    const [ listMonth, setListMonth ]     = useState(ListMonth());

    const [ showPageData, setShowPageData ] = useState(InitialData());

    const [ itensPage, setItensPage ] = useState(40);
    const [ showPage, setShowPage ]   = useState(0);

    const page         = Math.ceil(showPageData.length / itensPage);
    const startItens   = showPage * itensPage;
    const endItens     = startItens + itensPage;
    const currentItens = showPageData.slice(startItens, endItens);

    useEffect(()=>{
        RegisterDataPage('financialEntry', setShowPageData);
    }, []);

    useEffect(()=>{
        setListMonth(ListMonth());
    }, [searchYear]);

    function InitialData(){
        const newData = [];
        GetDataPage('financialEntry').map((key, index)=>{
            if(key.year == searchYear){
                if(searchMonth == 'All'){
                    newData.push(key);
                }else {
                    let nameMonth = monthList.find(item =>item.name == searchMonth); 
                    if(key.month == nameMonth.month){
                        newData.push(key);
                    }
                }
            }
        })
        const duplicate = newData.filter((item, index) => newData.indexOf(item) === index);
        return duplicate;
    }

    function ListYear(){
        const newData = [];
        GetDataPage('financialEntry').map((key, index)=>{
            newData.push(key.year);
        })
        const duplicate = newData.filter((item, index) => newData.indexOf(item) === index);
        return duplicate;
    }

    function ListMonth(){
        const newData = [];
        if(searchYear == 'All'){
            monthList.map((key, index)=>{
                newData.push(key.month);
            });
        }else {
            GetDataPage('financialEntry').map((key, index)=>{
                if(key.year == searchYear){             
                    newData.push(key.month);
                }
            });
        }
        const duplicate = newData.filter((item, index) => newData.indexOf(item) === index);
        return duplicate;
    }

    function SearchInput(value){
        const newList  = [];
        if(value){
            GetDataPage('financialEntry').map((key, index)=>{
                if(searchYear == 'All'){
                    if(searchMonth == 'All'){
                        if(key.title.toLowerCase().indexOf(value.toLowerCase()) != -1){
                            newList.push(key);
                        }
                        if(key.type.toLowerCase().indexOf(value.toLowerCase()) != -1){
                            newList.push(key);
                        }
                    }else {
                        if(key.month == searchMonth){
                            if(key.title.toLowerCase().indexOf(value.toLowerCase()) != -1){
                                newList.push(key);
                            }
                            if(key.type.toLowerCase().indexOf(value.toLowerCase()) != -1){
                                newList.push(key);
                            }
                        }
                    }
                }else {
                    if(key.year == searchYear){
                        if(searchMonth == 'All'){
                            if(key.title.toLowerCase().indexOf(value.toLowerCase()) != -1){
                                newList.push(key);
                            }
                            if(key.type.toLowerCase().indexOf(value.toLowerCase()) != -1){
                                newList.push(key);
                            }
                        }else {
                            let nameMonth = monthList.find(item =>item.name == searchMonth); 
                            if(key.month == nameMonth.month){
                                if(key.title.toLowerCase().indexOf(value.toLowerCase()) != -1){
                                    newList.push(key);
                                }
                                if(key.type.toLowerCase().indexOf(value.toLowerCase()) != -1){
                                    newList.push(key);
                                }
                            }
                        }
                    }
                }
            })
            const duplicate = newList.filter((item, index) => newList.indexOf(item) === index);
            setShowPageData(duplicate);
        }else {
            setShowPageData(InitialData());
        }
        setSearch(value);
        setStatusYear(false);
        setStatusMonth(false);
    }

    function SearchSelectYear(value){
        const newList = [];
        if(value){
            GetDataPage('financialEntry').map((key, index)=>{
                if(value == 'All'){
                    if(searchMonth == 'All'){
                        newList.push(key);
                    }else {
                        let nameMonth = monthList.find(item =>item.name == searchMonth); 
                        if(key.month == nameMonth.month){
                            newList.push(key);
                        }
                    }
                }else {
                    if(key.year == value){
                        if(searchMonth == 'All'){
                            newList.push(key);
                        }else {
                            let nameMonth = monthList.find(item =>item.name == searchMonth); 
                            if(key.month == nameMonth.month){
                                newList.push(key);
                            }
                        }
                    }
                }
            })
            const duplicate = newList.filter((item, index) => newList.indexOf(item) === index);
            setShowPageData(duplicate);
        }else{
            setShowPageData(InitialData());
        }
        setSearch('');
        setSearchYear(value);
        setStatusYear(false);
        setStatusMonth(false);
    }

    function SearchSelectMonth(value){
        const newList = [];
        if(value !=''){
            GetDataPage('financialEntry').map((key, index)=>{
                if(key.year == searchYear){
                    if(value == 'All'){
                        newList.push(key);
                    }else {
                        if(key.month == value){
                            newList.push(key);
                        }
                    }
                }
            });
            const duplicate = newList.filter((item, index) => newList.indexOf(item) === index);
            setShowPageData(duplicate);
        }else{
            setShowPageData(InitialData());
        }
        setSearch('');
        setStatusYear(false);
        setStatusMonth(false);
        if(value != 'All'){
            let nameMonth = monthList.find(item =>item.month == value); 
            setSearchMonth(nameMonth.name);
        }else {
            setSearchMonth(value);
        }
    }

    function PageClick(idPage){
        SetListPag('currentPage', 'financialEntry_details')
        SetListPag('currentPageId', idPage);
    }

    function OpenFile(type, file){
        SetModalData('ShowFile', { "type": type, "file": file });
        SetModalState('ShowFile', true);
    }

    function DeleteData(id, value){
        SetModalData('Confirmation', { "origin": "entry", "type" : "delete_entry", "id" : id, "name": value });
        SetModalState('Confirmation', true);
    }

    return(
        <div className="Finacial_Entry">
            <div className="new_block">
                <div className="input_search">

                    <SvgSearch color="#111827" className="icons_search" />
                    <input type="text" className="search" onChange={ (e)=>{ SearchInput(e.target.value) } } value={ search } required />
                    <span className="span_search">Pesquisar...</span>

                    <div className="click_show_search year">
                        <div className="div_input" onClick={ ()=>{ setStatusYear(!statusYear); setStatusMonth(false); } }>
                            <div className="div_select">
                                { searchYear }
                                {
                                    statusYear == true ?
                                    <SvgTriagle color="#747474" className="icon_select_open" /> :
                                    <SvgTriagle color="#747474" className="icon_select_close" />
                                }
                            </div>
                            <span className="span_name_select">Ano</span>
                        </div>
                        {
                            statusYear == true ?
                            <div className="click_show_search_selected year">
                                <div className="opt_list_search" onClick={ ()=>{ SearchSelectYear('All'); } }>All</div>
                                {
                                    listYear.map((key, index)=>{
                                        return (
                                            <div className="opt_list_search" key={ index } onClick={ ()=>{ SearchSelectYear(key); } }>
                                                { key }
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            : null
                        }
                    </div>

                    <div className="click_show_search month">
                        <div className="div_input" onClick={ ()=>{ setStatusYear(false); setStatusMonth(!statusMonth); } }>
                            <div className="div_select">
                                { searchMonth }
                                {
                                    statusMonth == true ?
                                    <SvgTriagle color="#747474" className="icon_select_open" /> :
                                    <SvgTriagle color="#747474" className="icon_select_close" />
                                }
                            </div>
                            <span className="span_name_select">Mês</span>
                        </div>
                        {
                            statusMonth == true ?
                            <div className="click_show_search_selected month">
                                <div className="opt_list_search" onClick={ ()=>{ SearchSelectMonth('All'); } }>All</div>
                                {
                                    listMonth.map((key, index)=>{
                                        let nameMonth = monthList.find(item =>item.month == key); 
                                        return (
                                            <div className="opt_list_search" key={ index } onClick={ ()=>{ SearchSelectMonth(nameMonth.month); } }>
                                                { nameMonth.name }
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            : null
                        }
                    </div>

                </div>

                <div className="title_page">Entrada</div>
                <div className="new_block_text" onClick={ ()=>{ PageClick(0) } }>
                    <SvgAddNewData color="#ffffff" className="icons" />
                    Faturamento
                </div>
            </div>
            <div className="list_data_page">
                <table width="100%" cellPadding="2" cellSpacing="6">
                    <tbody>
                        <tr>
                            <th width="20" align="center">#</th>
                            <th width="200">Origem</th>
                            <th>Título</th>
                            <th width="60">Valor</th>
                            <th width="60" align="center">Data pagamento</th>
                            <th width="60" align="center">Data vencimento</th>
                            <th width="90" align="right">#</th>
                        </tr>
                    </tbody>
                    <tbody>
                        {
                            currentItens.map((key, index)=>{
                                let bgColor = 'transparent';
                                let title   = 'Pagamento pendente';
                                switch (key.status) {
                                    case 1:
                                            bgColor = 'rgb(124 159 6 / 10%)';
                                            title   = 'Pagamento realizado';
                                        break;

                                    case 2:
                                            bgColor = 'rgb(0 0 0 / 10%)';
                                            title   = 'Pagamento cancelado';
                                        break;
                                }

                                let ext = '';
                                if(key.file !=''){
                                    let show_ext = key.file.split('.');
                                    ext = show_ext[1];
                                }

                                return(
                                    <tr key={ index } style={ { backgroundColor: bgColor } } title={ title }>
                                        <td align="center">{ startItens + index + 1 }</td>
                                        <td>{ key.type }</td>
                                        <td>{ key.title }</td>
                                        <td>{ key.cash }</td>
                                        <td>{ key.payment_br }</td>
                                        <td>{ key.due_br }</td>
                                        <td align="right">
                                            <div className="div_opt_alt">
                                                {
                                                    key.file !='' ?
                                                        <>
                                                            {
                                                                ext == "pdf" ?
                                                                <div onClick={ ()=>{ OpenFile('showPdf', 'financial/entry/' + key.file) } }>
                                                                    <SvgPdf color="#324d6b" className="icons" />
                                                                </div> :
                                                                <div onClick={ ()=>{ OpenFile('showImg', 'financial/entry/' + key.file) } }>
                                                                    <SvgImg color="#324d6b" className="icons" />
                                                                </div>
                                                            }
                                                        </>
                                                    : ""
                                                }
                                                <div onClick={ ()=>{ DeleteData(key.id, key.title) } }>
                                                    <SvgDelete color="#F00000" className="icons"/>
                                                </div>
                                                <div onClick={ ()=>{ PageClick(key.id) } }>
                                                    <SvgEdit color="#324d6b" className="icons"/>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
            {
                showPageData.length >= 40 ?
                <div className="list_pages">
                    {
                        Array.from(Array(page), (item, index)=>{
                            return(
                                <div className={ showPage == index ? "numb_page" : "numb_page page_active" } key={ index } onClick={ (e)=>{ setShowPage(index) } }>
                                    { index + 1 }
                                </div>
                            )
                        })
                    }
                </div> : null
            }
        </div>
    )
}