
let DataPage = {
    "accessDash" : [],
    "courses"    : [],
    "students"   : [],
    "team"       : [],

    "siteHome"      : [],
    "siteHowWorks"  : {},
    "siteMentoring" : [],
    "siteRolo"      : [],
    "sitePayment"   : {},
    "siteNameMenu"  : [],
    
    "financialEntry"   : {},
    "financialExit"    : {},
    "financialBalance" : {},

    "Indicators" : []
};

let NotifyDataPage = {
    "accessDash" : [],
    "courses"    : [],
    "students"   : [],
    "team"       : [],

    "siteHome"      : [],
    "siteHowWorks"  : [],
    "siteMentoring" : [],
    "siteRolo"      : [],
    "sitePayment"   : [],
    "siteNameMenu"  : [],
    
    "financialEntry"   : [],
    "financialExit"    : [],
    "financialBalance" : [],

    "Indicators" : []
}

export function SetListData(value) {
    DataPage = value;
    NotifyDataPage["accessDash"].forEach(element => {       element(value["accessDash"]); });
    NotifyDataPage["courses"].forEach(element => {          element(value["courses"]); });
    NotifyDataPage["students"].forEach(element => {         element(value["students"]); });
    NotifyDataPage["team"].forEach(element => {             element(value["team"]); });
    NotifyDataPage["siteHome"].forEach(element => {         element(value["siteHome"]); });
    NotifyDataPage["siteHowWorks"].forEach(element => {     element(value["siteHowWorks"]); });
    NotifyDataPage["siteMentoring"].forEach(element => {    element(value["siteMentoring"]); });
    NotifyDataPage["siteRolo"].forEach(element => {         element(value["siteRolo"]); });
    NotifyDataPage["sitePayment"].forEach(element => {      element(value["sitePayment"]); });
    NotifyDataPage["siteNameMenu"].forEach(element => {     element(value["siteNameMenu"]); });
    NotifyDataPage["financialEntry"].forEach(element => {   element(value["financialEntry"]); });
    NotifyDataPage["financialExit"].forEach(element => {    element(value["financialExit"]); });
    NotifyDataPage["financialBalance"].forEach(element => { element(value["financialBalance"]); });
    NotifyDataPage["Indicators"].forEach(element => {       element(value["Indicators"]); });
}

export function SetListDataSingle(key, value) {
    DataPage[key] = value;
    NotifyDataPage[key].forEach(element => {
        element(value);
    });
}

export function GetDataPage(key){
    return DataPage[key];
}

export function RegisterDataPage(key, value){
    if(!NotifyDataPage[key]){
        NotifyDataPage[key] = [];
    }
    NotifyDataPage[key].push(value);
}
