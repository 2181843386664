import { useState, useEffect } from "react";

import './HowWorks.css';

import JoditEditor from 'jodit-react';
import { config, typeCookiePageId } from "dataFixed";

import { SvgDelete, SvgSave } from "components/SvgFile";

import { Reg_HowWorks } from "services/RegisterData";

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage } from "interface/Data";
import PopUp_Doubts from "components/PopUp/Doubts";

export default function Site_HowWorks(){
    
    const [ loading, setLoading ]   = useState(false);
    const [ dataPage, setDataPage ] = useState(GetDataPage('siteHowWorks'));

    const [ title_1, setTitle_1 ] = useState(InitialData('title_1'));
    const [ title_2, setTitle_2 ] = useState(InitialData('title_2'));
    const [ text, setText ]       = useState(InitialData('text'));
    const [ doubts, setDoubts ]   = useState(InitialData('doubts'));

    useEffect(()=>{
        RegisterDataPage('siteHowWorks', setDataPage);
    }, []);

    useEffect(()=>{
        setDataPage(GetDataPage('siteHowWorks'));

        setTitle_1(InitialData('title_1'));
        setTitle_2(InitialData('title_2'));
        setText(InitialData('text'));
        setDoubts(InitialData('doubts'));
    }, [dataPage]);

    function InitialData(type){
        const newData = dataPage;
        return newData[type];
    }

    function EditDoubts(type, index, value){
        const newData = [...doubts];
        newData[index][type] = value;
        setDoubts(newData)
    }

    function NewData(id){
        SetModalData('Doubts', { "origin": "howWorks", "type" : "Doubts", "id" : id });
        SetModalState('Doubts', true);
    }

    function DeleteData(id, value){
        SetModalData('Confirmation', { "origin": "howWorks", "type" : "delete_howWorks", "id" : id, "name": value });
        SetModalState('Confirmation', true);
    }

    function SaveData(event){
        event.preventDefault();
        setLoading(true);
        Reg_HowWorks(title_1, title_2, text, doubts, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    return(
        <div className="Site_HowWorks">
            <div className={ loading == true ? "return_data_save div_loading" : "return_data_save" }>
                <img alt="loading" src="./assets/loading.gif" className="loading" />
            </div>
            <form onSubmit={ SaveData } className="div_login">
                <div className="new_block">
                    <div className="title_page">Como funciona</div>
                    <button className="new_block_text">
                        <SvgSave color="#ffffff" className="icons" />
                        Salvar
                    </button>
                </div>

                <div className="list_data_page">
                    <div className="div_input">
                        <div className="div_show_data div_space">
                            <input type="text" className="data_input" onChange={ (e)=>{  setTitle_1(e.target.value) } } value={ title_1 } required />
                            <span className="span_input" maxLength="14">Título</span>
                        </div>
                    </div>
                </div>

                <div className="list_data_page block_cinza"> 
                    <div className="block_title">
                        <div className="">
                            Descrição
                        </div>
                    </div>
                    <div className="div_input">
                        <JoditEditor className="show_textarea" config={ config } value={ text ? text.replaceAll('&#34;', '"') : "" } onBlur={ newContent => setText(newContent) } />
                    </div>
                </div>

                <div className="list_data_page space_top">
                    <div className="div_input">
                        <div className="div_show_data div_space">
                            <input type="text" className="data_input" onChange={ (e)=>{  setTitle_2(e.target.value) } } value={ title_2 } required />
                            <span className="span_input" maxLength="14">Subtitulo</span>
                        </div>
                    </div>
                </div>

                <div className="list_data_page block_cinza"> 
                    <div className="block_title">
                        <div className="">
                            Dúvidas
                        </div>
                        <div className="button_new_data" onClick={ ()=>{ NewData() } }>
                            Adicionar novo dúvida
                        </div> 
                    </div>
                    {
                        doubts.map((key, index)=>{
                            return(
                                <div className="show_doubts" key={ index }>
                                    <div className="list_data_page">
                                        <div className="div_input">
                                            <div className="div_show_data div_space">
                                                <input type="text" className="data_input" onChange={ (e)=>{ EditDoubts('question', index, e.target.value) } } value={ key.question } required />
                                                <span className="span_input" maxLength="14">Dúvida</span>
                                            </div>
                                            <div className="div_show_data trash" onClick={ ()=>{ DeleteData(key.id, key.question) } }>
                                                <SvgDelete color="#f00000" className="icons" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="list_data_page space_top">
                                        <div className="div_input">
                                            <JoditEditor className="show_textarea" config={ config } value={ key.response ? key.response.replaceAll('&#34;', '"') : "" } onBlur={ newContent => EditDoubts('response', index, newContent) } />
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </form>
            <PopUp_Doubts />
        </div>
    );
}