import { useState, useEffect, useRef } from "react";

import './Details.css';

import Select from 'react-select';

import JoditEditor from 'jodit-react';
import { config, typeCookiePageId } from "dataFixed";

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage } from "interface/Data";
import { GetUserData, RegisterUserData } from "interface/Users";
import { GetListPag, SetListPag, RegisterListPag } from "interface/Page";

import { SvgDelete, SvgEdit, SvgImg, SvgLockClose, SvgLockOpen, SvgReturn, SvgSave, SvgSetaDown, SvgSetaUp, SvgTriagle, SvgVimeo, SvgYoutube } from "components/SvgFile";

import { Reg_AltPosition, Reg_EditOrRegisterCourses, Reg_UserDash } from "services/RegisterData";

export default function Courses_Details(){

    const editText_1 = useRef();
    const editText_2 = useRef();
    
    const [ loading, setLoading ]   = useState(false);
    const [ userId, setUserId ]     = useState(GetUserData('id'));
    const [ dataPage, setDataPage ] = useState(GetDataPage('courses'));
    const [ idPage, setIdPage ]     = useState(GetListPag('currentPageId'));

    const [ typeVideo, setTypeVideo ] = useState(InitialData('type_video'));
    const [ video, setVideo ]         = useState(InitialData('video'));

    const [ file, setFile ]             = useState(InitialData('file'));
    const [ uploadFile, setUploadFile ] = useState(false);
    const [ img, setImg ]               = useState(InitialData('img'));
    const [ uploadImg, setUploadImg ]   = useState(false);

    const [ highlight, setHighlight ] = useState(InitialData('highlight'))
    const [ title, setTitle ]         = useState(InitialData('title'));
    const [ subtitle, setSubtitle ]   = useState(InitialData('subtitle'));

    const [ free, setFree ]     = useState(InitialData('free'));
    const [ bronze, setBronze ] = useState(InitialData('bronze'));
    const [ prata, setPrata ]   = useState(InitialData('prata'));
    const [ gold, setGold ]     = useState(InitialData('gold'));

    const [ category, setCategory ]       = useState(InitialData('category'));
    const [ text, setText ]               = useState(InitialData('text'));
    const [ observation, setObservation ] = useState(InitialData('observation'));
    const [ listModule, setListModule ]   = useState([]);
    const [ listClasse, setListClasse ]   = useState(InitialData('list_classes'));
    
    const [ listCategory, setListCategory ] = useState([]);

    useEffect(()=>{
        RegisterUserData('id', setUserId);
        RegisterDataPage('courses', setDataPage);
        RegisterListPag('currentPageId', setIdPage);
        ListCategory();
        ListModule();
    }, []);

    useEffect(()=>{
        setDataPage(GetDataPage('courses'));
        setIdPage(GetListPag('currentPageId'));
        ListCategory();
        ListModule();

        setTypeVideo(InitialData('type_video'));
        setVideo(InitialData('video'));
        setFile(InitialData('file'));
        setImg(InitialData('img'));
        setHighlight(InitialData('highlight'))
        setTitle(InitialData('title'));
        setSubtitle(InitialData('subtitle'));
        setFree(InitialData('free'));
        setBronze(InitialData('bronze'));
        setPrata(InitialData('prata'));
        setGold(InitialData('gold'));
        setCategory(InitialData('category'));
        setText(InitialData('text'));
        setObservation(InitialData('observation'));
        setListClasse(InitialData('list_classes'));
    }, [dataPage]);

    useEffect(()=>{
        setDataPage(GetDataPage('courses'));
        setIdPage(GetListPag('currentPageId'));
        ListCategory();
        ListModule();

        setTypeVideo(InitialData('type_video'));
        setVideo(InitialData('video'));
        setFile(InitialData('file'));
        setImg(InitialData('img'));
        setHighlight(InitialData('highlight'))
        setTitle(InitialData('title'));
        setSubtitle(InitialData('subtitle'));
        setFree(InitialData('free'));
        setBronze(InitialData('bronze'));
        setPrata(InitialData('prata'));
        setGold(InitialData('gold'));
        setCategory(InitialData('category'));
        setText(InitialData('text'));
        setObservation(InitialData('observation'));
        setListClasse(InitialData('list_classes'));
    }, [idPage]);

    function InitialData(type){
        if(idPage !=0){
            if(dataPage.length > 0){
                const newData = dataPage.find(item => item.id == idPage);
                return newData[type];
            }
        }
        if(type == 'type_video'){
            return 'Vimeo';
        }

        if(type == 'free' || type == 'bronze' || type == 'prata' || type == 'gold' || type == 'highlight'){
            return false;
        }

        if(type == 'category'){
            return [];
        }

        if(type == 'list_classes'){
            return [];
        }
        return '';
    }

    function ListCategory(){
        const newData = [];
        GetDataPage('courses').forEach(item =>{
            item.category.forEach(item_1 =>{
                newData.push(item_1.name);
            });
        })
        const duplicate = newData.filter((item, index) => newData.indexOf(item) === index);
        setListCategory(duplicate);
    }

    function ListModule(){
        const newList = [];
        if(idPage !=0){
            const newData = dataPage.find(item => item.id == idPage);
            newData.list_classes.forEach(item =>{
                newList.push(item.module);
            })
        }
        const duplicate = newList.filter((item, index) => newList.indexOf(item) === index);
        setListModule(duplicate);
    }

    function ReturnPage(){
        SetListPag('currentPage', 'courses');
        SetListPag('currentPageId', 'remuve');
        SetListPag('currentPageIdSegund', 'remuve');
    }

    function OpenFile(type, file){
        SetModalData('ShowFile', { "type": type, "file": file });
        SetModalState('ShowFile', true);
    }

    function AddFile(type, value){
        switch (type) {
            case 'img':
                    setFile(value);
                    if(value){
                        setUploadFile(true);
                    }else {
                        setUploadFile(false);
                    }
                break;

            case 'banner':
                    setImg(value);
                    if(value){
                        setUploadImg(true);
                    }else {
                        setUploadImg(false);
                    }
                break;
        }
    }

    function AddOptCategory(){
        setCategory([...category, { "id": 0, "name": "", "new_name": "" }]);
    }

    function HandleCategory(type, index, value){
        const newData = [...category];
        newData[index][type] = value;
        setCategory(newData);
    }

    function DeleteCategory(index, id, value){
        if(id == 0){
            const newData = [...category];
            newData.splice(index, 1);
            setCategory(newData);
        }else {
            SetModalData('Confirmation', { "origin": "courses", "type" : "delete_category", "id" : id, "name": value });
            SetModalState('Confirmation', true);
        }
    }

    function DeleteData(id, value){
        SetModalData('Confirmation', { "origin": "classes", "type" : "delete_classes", "id" : id, "name": value });
        SetModalState('Confirmation', true);
    }

    function ChangePosition(id, order, type){
        Reg_AltPosition('courses_details', id, order, type, ()=>{ }, ()=>{ CallbackError() });
    }

    function SaveData(event){
        setLoading(true);
        event.preventDefault();
        Reg_EditOrRegisterCourses(idPage, typeVideo, video, file, img, highlight, title, subtitle, free, bronze, prata, gold, category, text, observation, ()=>{ CallbackSuccess() }, ()=>{ CallbackError() });
    }

    function CallbackSuccess(){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "ok" });
        SetModalState('ReturnResponse', true);
    }

    function CallbackError(){
        setLoading(false);
        SetModalData('ReturnResponse', { "page": "erro" });
        SetModalState('ReturnResponse', true);
    }

    function PageClick(id){
        SetListPag('currentPage', 'courses_details_classes');
        SetListPag('currentPageIdSegund', id);
    }
    
    return(
        <div className="Courses_Details">
            <div className={ loading == true ? "return_data_save div_loading" : "return_data_save" }>
                <img alt="loading" src="./assets/loading.gif" className="loading" />
            </div>
            <form onSubmit={ SaveData } className="div_login">
                <div className="new_block">
                    <div className="div_return" onClick={ ()=>{ ReturnPage() } }>
                        <SvgReturn color="#111827" className="icons" />
                        voltar
                    </div>
                    <div className="title_page">Detalhes do curso</div>
                    <button className="new_block_text">
                        <SvgSave color="#ffffff" className="icons" />
                        Salvar
                    </button>
                </div>

                <div className="list_data_page">
                    <div className="div_input">
                        
                        <div className="div_show_data">  
                            <select className="data_select" onChange={ (e)=>{ setTypeVideo(e.target.value) } } value={ typeVideo } required>
                                <option value="Vimeo">Vimeo</option>
                                <option value="Youtube">Youtube</option>
                            </select>
                            <span className="span_input">Tipo</span>
                        </div>
                        
                        <div className="div_show_data">
                            <input type="text" className="code" onChange={ (e)=>{ setVideo(e.target.value); } } value={ video.replaceAll(' ', '') } maxLength="18" required />
                            <span className="span_input" maxLength="14">Código do vídeo</span>
                        </div>

                        {
                            video !='' ?
                            <div className="div_show_data" onClick={ ()=>{ OpenFile(typeVideo, video) } }>
                                {
                                    typeVideo == 'Vimeo' ?
                                    <SvgVimeo color="#324d6b" className="icons"/> :
                                    <SvgYoutube color="#324d6b" className="icons"/>
                                }
                            </div> : ""
                        }

                        <div className="div_show_data">   
                            <div className={ uploadFile == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                <label>
                                    { uploadFile == true ? "Foto adicionada" : "Adicionar foto" }
                                    <input type="file" onChange={ (e)=>{ AddFile('img', e.target.files[0]); } } accept="image/*"/>
                                </label>
                            </div>
                            {
                                file !='' ?
                                    uploadFile == true ?
                                    <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(file)) } }>
                                        <SvgImg color="#324d6b" className="icons"/>
                                    </div> :
                                    <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "classes/img/" + file) } }>
                                        <SvgImg color="#324d6b" className="icons"/>
                                    </div>
                                : ""
                            }                      
                        </div>

                        <div className="div_show_data">   
                            <div className={ uploadImg == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                <label>
                                    { uploadImg == true ? "Banner adicionado" : "Adicionar banner" }
                                    <input type="file" onChange={ (e)=>{ AddFile('banner', e.target.files[0]); } } accept="image/*"/>
                                </label>
                            </div>
                            {
                                img !='' ?
                                    uploadImg == true ?
                                    <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(file)) } }>
                                        <SvgImg color="#324d6b" className="icons"/>
                                    </div> :
                                    <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "classes/img/" + file) } }>
                                        <SvgImg color="#324d6b" className="icons"/>
                                    </div>
                                : ""
                            }                      
                        </div>

                        <div className={ highlight == true ? "highlight high_active" : "highlight" } onClick={ ()=>{ setHighlight(!highlight) } }>
                            { highlight == true ? "Destacado" : "Destacar" }
                        </div>
                        
                        <div className="div_show_data div_space">
                            <input type="text" className="data_input" onChange={ (e)=>{ setSubtitle(e.target.value) } } value={ subtitle } maxLength="80" required />
                            <span className="span_input" maxLength="14">Título</span>
                        </div>
                        
                        <div className="div_show_data">
                            <input type="text" className="title_highlight" onChange={ (e)=>{ setTitle(e.target.value) } } value={ title } maxLength="40" required />
                            <span className="span_input" maxLength="14">Título em destaque</span>
                        </div>
                    </div>                 
                    <div className="div_input">
                        <div className="div_show_data">
                            Quem pode acessar                            
                            <div className={ free == true ? "type_access highlight high_active" : "type_access highlight" } onClick={ ()=>{ setFree(!free) } }>Free</div>
                            <div className={ bronze == true ? "type_access highlight high_active" : "type_access highlight" } onClick={ ()=>{ setBronze(!bronze) } }>Bronze</div>
                            <div className={ prata == true ? "type_access highlight high_active" : "type_access highlight" } onClick={ ()=>{ setPrata(!prata) } }>Prata</div>
                            <div className={ gold == true ? "type_access highlight high_active" : "type_access highlight" } onClick={ ()=>{ setGold(!gold) } }>Ouro</div>
                        </div>
                    </div>
                </div>

                <div className="list_data_page block_cinza"> 
                    <div className="block_title">
                        <div className="">
                            Categoria
                        </div>
                        <div className="button_new_data" onClick={ ()=>{ AddOptCategory(); } }>
                            Adicionar nova categoria
                        </div>
                    </div>
                    <div className="div_input">
                        {
                            category.length > 0 ?
                                category.map((key, index)=>{
                                    return(
                                        <div className="page_released" key={ index }>
                                            <div className="">
                                                <select onChange={ (e)=>{ HandleCategory('name', index, e.target.value) } } value={ key.name }>
                                                    <option value="">#</option>
                                                    <option value="new_module">Adicionar nova categoria</option>
                                                    {
                                                        listCategory.map((key_1, index_1)=>{
                                                            return(
                                                                <option value={ key_1 } key={ index_1 }>{ key_1 }</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                            {
                                                key.name == "new_module" ?                         
                                                <div className="">
                                                    <input type="text" className="input_new_module" onChange={ (e)=>{ HandleCategory('new_name', index, e.target.value); } } maxLength="80" required placeholder="nome..." />
                                                </div>
                                                : null
                                            }
                                            <div className="" onClick={ ()=>{ DeleteCategory(index, key.id, key.name) } }>
                                                <SvgDelete color="#f00000" className="icons" />
                                            </div>
                                        </div>
                                    )
                                })
                            : <div className="no_data">Nenhuma categoria adicionada até o momento...</div>
                        }
                    </div>                    
                </div>

                <div className="list_data_page block_cinza"> 
                    <div className="block_title">
                        <div className="">
                            Descrição
                        </div>
                    </div>
                    <div className="div_input">
                        <JoditEditor className="show_textarea" ref={ editText_1 } config={ config } value={ text != '' ? text.replaceAll('&#34;', '"') : "" } onBlur={ newContent => setText(newContent) } />
                    </div>
                </div>

                <div className="list_data_page block_cinza"> 
                    <div className="block_title">
                        <div className="">
                            Observação
                        </div>
                        <div className="inf_text">
                            *fixa em todos os cursos*
                        </div>
                    </div>
                    <div className="div_input">
                        <JoditEditor className="show_textarea" ref={ editText_2 } config={ config } value={ observation !='' ? observation.replaceAll('&#34;', '"') : "" } onBlur={ newContent => setObservation(newContent) } />
                    </div>
                </div>
                {
                    idPage !=0 ?
                    <div className="list_data_page block_cinza"> 
                        <div className="block_title">
                            <div className="">
                                Aulas
                            </div>
                            <div className="button_new_data" onClick={ ()=>{ PageClick(0) } }>
                                Adicionar nova aula
                            </div>
                        </div>
                        <div className="div_input">
                            <table width="100%" cellPadding="2" cellSpacing="6">
                                <tbody>
                                    <tr>
                                        <th width="20" align="center">#</th>
                                        <th>Título</th>
                                        <th width="60" align="right"></th>
                                        <th width="60" align="right"></th>
                                    </tr>
                                </tbody>
                                {
                                    listModule.length > 0 ?
                                        listModule.map((key, index)=>{
                                            let nameModule = 'Não definido';
                                            if(key != ''){
                                                nameModule = key;
                                            }

                                            let showClasses = listClasse.filter(item => item.module == key);
                                            return(
                                                <tbody key={ index }>
                                                    <tr>
                                                        <td colSpan="4">
                                                            <span className="title_table">Módulo:</span> { nameModule }
                                                        </td>
                                                    </tr>
                                                    {
                                                        showClasses.map((key_1, index_1)=>{
                                                            return(
                                                                <tr key={ index_1 }>
                                                                    <td align="center">{ index_1 + 1 }</td>
                                                                    <td>{ key_1.title }</td>
                                                                    <td>
                                                                        <div className="div_opt_alt">
                                                                            {
                                                                                index_1 == 0 ?
                                                                                <div className="space_div"></div> :
                                                                                <div onClick={ ()=>{ ChangePosition(key_1.id, key_1.order_, 'up') } }>
                                                                                    <SvgSetaUp color="#324d6b" className="icons"/>
                                                                                </div>
                                                                            }
                                                                            {
                                                                                (index_1 + 1) == showClasses.length ?
                                                                                <div className="space_div"></div> :
                                                                                <div onClick={ ()=>{ ChangePosition(key_1.id, key_1.order_, 'down') } }>
                                                                                    <SvgSetaDown color="#324d6b" className="icons"/>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="div_opt_alt">
                                                                            <div>
                                                                                {
                                                                                    key_1.free == true ?
                                                                                    <SvgLockOpen color="#324d6b" className="icons lock" /> :
                                                                                    <SvgLockClose color="#324d6b" className="icons lock" />
                                                                                }
                                                                            </div>
                                                                            <div onClick={ ()=>{ DeleteData(key_1.id, key_1.title) } }>
                                                                                <SvgDelete color="#F00000" className="icons"/>
                                                                            </div>
                                                                            <div onClick={ ()=>{ OpenFile(key_1.type_video, key_1.video) } }>
                                                                                {
                                                                                    key_1.video !='' ?
                                                                                        key_1.type_video == 'Vimeo' ?
                                                                                        <SvgVimeo color="#324d6b" className="icons"/> :
                                                                                        <SvgYoutube color="#324d6b" className="icons"/>
                                                                                    : ""
                                                                                }
                                                                            </div>
                                                                            <div onClick={ ()=>{ PageClick(key_1.id) } }>
                                                                                <SvgEdit color="#324d6b" className="icons"/>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            )
                                        })
                                    : 
                                    <tbody>
                                        <tr>
                                            <td colSpan="5" align="center">
                                                Nenhuma aula adicionada até o momento...
                                            </td>
                                        </tr>
                                    </tbody>
                                }
                                {/* <tbody>
                                    {
                                        listClasse.length >0 ?
                                        listClasse.map((key, index)=>{
                                            return(
                                                <tr key={ index }>
                                                    
                                                </tr>
                                            )
                                        }) :
                                        <tr>
                                            <td colSpan="5" align="center">
                                                Nenhuma aula adicionada até o momento...
                                            </td>
                                        </tr>
                                    }
                                </tbody> */}
                            </table>
                        </div>
                    </div> : null
                }
            </form>
        </div>
    )
}