import { useState, useEffect } from "react";

import './team.css';

import { SvgAddNewData, SvgDelete, SvgEdit, SvgSearch } from "components/SvgFile";

import { GetDataPage, RegisterDataPage } from "interface/Data";
import { SetListPag } from "interface/Page";
import { GetUserData, RegisterUserData } from "interface/Users";
import { SetModalData, SetModalState } from "interface/PopUp";

export default function Team(){

    const [ search, setSearch ]             = useState('');
    const [ userId, setUserId ]             = useState(GetUserData('id'));
    const [ showPageData, setShowPageData ] = useState(GetDataPage('team'));

    const [ itensPage, setItensPage ] = useState(30);
    const [ showPage, setShowPage ]   = useState(0);

    const page         = Math.ceil(showPageData.length / itensPage);
    const startItens   = showPage * itensPage;
    const endItens     = startItens + itensPage;
    const currentItens = showPageData.slice(startItens, endItens);

    useEffect(()=>{
        RegisterUserData('id', setUserId);
        RegisterDataPage('team', setShowPageData);
    }, []);

    function SearchInput(value){
        const newList = [];
        if(value){
            GetDataPage('team').forEach(item =>{
                if(item.name.toLowerCase().indexOf(value.toLowerCase()) != -1){
                    newList.push(item);
                }
                if(item.email.toLowerCase().indexOf(value.toLowerCase()) != -1){
                    newList.push(item);
                }
            });
            const duplicate = newList.filter((item, index) => newList.indexOf(item) === index);
            setShowPageData(duplicate);
        }else {
            setShowPageData(GetDataPage('team'));
        }
        setSearch(value);
    }

    function DeleteData(id, value){
        SetModalData('Confirmation', { "origin": "team", "type" : "delete_team", "id" : id, "name": value });
        SetModalState('Confirmation', true);
    }

    function PageClick(idPage){
        SetListPag('currentPage', 'team_details')
        SetListPag('currentPageId', idPage);
    }

    return(
        <div className="Team">
            <div className="new_block">
                <div className="input_search">
                    <SvgSearch color="#111827" className="icons_search" />
                    <input type="text" className="search" onChange={ (e)=>{ SearchInput(e.target.value) } } value={ search } required />
                    <span className="span_search">Pesquisar...</span>
                </div>
                <div className="title_page">Turmas</div>
                <div className="new_block_text" onClick={ ()=>{ PageClick(0) } }>
                    <SvgAddNewData color="#ffffff" className="icons" />
                    turma
                </div>
            </div>
            <div className="list_data_page">
                <table width="100%" cellPadding="2" cellSpacing="6">
                    <tbody>
                        <tr>
                            <th width="20" align="center">#</th>
                            <th>Nome</th>
                            <th width="60" align="right"></th>
                        </tr>
                    </tbody>
                    <tbody>
                        {
                            currentItens.map((key, index)=>{                                
                                return(
                                    <tr key={ index }>
                                        <td align="center">{ startItens + index + 1 }</td>
                                        <td>{ key.name }</td>
                                        <td align="right">
                                            <div className="div_opt_alt">
                                                <div onClick={ ()=>{ DeleteData(key.id, key.name) } }>
                                                    <SvgDelete color="#F00000" className="icons"/>
                                                </div>
                                                <div onClick={ ()=>{ PageClick(key.id) } }>
                                                    <SvgEdit color="#324d6b" className="icons"/>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
            {
                showPageData.length >= 30 ?
                <div className="list_pages">
                    {
                        Array.from(Array(page), (item, index)=>{
                            return(
                                <div className={ showPage == index ? "numb_page" : "numb_page page_active" } key={ index } onClick={ (e)=>{ setShowPage(index) } }>
                                    { index + 1 }
                                </div>
                            )
                        })
                    }
                </div> : null
            }
        </div>
    )
}