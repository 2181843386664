import { useState, useEffect } from "react";

import './Home.css';

import { SvgImg } from "components/SvgFile";

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage } from "interface/Data";
import { GetListPag, RegisterListPag } from "interface/Page";

export default function ModelSite_Home(props){

    const [ idPage, setIdPage ]     = useState(GetListPag('currentPageId'));
    const [ dataPage, setDataPage ] = useState(GetDataPage(props.origin));

    const [ file, setFile ]       = useState(InitialData('bg_page'));
    const [ title_1, setTitle_1 ] = useState(InitialData('title_1'));
    const [ title_2, setTitle_2 ] = useState(InitialData('title_2'));
    const [ title_3, setTitle_3 ] = useState(InitialData('title_3'));
    const [ title_4, setTitle_4 ] = useState(InitialData('title_4'));
    const [ title_5, setTitle_5 ] = useState(InitialData('title_5'));
    const [ title_6, setTitle_6 ] = useState(InitialData('title_6'));
    const [ title_7, setTitle_7 ] = useState(InitialData('title_7'));
    const [ title_8, setTitle_8 ] = useState(InitialData('title_8'));
    const [ status, setStatus ]   = useState(InitialData('status'));

    useEffect(()=>{
        RegisterDataPage(props.origin, setDataPage);
        RegisterListPag('currentPageId', setIdPage);

        LoadingData();
    }, []);

    useEffect(()=>{
        setDataPage(GetDataPage(props.origin));
        setIdPage(GetListPag('currentPageId'));

        setFile(InitialData('bg_page'));
        setTitle_1(InitialData('title_1'));
        setTitle_2(InitialData('title_2'));
        setTitle_3(InitialData('title_3'));
        setTitle_4(InitialData('title_4'));
        setTitle_5(InitialData('title_5'));
        setTitle_6(InitialData('title_6'));
        setTitle_7(InitialData('title_7'));
        setTitle_8(InitialData('title_8'));
        setStatus(InitialData('status'));

        LoadingData();
    }, [dataPage]);

    useEffect(()=>{
        setDataPage(GetDataPage(props.origin));
        setIdPage(GetListPag('currentPageId'));

        setFile(InitialData('bg_page'));
        setTitle_1(InitialData('title_1'));
        setTitle_2(InitialData('title_2'));
        setTitle_3(InitialData('title_3'));
        setTitle_4(InitialData('title_4'));
        setTitle_5(InitialData('title_5'));
        setTitle_6(InitialData('title_6'));
        setTitle_7(InitialData('title_7'));
        setTitle_8(InitialData('title_8'));
        setStatus(InitialData('status'));

        LoadingData();
    }, [idPage]);

    function InitialData(type){
        if(idPage !=0){
            if(dataPage.length > 0){
                const newData = dataPage.find(item => item.id == idPage);
                return newData[type];
            }
        }
        return '';
    }

    function LoadingData(){
        props.ReturnData({ "bg_page": file, "title_1": title_1, "title_2": title_2, "title_3": title_3, "title_4": title_4, "title_5": title_5, "title_6": title_6, "title_7": title_7, "title_8": title_8 });
    }

    function HandleFile(value){
        setFile(value);
        value ? setStatus(true) : setStatus(false);
        props.ReturnData({ "bg_page": value, "title_1": title_1, "title_2": title_2, "title_3": title_3, "title_4": title_4, "title_5": title_5, "title_6": title_6, "title_7": title_7, "title_8": title_8 });
    }

    function HandleTitle_1(value){
        setTitle_1(value);
        props.ReturnData({ "bg_page": file, "title_1": value, "title_2": title_2, "title_3": title_3, "title_4": title_4, "title_5": title_5, "title_6": title_6, "title_7": title_7, "title_8": title_8 });
    }

    function HandleTitle_2(value){
        setTitle_2(value);
        props.ReturnData({ "bg_page": file, "title_1": title_1, "title_2": value, "title_3": title_3, "title_4": title_4, "title_5": title_5, "title_6": title_6, "title_7": title_7, "title_8": title_8 });
    }

    function HandleTitle_3(value){
        setTitle_3(value);
        props.ReturnData({ "bg_page": file, "title_1": title_1, "title_2": title_2, "title_3": value, "title_4": title_4, "title_5": title_5, "title_6": title_6, "title_7": title_7, "title_8": title_8 });
    }

    function HandleTitle_4(value){
        setTitle_4(value);
        props.ReturnData({ "bg_page": file, "title_1": title_1, "title_2": title_2, "title_3": title_3, "title_4": value, "title_5": title_5, "title_6": title_6, "title_7": title_7, "title_8": title_8 });
    }

    function HandleTitle_5(value){
        setTitle_5(value);
        props.ReturnData({ "bg_page": file, "title_1": title_1, "title_2": title_2, "title_3": title_3, "title_4": title_4, "title_5": value, "title_6": title_6, "title_7": title_7, "title_8": title_8 });
    }

    function HandleTitle_6(value){
        setTitle_6(value);
        props.ReturnData({ "bg_page": file, "title_1": title_1, "title_2": title_2, "title_3": title_3, "title_4": title_4, "title_5": title_5, "title_6": value, "title_7": title_7, "title_8": title_8 });
    }

    function HandleTitle_7(value){
        setTitle_7(value);
        props.ReturnData({ "bg_page": file, "title_1": title_1, "title_2": title_2, "title_3": title_3, "title_4": title_4, "title_5": title_5, "title_6": title_6, "title_7": value, "title_8": title_8 });
    }

    function HandleTitle_8(value){
        setTitle_8(value);
        props.ReturnData({ "bg_page": file, "title_1": title_1, "title_2": title_2, "title_3": title_3, "title_4": title_4, "title_5": title_5, "title_6": title_6, "title_7": title_7, "title_8": value });
    }

    function OpenFile(type, file){
        SetModalData('ShowFile', { "type": type, "file": file });
        SetModalState('ShowFile', true);
    }

    return(
        <div className="ModelSite_Home">
            <div className="list_data_page">                        
                <div className="div_input div_space">
                    <div className="div_show_data file_bg">   
                        <div className={ status == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                            <label>
                                { status == true ? "Imagem de fundo adicionada" : "Adicionar imagem de fundo" }
                                <input type="file" onChange={ (e)=>{ HandleFile(e.target.files[0]); } } accept="image/*"/>
                            </label>
                        </div>
                        {
                            file !='' ?
                                status == true ?
                                <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(file)) } }>
                                    <SvgImg color="#324d6b" className="icons"/>
                                </div> :
                                <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "home/" + file) } }>
                                    <SvgImg color="#324d6b" className="icons"/>
                                </div>
                            : ""
                        }                      
                    </div>
                </div>
                        
                <div className="div_input div_space space_top">
                    <div className="div_show_data div_space">
                        <input type="text" className="data_input" onChange={ (e)=>{ HandleTitle_1(e.target.value) } } value={ title_1 } maxLength="80" />
                        <span className="span_input" maxLength="14">Título 1</span>
                    </div>
                    <div className="div_show_data div_space">
                        <input type="text" className="data_input" onChange={ (e)=>{ HandleTitle_2(e.target.value) } } value={ title_2 } maxLength="80" />
                        <span className="span_input" maxLength="14">Título 2</span>
                    </div>
                </div>

                <div className="div_input div_space space_top">
                    <div className="div_show_data div_space">
                        <input type="text" className="data_input" onChange={ (e)=>{ HandleTitle_3(e.target.value) } } value={ title_3 } maxLength="80" />
                        <span className="span_input" maxLength="14">Título 3</span>
                    </div>
                </div>

                <div className="div_input div_space space_top">
                    <div className="div_show_data div_space">
                        <input type="text" className="data_input" onChange={ (e)=>{ HandleTitle_4(e.target.value) } } value={ title_4 } maxLength="80" />
                        <span className="span_input" maxLength="14">Título 4</span>
                    </div>
                    <div className="div_show_data div_space">
                        <input type="text" className="data_input" onChange={ (e)=>{ HandleTitle_5(e.target.value) } } value={ title_5 } maxLength="80" />
                        <span className="span_input" maxLength="14">Título 5</span>
                    </div>
                    <div className="div_show_data div_space">
                        <input type="text" className="data_input" onChange={ (e)=>{ HandleTitle_6(e.target.value) } } value={ title_6 } maxLength="80" />
                        <span className="span_input" maxLength="14">Título 6</span>
                    </div>
                </div>

                <div className="div_input div_space space_top">
                    <div className="div_show_data div_space">
                        <input type="text" className="data_input" onChange={ (e)=>{ HandleTitle_7(e.target.value) } } value={ title_7 } maxLength="80" />
                        <span className="span_input" maxLength="14">Botão 1</span>
                    </div>
                    <div className="div_show_data div_space">
                        <input type="text" className="data_input" onChange={ (e)=>{ HandleTitle_8(e.target.value) } } value={ title_8 } maxLength="80" />
                        <span className="span_input" maxLength="14">Botão 2</span>
                    </div>
                </div>

                <div className="div_input div_space space_top">
                    <div className="div_show_data div_space img">
                        <img alt="home_example" src="./assets/bg_home.jpg" className="img_example" />
                    </div>
                </div>
            </div>
        </div>
    );
}