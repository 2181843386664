import { useState, useEffect } from "react";

import './Depositions.css';

import JoditEditor from 'jodit-react';
import { config } from "dataFixed";

import { SvgDelete, SvgImg, SvgPdf, SvgVimeo, SvgYoutube } from "components/SvgFile";

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage } from "interface/Data";
import { GetListPag, RegisterListPag } from "interface/Page";

export default function ModelSite_Depositions(props){

    const [ idPage, setIdPage ]     = useState(GetListPag('currentPageId'));
    const [ dataPage, setDataPage ] = useState(GetDataPage(props.origin));

    const [ title_1, setTitle_1 ]         = useState(InitialData('title_1'));
    const [ bgPage, setBgPage ]           = useState(InitialData('bg_page'));
    const [ dataProfile, setDataProfile ] = useState(InitialData('list_others'));

    useEffect(()=>{
        RegisterDataPage(props.origin, setDataPage);
        RegisterListPag('currentPageId', setIdPage);
        
        LoadingData();
    }, []);

    useEffect(()=>{
        setDataPage(GetDataPage(props.origin));
        setIdPage(GetListPag('currentPageId'));
        
        setTitle_1(InitialData('title_1'));
        setBgPage(InitialData('bg_page'));
        setDataProfile(InitialData('list_others'));

        LoadingData();
    }, [dataPage]);

    useEffect(()=>{
        setDataPage(GetDataPage(props.origin));
        setIdPage(GetListPag('currentPageId'));
        
        setTitle_1(InitialData('title_1'));
        setBgPage(InitialData('bg_page'));
        setDataProfile(InitialData('list_others'));

        LoadingData();
    }, [idPage]);

    function InitialData(type){
        if(idPage !=0){
            if(dataPage.length > 0){
                const newData = dataPage.find(item => item.id == idPage);
                return newData[type];
            }
        }
        if(type == 'list_others'){
            return [];
        }
        return '';
    }

    function LoadingData(){
        props.ReturnData({"title_1": title_1, "bg_page": bgPage, "dataProfile": dataProfile});
    }

    function AddNewData(){
        SetModalData('DataProfile', { "id": idPage, "page": props.origin });
        SetModalState('DataProfile', true);
    }

    function OpenFile(type, file){
        SetModalData('ShowFile', { "type": type, "file": file });
        SetModalState('ShowFile', true);
    }

    function handleProfile(type, value, index){
        let arrayData = [...dataProfile];
        if(type == 'img'){
            if(value){
                arrayData[index]['upload'] = true;
            }else {
                arrayData[index]['upload'] = false;
            }
        }
        arrayData[index][type] = value;
        setDataProfile(arrayData);
        props.ReturnData({"title_1": title_1, "bg_page": bgPage, "dataProfile": arrayData});
    }

    function RemuveDataAdd(id, value){
        SetModalData('Confirmation', { "origin": props.origin, "type" : "delete_data_table", "id" : id, "name": value });
        SetModalState('Confirmation', true);
    }

    function HandleTitle(value){
        setTitle_1(value);
        props.ReturnData({"title_1": value, "bg_page": bgPage, "dataProfile": dataProfile});
    }

    function HandleBgPage(value){
        setBgPage(value);
        props.ReturnData({"title_1": title_1, "bg_page": value, "dataProfile": dataProfile});
    }

    return(
        <div className="ModelSite_Depositions">
            <div className="list_data_page">
                <div className="div_input">
                    <div className="div_show_data">
                        <input type="text" className="input_color" onChange={ (e)=>{ HandleBgPage(e.target.value) } } value={ bgPage } maxLength="140" />
                        <span className="span_input" maxLength="14">Cor de fundo</span>
                        <div className="div_color" style={ { background: bgPage } } />
                    </div>
                    <div className="div_show_data div_space">
                        <input type="text" className="data_input" onChange={ (e)=>{ HandleTitle(e.target.value) } } value={ title_1 } maxLength="80" />
                        <span className="span_input" maxLength="14">Título</span>
                    </div>
                </div>
                {
                    idPage == 0 ? null :  
                    <div className="div_input block_cinza"> 
                        <div className="block_title space_bottom">
                            <div className="">
                                Perfil
                            </div>
                            <div className="button_new_data" onClick={ ()=>{ AddNewData() } }>
                                Adicionar novo perfil
                            </div>
                        </div>
                        {
                            dataProfile.length > 0 ?
                                dataProfile.map((key, index)=>{
                                    return(  
                                        <div className="list_data_page show_profile" key={ index }> 
                                            <div className="div_input">                                       
                                                <div className="div_show_data"> 
                                                    <div className={ key.upload == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                                                        <label>
                                                            { key.upload == true ? "Imagem adicionada" : "Adicionar imagem" }
                                                            <input type="file" onChange={ (e)=>{ handleProfile('img', e.target.files[0], index); } } accept="image/*"/>
                                                        </label>
                                                    </div>
                                                    {
                                                        key.img !='' ?
                                                            key.upload == true ?
                                                            <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(key.img)) } }>
                                                                <SvgImg color="#324d6b" className="icons"/>
                                                            </div> :
                                                            <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "depositions/" + key.img) } }>
                                                                <SvgImg color="#324d6b" className="icons"/>
                                                            </div>
                                                        : ""
                                                    }                      
                                                </div> 
                                            </div>                           
                                            <div className="div_input space_top">
                                                <div className="div_show_data">
                                                    <input type="text" className="input_name" onChange={ (e)=>{ handleProfile('title', e.target.value, index); } } value={ key.title } maxLength="80" />
                                                    <span className="span_input" maxLength="14">Nome</span>
                                                </div>
                                                <div className="div_show_data">
                                                    <input type="text" className="data_input" onChange={ (e)=>{ handleProfile('area', e.target.value, index) } } value={ key.area } maxLength="80" />
                                                    <span className="span_input" maxLength="14">Área</span>
                                                </div> 
                                                <div className="div_show_data">
                                                    <input type="text" className="input_date" onChange={ (e)=>{ handleProfile('data', e.target.value, index) } } value={ key.data } maxLength="80" />
                                                    <span className="span_input" maxLength="14">Data</span>
                                                </div> 
                                                <div className="div_show_data">
                                                    <div onClick={ ()=>{ RemuveDataAdd(key.id, key.title) } }>
                                                        <SvgDelete color="#F00000" className="icons" />
                                                    </div>
                                                </div>
                                            </div>                           
                                            <div className="div_input div_space">                                       
                                                <div className="div_show_data div_space"> 
                                                    <JoditEditor className="show_textarea" config={ config } value={ key.text.replaceAll('&#34;', '"') } onBlur={ newContent => handleProfile('text', newContent, index) } />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            : null
                        }
                    </div>                  
                }
            </div>
        </div>
    );

}