import { useState, useEffect } from "react";

import './Students.css';

import { SvgAddNewData, SvgCancel, SvgDelete, SvgEdit, SvgUser, SvgUserDisabled, SvgSearch, SvgTriagle } from "components/SvgFile";

import { GetDataPage, RegisterDataPage } from "interface/Data";
import { GetListPag, RegisterListPag, SetListPag } from "interface/Page";
import { GetUserData, RegisterUserData } from "interface/Users";
import { SetModalData, SetModalState } from "interface/PopUp";

export default function Students(){

    const [ search, setSearch ]             = useState('');
    const [ userId, setUserId ]             = useState(GetUserData('id'));
    const [ showPageData, setShowPageData ] = useState(GetDataPage('students'));

    const [ typeAccess, setTypeAccess ] = useState('All');
    const [ statusType, setStatusType ] = useState(false);
    const [ listType, setListType ]     = useState(ListType());

    const [ itensPage, setItensPage ] = useState(50);
    const [ showPage, setShowPage ]   = useState(0);

    const page         = Math.ceil(showPageData.length / itensPage);
    const startItens   = showPage * itensPage;
    const endItens     = startItens + itensPage;
    const currentItens = showPageData.slice(startItens, endItens);

    useEffect(()=>{
        RegisterUserData('id', setUserId);
        RegisterDataPage('students', setShowPageData);
    }, []);

    function ListType(){
        const newData = [];
        GetDataPage('students').forEach(item =>{
            newData.push(item.nivel_page);
        })
        const duplicate = newData.filter((item, index) => newData.indexOf(item) === index);
        return duplicate;
    }

    function SearchInput(value){
        const newList = [];
        if(value){
            GetDataPage('students').forEach(item =>{
                if(item.name.toLowerCase().indexOf(value.toLowerCase()) != -1){
                    newList.push(item);
                }
                if(item.email.toLowerCase().indexOf(value.toLowerCase()) != -1){
                    newList.push(item);
                }
            });
            const duplicate = newList.filter((item, index) => newList.indexOf(item) === index);
            setShowPageData(duplicate);
        }else {
            setShowPageData(GetDataPage('students'));
        }
        setSearch(value);
        setTypeAccess('All');
        setStatusType(false);
    }

    function SearchSelect(value){
        const newList = [];
        if(value){
            if(value != 'All'){
                GetDataPage('students').forEach(item =>{
                    if(item.nivel_page.toLowerCase().indexOf(value.toLowerCase()) != -1){
                        newList.push(item);
                    }
                });
                const duplicate = newList.filter((item, index) => newList.indexOf(item) === index);
                setShowPageData(duplicate);
            }else {
                setShowPageData(GetDataPage('students'));
            }
        }else {
            setShowPageData(GetDataPage('students'));
        }
        setSearch('');
        setTypeAccess(value);
        setStatusType(false);
    }

    function OpenFile(type, file){
        SetModalData('ShowFile', { "type": type, "file": file });
        SetModalState('ShowFile', true);
    }

    function DeleteData(id, value){
        SetModalData('Confirmation', { "origin": "students", "type" : "delete_students", "id" : id, "name": value });
        SetModalState('Confirmation', true);
    }

    function PageClick(idPage){
        SetListPag('currentPage', 'students_details')
        SetListPag('currentPageId', idPage);
    }

    return(
        <div className="Students">
            <div className="new_block">
                <div className="input_search">

                    <SvgSearch color="#111827" className="icons_search" />
                    <input type="text" className="search" onChange={ (e)=>{ SearchInput(e.target.value) } } value={ search } required />
                    <span className="span_search">Pesquisar...</span>

                    <div className="click_show_search access">
                        <div className="div_input" onClick={ ()=>{ setStatusType(!statusType); } }>
                            <div className="div_select">
                                { typeAccess }
                                {
                                    statusType == true ?
                                    <SvgTriagle color="#747474" className="icon_select_open" /> :
                                    <SvgTriagle color="#747474" className="icon_select_close" />
                                }
                            </div>
                            <span className="span_name_select">Acesso</span>
                        </div>
                        {
                            statusType == true ?
                            <div className="click_show_search_selected access">
                                <div className="opt_list_search" onClick={ ()=>{ SearchSelect('All'); } }>All</div>
                                {
                                    listType.map((key, index)=>{
                                        return (
                                            <div className="opt_list_search" key={ index } onClick={ ()=>{ SearchSelect(key); } }>
                                                { key }
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            : null
                        }
                    </div>

                </div>

                <div className="title_page">Alunos</div>
                <div className="new_block_text" onClick={ ()=>{ PageClick(0) } }>
                    <SvgAddNewData color="#ffffff" className="icons" />
                    usuário
                </div>
            </div>
            <div className="list_data_page">
                <table width="100%" cellPadding="2" cellSpacing="6">
                    <tbody>
                        <tr>
                            <th width="20" align="center">#</th>
                            <th>Nome</th>
                            <th>E-mail</th>
                            <th width="50">Acesso</th>
                            <th width="30">Foto</th>
                            <th width="60" align="right"></th>
                        </tr>
                    </tbody>
                    <tbody>
                        {
                            currentItens.map((key, index)=>{                                
                                return(
                                    <tr key={ index }>
                                        <td align="center">{ startItens + index + 1 }</td>
                                        <td>{ key.name }</td>
                                        <td>{ key.email }</td>
                                        <td>{ key.nivel_page }</td>
                                        <td align="center">
                                            <div>
                                                {
                                                    key.file !='' ?
                                                    <img alt="profile user" src={ "./assets/login/" + key.file } className="icon_user" onClick={ ()=>{ OpenFile('showImg', "students/" + key.file) } } /> :
                                                    <SvgUser color="#324d6b" className="icon_user" />
                                                }
                                            </div>
                                        </td>
                                        <td align="right">
                                            <div className="div_opt_alt">     
                                                <div onClick={ ()=>{ DeleteData(key.id, key.name) } } title="Deletar usuário">
                                                    <SvgDelete color="#F00000" className="icons"/>
                                                </div>
                                                <div onClick={ ()=>{ PageClick(key.id) } } title="Editar usuário">
                                                    <SvgEdit color="#324d6b" className="icons"/>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
            {
                showPageData.length >= 50 ?
                <div className="list_pages">
                    {
                        Array.from(Array(page), (item, index)=>{
                            return(
                                <div className={ showPage == index ? "numb_page" : "numb_page page_active" } key={ index } onClick={ (e)=>{ setShowPage(index) } }>
                                    { index + 1 }
                                </div>
                            )
                        })
                    }
                </div> : null
            }
        </div>
    )
}