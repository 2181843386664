import { useState, useEffect } from "react";

import './ModulesWorkload.css';

import JoditEditor from 'jodit-react';
import { config } from "dataFixed";

import { SvgDelete, SvgImg, SvgVimeo, SvgYoutube } from "components/SvgFile";

import { SetModalData, SetModalState } from "interface/PopUp";
import { GetDataPage, RegisterDataPage } from "interface/Data";
import { GetListPag, RegisterListPag } from "interface/Page";

export default function ModelSite_ModulesWorkload(props){

    const [ idPage, setIdPage ]     = useState(GetListPag('currentPageId'));
    const [ dataPage, setDataPage ] = useState(GetDataPage(props.origin));

    const [ img, setImg ]             = useState(InitialData('img'));
    const [ status, setStatus ]       = useState(InitialData('status'));
    const [ bgPage, setBgPage ]       = useState(InitialData('bg_page'));
    const [ title_1, setTitle_1 ]     = useState(InitialData('title_1'));
    const [ text_1, setText_1 ]       = useState(InitialData('text_1'));
    const [ dataTable, setDataTable ] = useState(InitialData('list_others'));

    useEffect(()=>{
        RegisterDataPage(props.origin, setDataPage);
        RegisterListPag('currentPageId', setIdPage);
        
        LoadingData();
    }, []);

    useEffect(()=>{
        setDataPage(GetDataPage(props.origin));
        setIdPage(GetListPag('currentPageId'));
        
        setImg(InitialData('img'));
        setStatus(InitialData('status'));
        setBgPage(InitialData('bg_page'));
        setTitle_1(InitialData('title_1'));
        setText_1(InitialData('text_1'));
        setDataTable(InitialData('list_others'));

        LoadingData();
    }, [dataPage]);

    useEffect(()=>{
        setDataPage(GetDataPage(props.origin));
        setIdPage(GetListPag('currentPageId'));
        
        setImg(InitialData('img'));
        setStatus(InitialData('status'));
        setBgPage(InitialData('bg_page'));
        setTitle_1(InitialData('title_1'));
        setText_1(InitialData('text_1'));
        setDataTable(InitialData('list_others'));

        LoadingData();
    }, [idPage]);

    function InitialData(type){
        if(idPage !=0){
            if(dataPage.length > 0){
                const newData = dataPage.find(item => item.id == idPage);
                return newData[type];
            }
        }
        if(type == 'list_others'){
            return [];
        }
        return '';
    }

    function LoadingData(){
        props.ReturnData({"img": img, "bg_page": bgPage, "title_1": title_1, "text_1": text_1, "dataTable": dataTable});
    }

    function HandleBgPage(value){
        setBgPage(value)
        props.ReturnData({"img": img, "bg_page": value, "title_1": title_1, "text_1": text_1, "dataTable": dataTable});
    }

    function HandleImg(value){
        setImg(value);
        value ? setStatus(true) : setStatus(false);
        props.ReturnData({"img": value, "bg_page": bgPage, "title_1": title_1, "text_1": text_1, "dataTable": dataTable});
    }

    function HandleTitle(value){
        setTitle_1(value);
        props.ReturnData({"img": img, "bg_page": bgPage, "title_1": value, "text_1": text_1, "dataTable": dataTable});
    }

    function HandleText(value){
        setText_1(value);
        props.ReturnData({"img": img, "bg_page": bgPage, "title_1": title_1, "text_1": value, "dataTable": dataTable});
    }

    function handleTable(type, value, index){
        let arrayData = [...dataTable];
        arrayData[index][type] = value;
        setDataTable(arrayData);
        props.ReturnData({"img": img, "bg_page": bgPage, "title_1": title_1, "text_1": text_1, "dataTable": arrayData});
    }

    function AddNewData(){
        setDataTable([...dataTable, { "id": 0, "title": "", "hour": "", "color": "", "upload": false }]);
    }

    function OpenFile(type, file){
        SetModalData('ShowFile', { "type": type, "file": file });
        SetModalState('ShowFile', true);
    }

    function RemuveDataAdd(id, index, value){
        if(id != 0){
            SetModalData('Confirmation', { "origin": props.origin, "type" : "delete_data_table", "id" : id, "name": value });
            SetModalState('Confirmation', true);
        }else {
            let arrayData = [...dataTable];
            arrayData.splice(index, 1);
            setDataTable(arrayData);
        }
    }

    return(
        <div className="ModelSite_ModulesWorkload">
            <div className="list_data_page">
                <div className="div_input">
                    <div className="div_show_data">
                        <input type="text" className="input_color" onChange={ (e)=>{ HandleBgPage(e.target.value) } } value={ bgPage } maxLength="140" />
                        <span className="span_input" maxLength="14">Cor de fundo</span>
                        <div className="div_color" style={ { background: bgPage } } />
                    </div>

                    <div className="div_show_data">   
                        <div className={ status == true ? "new_file_add new_file_add_active" : "new_file_add" }>
                            <label>
                                { status == true ? "Imagem adicionada" : "Adicionar imagem" }
                                <input type="file" onChange={ (e)=>{ HandleImg(e.target.files[0]); } } accept="image/*"/>
                            </label>
                        </div>
                        {
                            img !='' ?
                                status == true ?
                                <div className="show_img_" onClick={ ()=>{ OpenFile('showImg_local', URL.createObjectURL(img)) } }>
                                    <SvgImg color="#324d6b" className="icons"/>
                                </div> :
                                <div className="show_img_" onClick={ ()=>{ OpenFile('showImg', "modules_workload/" + img) } }>
                                    <SvgImg color="#324d6b" className="icons"/>
                                </div>
                            : ""
                        }                      
                    </div>
                </div>
                        
                <div className="div_input div_space space_top">
                    <div className="div_show_data div_space">
                        <input type="text" className="data_input" onChange={ (e)=>{ HandleTitle(e.target.value) } } value={ title_1 } maxLength="80" />
                        <span className="span_input" maxLength="14">Título</span>
                    </div>
                </div>

                <div className="div_input block_cinza"> 
                    <div className="block_title">
                        <div className="">
                            Texto
                        </div>
                    </div>
                    <div className="div_input">
                        <JoditEditor className="show_textarea" config={ config } value={ text_1.replaceAll('&#34;', '"') } onBlur={ newContent => HandleText(newContent) } />
                    </div>
                </div>

                <div className="div_input block_cinza"> 
                    <div className="block_title">
                        <div className="">
                            Tabela
                        </div>
                        <div className="button_new_data" onClick={ ()=>{ AddNewData() } }>
                            Adicionar nova linha na tabela
                        </div>
                    </div>
                    {
                        dataTable.length > 0 ?
                        <table width="100%" cellPadding="2" cellSpacing="6">
                            <tbody>
                                <tr>
                                    <th width="10" align="center">#</th>
                                    <th>Nome</th>
                                    <th width="80">Cor</th>
                                    <th width="40">Hora</th>
                                    <th width="20" align="center">#</th>
                                </tr>
                            </tbody>
                            <tbody>
                                {
                                    dataTable.map((key, index)=>{
                                        return(
                                            <tr key={ index }>
                                                <td className="td_" align="center">{ index + 1 }</td>
                                                <td className="td_">
                                                    <input type="text" className="data_input" onChange={ (e)=>{ handleTable('title', e.target.value, index) } } value={ key.title } maxLength="80" />
                                                </td>
                                                <td className="td_">
                                                    <div className="show_div_color">
                                                        <input type="text" className="input_color" onChange={ (e)=>{ handleTable('color', e.target.value, index) } } value={ key.color } maxLength="80" />
                                                        <div className="div_color" style={ { background: key.color } } />
                                                    </div>
                                                </td>
                                                <td className="td_">
                                                    <input type="text" className="input_hour" onChange={ (e)=>{ handleTable('data', e.target.value, index) } } value={ key.data } maxLength="80" />
                                                </td>
                                                <td className="td_">
                                                    <div onClick={ ()=>{ RemuveDataAdd(key.id, index, key.title) } }>
                                                        <SvgDelete color="#F00000" className="icons" />
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                        : null
                    }
                </div>

                <div className="div_input div_space space_top">
                    <div className="div_show_data div_space img">
                        <img alt="home_example" src="./assets/bg_modules_workload.jpg" className="img_example" />
                    </div>
                </div>
            </div>
        </div>
    );
}