import React, { useState, useEffect } from "react";

import './Balance.css';

import { SvgClose, SvgDownload, SvgImg, SvgPdf } from "components/SvgFile";

import { SetModalState, RegisterModalData, RegisterModalObserver, SetModalData } from "interface/PopUp";

import { GetDataPage } from "interface/Data";
import { connectionPage } from "dataFixed";

export default function PopUP_BalanceDetails(){

    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);
    const [ listData, setListData ]   = useState([]);

    const [ title, setTitle ] = useState('');

    useEffect(()=>{
        RegisterModalData('Balance', setModaldata);
        RegisterModalObserver('Balance', setShowPopUp);
    }, []);

    useEffect(()=>{
        if(showPopUp == true){
            setListData(InitiaData());
            setTitle(IniditalTitle());
        }
    }, [showPopUp]);

    function InitiaData(){
        switch (modalData.type) {
            case 'entry':
                    const ent_newData   = GetDataPage('financialEntry').filter(item => item.status == 1);
                    const ent_newData_1 = ent_newData.filter(item => item.year == modalData.year);
                    const ent_newData_2 = ent_newData_1.filter(item => item.month == modalData.month);
                return ent_newData_2;

            case 'fee':
                    const fee_newData   = GetDataPage('financialEntry').filter(item => item.status == 1);
                    const fee_newData_1 = fee_newData.filter(item => item.fee != '');
                    const fee_newData_2 = fee_newData_1.filter(item => item.year == modalData.year);
                    const fee_newData_3 = fee_newData_2.filter(item => item.month == modalData.month);
                return fee_newData_3;

            case 'exit':
                    const exi_newData   = GetDataPage('financialExit').filter(item => item.status == 1);
                    const exi_newData_1 = exi_newData.filter(item => item.year == modalData.year);
                    const exi_newData_2 = exi_newData_1.filter(item => item.month == modalData.month);
                return exi_newData_2;
        }
    }

    function IniditalTitle(){
        switch (modalData.type) {
            case 'entry': case 'exit':
                return 'Faturamento de ' + modalData.name + ' - total de R$ ' + modalData.cash;

            case 'fee':
                return 'Taxas de ' + modalData.name + ' - total de R$ ' + modalData.cash;
        }
    }

    function ClosePopUp(){
        setListData([]);
        SetModalState('Balance', false);
    }

    function OpenFile(type, file){
        SetModalData('ShowFile', { "type": type, "file": file });
        SetModalState('ShowFile', true);
    }

    function GeneratePDF(){
        if(modalData.cash != '0,00'){
            console.log(modalData.cash);
            return(
                <div className="download">
                    <a href={ connectionPage + "/php/generatePdf.php?acao=" + modalData.type + "/" + modalData.year + "/" + modalData.month + "/" + modalData.name }>
                        <div className="div_download" onClick={ ()=>{  } }>
                            <SvgDownload color="#FFFFFF" className="icons" />
                        </div>
                    </a>
                </div>
            )
        }
        return '';
    }

    return (
        (showPopUp ?
            <>
                <div className="PopUp">
                    <div className="all popup_BalanceDetails">
                        <div className="div_data" style={ { paddingBottom: '10px' } }>
                            <div className="title">
                                { title }
                            </div>
                            <div className="close" onClick={ ()=>{ ClosePopUp() } }>
                                <SvgClose color="#F00000" className="icons" />
                            </div>
                        </div>
                        <div className="div_data" style={ { paddingTop: 0 } }>
                            <div className="content">
                                { GeneratePDF() }
                                <table width="100%" cellPadding="2" cellSpacing="6">
                                    <tbody>
                                        <tr>
                                            <th width="20" align="center">#</th>
                                            <th width="200">Origem</th>
                                            <th>Título</th>
                                            <th width="60">Valor</th>
                                            <th width="60" align="center">Data</th>
                                            <th width="30" align="right"></th>
                                        </tr>
                                    </tbody>
                                    <tbody>
                                        {
                                            listData.length > 0 ?
                                            listData.map((key, index)=>{
                                                let ext = '';
                                                if(key.file !=''){
                                                    let show_ext = key.file.split('.');
                                                    ext = show_ext[1];
                                                }
                                                return(
                                                    <tr key={ index }>
                                                        <td align="center">{ index + 1 }</td>
                                                        <td align="left">{ key.type }</td>
                                                        <td align="left">{ key.title }</td>
                                                        <td align="left">{ key.cash }</td>
                                                        <td align="left">{ key.payment_br }</td>
                                                        <td align="right">
                                                            {
                                                                key.file !='' ?
                                                                    <>
                                                                        {
                                                                            ext == "pdf" ?
                                                                            <div onClick={ ()=>{ OpenFile('showPdf', 'financial/' + modalData.type + '/' + key.file) } }>
                                                                                <SvgPdf color="#324d6b" className="icons" />
                                                                            </div> :
                                                                            <div onClick={ ()=>{ OpenFile('showImg', 'financial/' + modalData.type + '/' + key.file) } }>
                                                                                <SvgImg color="#324d6b" className="icons" />
                                                                            </div>
                                                                        }
                                                                    </>
                                                                : ""
                                                            }
                                                        </td>
                                                    </tr>
                                                )
                                            }) :
                                            <tr>
                                                <td colSpan="6" align="center">Nenhum registrado até o momento...</td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        : <React.Fragment></React.Fragment>)
    );
}
