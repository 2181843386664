import React, { useState, useEffect } from "react";

import './ReturnResponse.css';

import { RegisterModalData, RegisterModalObserver, SetModalState } from "interface/PopUp";
import { SvgChekedReturn, SvgErro } from "components/SvgFile";

export default function PopUP_ReturnResponse(){

    const [ modalData, setModaldata ]     = useState({});
    const [ showPopUp, setShowPopUp ]     = useState(false);

    useEffect(()=>{
        RegisterModalData('ReturnResponse', setModaldata);
        RegisterModalObserver('ReturnResponse', setShowPopUp);
    }, []);

    function ClosePopUp(){
        SetModalState('ReturnResponse', false);
    }

    return(
        (showPopUp ?
            <>
                <div className="PopUp">
                    <div className="all ReturnResponse">
                        <div className="div_data">
                            <div className="content">
                                {
                                    modalData.page == 'erro' ? 
                                    <>
                                        <SvgErro color="#F00000" className="icons_return" />
                                        <div className="title_return">
                                            Erro ao registrar as informações, tente novamente!
                                        </div>
                                    </>
                                    :
                                    <>
                                        <SvgChekedReturn color="#43a047" className="icons_return" />
                                        <div className="title_return">
                                            Informações registradas com sucesso.
                                        </div>
                                    </>
                                }
                                <div className="close_return" onClick={ ()=>{ ClosePopUp() } }>
                                    Fechar
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        : <React.Fragment></React.Fragment>)
    )
};
